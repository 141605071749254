import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '15px 0px',
    textAlign: 'left'
  },
  textField: {
    '& > div > fieldset > legend': {
      display: 'block'
    }
  },
  subText: {
    margin: '8px 14px 0',
    fontSize: '0.75rem',
    color: theme.palette.text.subtext
  }
}))
const NmTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF'
    }
  }
})(TextField)

export const NmFormTextArea = React.forwardRef((props, ref) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <NmTextField
        multiline
        variant='outlined'
        fullWidth
        rows={props.rows ? props.rows : 5}
        {...props}
        inputRef={ref || props.inputRef}
      />
      <span className={classes.subText}>{props.subText}</span>
    </div>
  )
})
