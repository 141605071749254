import React from 'react'
import { Posts } from 'shared/Posts'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Box } from '@material-ui/core'
import { useInfiniteNewPostsUnshiftPressRelease } from 'hooks/useNewPostsUnshiftPressRelease'
import { ButtonReadMore } from 'shared/molecules/button/ReadMore'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  buttonContainer: {
    textAlign: 'center'
  },
  expandButton: {
    padding: '8px',
    textAlign: 'center'
  },
  expandButtonText: {
    color: theme.palette.text.accent
  },
  expandMoreIcon: {
    fontSize: 22,
    verticalAlign: 'middle'
  }
}))

export const NewPosts = (props) => {
  const classes = useStyles()
  const { data, error, fetchNextPage, hasNextPage } =
    useInfiniteNewPostsUnshiftPressRelease()

  if (error) {
    return (
      <Box display='flex' justifyContent='center'>
        <p>取得できませんでした</p>
      </Box>
    )
  }

  return (
    <Container className={classes.root}>
      {data && <Posts posts={data} sectionTitle='新着' />}
      <ButtonReadMore hasNextPage={hasNextPage} onClick={fetchNextPage} />
    </Container>
  )
}
