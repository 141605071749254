import React from 'react'
import { Content } from './Content'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '702px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

export const Body = (props) => {
  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <Content />
    </Container>
  )
}
