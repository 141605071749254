import React from 'react'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'
import { Body } from './Body'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = () => {
  setPageTitle(TitleUtil.login())

  return (
    <TemplateSideColumn content={<Body />} />
  )
}
