import { DateTime } from 'luxon'

export const isoDateToYMD = (iso) => {
  if (!iso) return iso
  const parse = iso.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/)
  return parse[1] + '-' + parse[2] + '-' + parse[3]
}

export const timestampToJapaneseDateTimeString = (timestamp) => {
  return DateTime.fromSeconds(timestamp).setZone('Asia/Tokyo').toFormat('yyyy年L月d日 HH:mm')
}

export const sqlFormatToJapaneseString = (sqlFormat) => {
  return DateTime.fromSQL(sqlFormat, { zone: 'Asia/Tokyo' }).toFormat('yyyy年L月d日 HH:mm')
}

export const isPastFromsql = (sqlFormat) => {
  const duration = DateTime.fromSQL(sqlFormat, { zone: 'Asia/Tokyo' }).diffNow().valueOf()
  if (duration >= 0) return false
  return true
}

export const fromIsoToFormat = (iso, format) => {
  if (!iso) return null
  return DateTime.fromISO(iso).toFormat(format)
}
