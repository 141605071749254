import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import {
  Typography,
  Container,
  Grid
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    maxWidth: '100%',
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
    margin: '0',
    textAlign: 'center'
  },
  container: {
    display: 'flex',
    paddingRight: 0,
    paddingLeft: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  list: {
    display: 'inline-blick',
    paddingLeft: '0',
    justifyContent: 'center',
    margin: 'auto',
    listStyle: 'none'
  },
  listItem: {
    textAlign: 'left',
    paddingRight: '20px',
    paddingLeft: '20px',
    height: '32px',
    display: 'list-item',
    fontSize: '14px',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.cassette.main
    }
  },
  link: {
    'text-decoration': 'none',
    color: 'inherit'
  }

}))

export const MenuCategory = (props) => {
  const classes = useStyles()
  return (
    <Typography component='div' className={classes.root}>
      <Container className={classes.container}>
        <Grid item xs={12}>
          <ul className={classes.list}>
            {props.categories.map(category => (
              <Link
                key={category.id}
                className={classes.link}
                to={'/Category/' + category.id}
              >
                <li className={classes.listItem}>
                  {category.name}
                </li>
              </Link>
            ))}
          </ul>
        </Grid>
      </Container>
    </Typography>
  )
}
