import React from 'react'
import { Body } from './Body'
import { Helmet } from './Helmet'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = (props) => {
  const params = props.match.params
  const queryParams = new URLSearchParams(props.location.search)
  const content = <>
    <Helmet />
    <Body postId={params.id} nonce={queryParams.get('token')} isPreview={queryParams.get('preview')} />
  </>

  return <TemplateSideColumn content={content}/>
}
