import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import twitter from 'image/twitterIconBlue.png'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    display: 'inline-flex',
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      margin: 0
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  icon: {
    width: '20px',
    height: '20px',
    margin: 'auto'
  },
  aa: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    borderRadius: '10px'
  }
}))

export const TwitterIcon = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <a href='https://twitter.com/netamatch' target='_blank' rel='noopener noreferrer' className={classes.aa}>
        <img alt='TWITTER' src={twitter} className={classes.icon} />
      </a>
    </div>

  )
}
