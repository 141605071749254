import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Parser, { domToReact } from 'html-react-parser'
import { ResourceContext } from 'App'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    color: theme.palette.text.primary,
    '& blockquote,& iframe': {
      margin: '0 auto!important'
    }
  },
  section: {
    marginTop: '24px',
    marginBottom: '16px'
  },
  paragraph: {
    marginBottom: '16px',
    lineHeight: '190%'
  },
  line: {
    backgroundColor: '#333333',
    width: '100%',
    height: '2px',
    margin: '0px'
  },
  image: {
    display: 'block',
    margin: 'auto',
    maxWidth: '100%',
    height: 'auto'
  }
}))

export const Content = (props) => {
  const classes = useStyles()
  const context = React.useContext(ResourceContext)

  const options = {
    replace: ({ name, attribs, children }) => {
      if (!name) return
      if (name === 'h2') {
        return <Typography variant='h2' className={classes.section}>{domToReact(children, options)}<hr className={classes.line} /></Typography>
      }
      if (name === 'p') {
        return <Typography variant='body1' className={classes.paragraph}>{domToReact(children, options)}</Typography>
      }
      if (name === 'img') {
        return <img className={classes.image} alt={attribs.alt} src={attribs.src} />
      }
      if (name === 'span') {
        return <span>{domToReact(children, options)}</span>
      }
    }
  }
  return (
    <div className={classes.wrapper}>
      {Parser(getContentHTML(context.user.isLogin(), context.isInvited, props.post.content), options)}
    </div>
  )
}

function getContentHTML (isLogin, isInvited, rawHTML) {
  if (isLogin || isInvited) return rawHTML
  return extractElements(rawHTML)
}

function htmlToElements (html) {
  const template = document.createElement('template')
  template.innerHTML = html
  return template.content.childNodes
}

function extractElements (html) {
  const returnElements = document.createElement('div')
  const maxTextCount = 200
  const nodeList = htmlToElements(html)

  let totalTextCount = 0
  for (let i = 0; i < nodeList.length; i++) {
    if (!nodeList[i].tagName) continue

    const textCount = nodeList[i].innerText.length
    if (i > 1 && nodeList[i].tagName !== 'P') break

    if (isVideoEmbedTag(nodeList[i].tagName)) {
      returnElements.appendChild(nodeList[i])
      totalTextCount += textCount
      continue
    }

    if ((totalTextCount + textCount) > maxTextCount) {
      nodeList[i].innerText = nodeList[i].innerText.substr(0, maxTextCount - totalTextCount)
      returnElements.appendChild(nodeList[i])
      break
    }
    returnElements.appendChild(nodeList[i])
    totalTextCount += textCount
  }
  returnElements.lastChild.innerText += '・・・'
  return returnElements.innerHTML
}

const isVideoEmbedTag = (tagName) => {
  return tagName === 'BLOCKQUOTE' || tagName === 'FIGURE'
}
