import React from 'react'
import { useQuery } from 'react-query'
import WpApi from 'api/WpApi'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { Title } from './Title'
import { SubTitle } from './SubTitle'
import { Contact } from './Contact'
import { NewPosts } from './NewPosts'
import { setPageTitle } from 'hooks/setPageTitle'
import TitleUtil from 'utility/TitleUtil'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '702px',
    margin: 'auto'
  }
}))

export const Body = (props) => {
  const classes = useStyles()
  const { post_id: postId, nonce, isPreview } = props
  const { data: post } = useQuery(['mediaDoc', postId], () => WpApi.getMediaDoc(postId, nonce, isPreview))
  console.log(post)
  setPageTitle(TitleUtil.mediaDocDetail(post))

  if (!post) return null

  return (
    <>
      <Box className={classes.root}>
        <Title post={post} />
        <SubTitle post={post} />
        <Contact post={post} />
      </Box>
      <NewPosts />
    </>
  )
}
