import React from 'react'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'
import { SearchPosts } from './SearchPosts'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = (props) => {
  setPageTitle(TitleUtil.search())

  const replaceWhiteSpace = (string) => {
    return string.replace(/\u3000/g, ' ')
  }

  const params = new URLSearchParams(props.location.search)
  const searchWord = replaceWhiteSpace(params.get('s'))
  const content = <>
    <SearchPosts searchWord={searchWord} />
  </>

  return <TemplateSideColumn content={content}/>
}
