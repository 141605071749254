import React from 'react'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'
import { Body } from './Body'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = (props) => {
  const name =
    decodeURI(new URLSearchParams(props.location.search).get('name')) || ''
  const email =
    decodeURI(new URLSearchParams(props.location.search).get('email')) || ''
  const tel =
    decodeURI(new URLSearchParams(props.location.search).get('tel')) || ''
  const company =
    decodeURI(new URLSearchParams(props.location.search).get('company')) || ''
  const job =
    decodeURI(new URLSearchParams(props.location.search).get('job')) || ''
  const media =
    decodeURI(new URLSearchParams(props.location.search).get('media')) || ''

  setPageTitle(TitleUtil.eventEntryComplete())
  const content = <>
    <Body
      email={email}
      tel={tel}
      name={name}
      job={job}
      company={company}
      media={media}
    />
  </>

  return (
    <TemplateSideColumn content={content} />
  )
}
