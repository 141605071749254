import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px',
    textAlign: 'right',
    color: theme.palette.primary.main,
    top: '-50px',
    position: 'relative'
  },
  link: {
    'text-decoration': 'none'
  }
}))

export const NmFormOuterTextLink = (props) => {
  const classes = useStyles()
  return (
    <a
      className={classes.link}
      href={props.to}
    >
      <Typography variant='body2' className={classes.root}>
        {props.children}
      </Typography>
    </a>
  )
}
