import React from 'react'
import { Box } from '@material-ui/core'

export const Error = (props) => {
  const { height } = props
  return (
    <Box
      display='flex'
      height={height || 300}
      alignItems='center'
      justifyContent='center'
    >
      {props.children}
    </Box>
  )
}
