import React from 'react'
import WpApi from '../../api/WpApi'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useQuery } from 'react-query'
import { PostCardVertically } from 'shared/PostCardVertically'
import { Link } from 'shared/atoms/Link'



const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '160px',
    maxWidth: '160px',
    height: '258px',
    maxHeight: '258px',
    padding: 10
  },
  titleArea: {
    height: '42px',
    maxHeight: '62px',
    marginBottom: '8px',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '140%',
  },
  iconStyle: {
    verticalAlign: 'middle',
    marginTop: '-5px',
  }
  
}))

export const SingleCategoryOverviewSp = (props) => {
  const classes = useStyles()
  const { category, rootCategories } = props
  const categoryId = category.id
  const perPage = 1
  const offset = 0
  const { data } = useQuery(['singleCategoryOverview', categoryId], () =>
    WpApi.getCategoryPosts(categoryId, perPage, offset)
  )
  const post = data?.body[0]
  if (!post) return null

  return (
    <Box className={`${classes.wrapper}`} >
      <Box className={classes.titleArea}>
        <Link to={`/Category/${category.id}`}>
          <Typography variant={'h4'} className={classes.title} >{`${category.name} `}<KeyboardArrowRightIcon className={classes.iconStyle} /></Typography>
        </Link>
      </Box>
      <Box><PostCardVertically post={post} rootCategories={rootCategories} /></Box>
    </Box>
  )
}
