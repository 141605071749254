import QueryItem from 'utility/query_item/QueryItem'
import { useInfiniteQuery } from 'react-query'
import * as ArrayUtil from 'utility/ArrayUtil'

/**
 * mainとsubの２種類のクエリから取得して[sub, main, main, main]を * 4行の形(sub3つ、main9つ)になるように
 * ソートした上で１次元配列（length=12）にならして返す
 * どちらかに不足分があった場合、片方に不足分を上乗せして取得する
 */
export default class InfiniteQueryUnshiftSubItemUtil {
  queryItems
  static MainItemSizePerRow = 2
  static SizePerRow = 3

  constructor() {
    this.queryItems = new Map()
  }
  setMainQueryItem(queryItem) {
    if (!(queryItem instanceof QueryItem))
      throw new Error('不正なインスタンスです')
    this.queryItems.set('main', queryItem)

    return this
  }
  setSubQueryItem(queryItem) {
    if (!(queryItem instanceof QueryItem))
      throw new Error('不正なインスタンスです')
    this.queryItems.set('sub', queryItem)

    return this
  }

  /**
   * useInfiniteQueryを実行してリザルトを返す
   * useInfiniteQueryはreact-queryで用意されている関数
   * @param {string[]} queryKey キャッシュのindexとしてreact-queryが使用する文字列の配列
   * @returns
   */
  fetch(queryKey) {
    return useInfiniteQuery(queryKey, this.generateFetch(), {
      getNextPageParam: this.generateGetNextPageParam()
    })
  }

  /**
   * fetchメソッドのuseInfiniteQueryに渡される関数
   * 具体的なクエリ問い合わせ処理を行っている
   */
  async _fetch({ pageParam = new Map() }) {
    const eachFetchData = new Map()
    // mapオブジェクトはforEachしか使えないが、それではawaitがうまく機能しないためarrayに変換している
    // 参考: https://dev.classmethod.jp/articles/foreach-async-await/
    const queryItemArrayFromMap = Array.from(this.queryItems.entries())
    await Promise.all(
      queryItemArrayFromMap.map(async ([key, queryItem]) => {
        const eachPageParam = pageParam.get(key)
        const perPage = eachPageParam?.perPage || queryItem.getDefaultPerPage()
        const offset = eachPageParam?.offset || 0
        const fetchResult = await queryItem.fetch(perPage, offset)
        eachFetchData.set(key, fetchResult)
      })
    )
    // 1ページのmainとsubを１次元の配列にならす
    const mainItems = this.getItemsFromFetchData(eachFetchData.get('main'))
    const subItems = this.getItemsFromFetchData(eachFetchData.get('sub'))
    const sortedItems = this.sortItems(mainItems, subItems)

    // getNextPageParamのlastPageとして渡される
    return {
      res: eachFetchData,
      items: sortedItems
    }
  }

  sortItems(mainItems, subItems) {
    return this.unshiftSubItems(mainItems, subItems)
  }

  unshiftSubItems(mainItems, subItems) {
    // array.shiftすると元の配列に影響を与えるのでコピーする
    const subItemsCopy = Array.from(subItems)
    const SizePerRow = InfiniteQueryUnshiftSubItemUtil.SizePerRow
    const MainItemSizePerRow =
      InfiniteQueryUnshiftSubItemUtil.MainItemSizePerRow
    // mainItemsを3個ずつのチャンクに分割
    const chunkedMainItems = ArrayUtil.chunk(mainItems, MainItemSizePerRow)
    let mainItemsUnshiftSubItem = chunkedMainItems
      .map((chunk, index) => {
        // subItemから１つ取り出して、mainItemの先頭に加える
        const subItem = subItemsCopy.shift()
        if (subItem) chunk.unshift(subItem)
        const shortageForChunk = SizePerRow - chunk.length
        // チャンクの長さが行の規定の長さ（=4）に達してない場合は不足分をsubItemsから補充する
        if (chunk.length < SizePerRow) {
          const subItemsForFill = subItem?.slice(0, shortageForChunk) || []
          chunk = chunk.concat(subItemsForFill)
        }
        return chunk
      })
      .flat()
    // チャンク数が規定の長さ（=3）に達してない場合は、残りのsubItemsをすべて加える
    if (subItemsCopy.length > 0) {
      mainItemsUnshiftSubItem = mainItemsUnshiftSubItem.concat(subItemsCopy)
    }

    return mainItemsUnshiftSubItem
  }

  getItemsFromFetchData(fetchData) {
    return fetchData?.body || []
  }

  // そのままだと_fetch内のthisがundefinedになってしまうので、thisをbindしている
  generateFetch() {
    return this._fetch.bind(this)
  }
  getNextPageParam(lastPage, pages) {
    const params = new Map()
    const pagingInfos = new Map()

    // 取得済みの結果から、合計取得件数や不足数を割り出す
    // 不足がある場合は後で個別にperPage増やす
    // また、デフォルトのページ単位件数や、offsetを設定していく、
    this.queryItems.forEach((queryItem, key) => {
      const lastPageInfo = lastPage.res.get(key)
      const sumFetchedItems = this.sumFetchedItemsByPages(pages, key)
      const remainItems = Math.max(lastPageInfo.count - sumFetchedItems, 0)
      const shortage = queryItem.getShortageFromRemainItems(remainItems)
      const pagingInfo = {
        queryItem,
        sumFetchedItems,
        remainItems,
        shortage
      }
      pagingInfos.set(key, pagingInfo)
      params.set(key, {
        perPage: queryItem.getDefaultPerPage(),
        offset: sumFetchedItems
      })
    })
    // すべてのページが最後になった場合はundefined
    // undefinedを渡すとhasNextPageがfalseに変わる
    const pagingInfoValues = Array.from(pagingInfos.values())
    if (pagingInfoValues.every((pagingInfo) => pagingInfo.remainItems === 0))
      return undefined

    // 両方とも不足する場合はそれぞれデフォの個数を取得
    if (pagingInfoValues.every((pagingInfo) => pagingInfo.shortage > 0))
      return params

    // 次のリクエストでどちらか片方がperPageを下回る場合、もう一方はその不足分だけ多く取得して
    // 常に規定の個数（デフォは12）になるように取得する
    const mainPagingInfo = pagingInfos.get('main')
    const subPagingInfo = pagingInfos.get('sub')
    if (mainPagingInfo.shortage > 0) {
      const subParam = params.get('sub')
      const subQueryItem = this.queryItems.get('sub')
      // メインの不足分だけサブに取得させる
      params.set('sub', {
        ...subParam,
        perPage: subQueryItem.getDefaultPerPage() + mainPagingInfo.shortage
      })
    }
    if (subPagingInfo.shortage > 0) {
      const mainParam = params.get('main')
      const mainQueryItem = this.queryItems.get('main')
      // サブの不足分だけメインに取得させる
      params.set('main', {
        ...mainParam,
        perPage: mainQueryItem.getDefaultPerPage() + subPagingInfo.shortage
      })
    }

    return params
  }
  generateGetNextPageParam() {
    return this.getNextPageParam.bind(this)
  }
  // 全ページ（これまでfetchしたもの）のアイテム数をカウント
  sumFetchedItemsByPages(pages, index) {
    if (!pages) return 0

    return pages.reduce((count, page) => {
      const items = page.res.get(index).body

      return count + items?.length
    }, 0)
  }
}
