import React from 'react'
import { Container, Grid } from '@material-ui/core'
import { Post } from 'domain/entity/Post'
import { useQuery } from 'react-query'
import WpApi from 'api/WpApi'
import { Posts } from 'shared/Posts'
import { limitedCategoryLength } from 'const/Const'

export const NewPosts = (props) => {
  const { data: res } = useQuery(['newPosts'], () => WpApi.getNewPosts(3))
  const posts = res?.body

  return (
    <Container>
      <Posts sectionTitle={'新着記事'} posts={posts} />
    </Container>
  )
  
}
