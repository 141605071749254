import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%'
  },
  title: {
    marginTop: '24px',
    marginBottom: '24px'
  },
  subtitle: {
    marginTop: '24px',
    marginBottom: '8px'
  },
  paragraph: {
    marginBottom: '40px'
  }
}))

export const Content = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Typography variant='h1' className={classes.title}>利用規約</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        <strong>
          この利用規約（以下、「本規約」といいます。）は、PR オートメーション株式会社および株式会社エムスリー・カンパニー、株式会社ジェイアンドティプランニング（以下、「当社グループ」といいます。）がこの『ネタマッチ』上で提供するサービス（以下、「本 サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
        </strong>
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第１条（適用）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        本規約は、ユーザーと当社グループとの間の本サービスの利用に関わる一切の関係に適用されるものとします。
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第２条（利用登録）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        本サービスへの登録を希望する者は、本規約に同意した上で、当社グループの定める方法によって利用登録を申請します。当社グループがこれを承認することによって、利用登録が完了するものとし、この利用登録の完了をもって、ユーザーと当社グループとの間で本規約を内容とする本サービスに関する契約が成立するものとします。<br />
        当社グループは、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。<br />
        ・利用登録の申請に際して虚偽の事項を届け出た場合<br />
        ・本規約に違反したことがある者からの申請である場合<br />
        ・その他、当社グループが利用登録を相当でないと判断した場合<br />
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第３条（ユーザーIDおよびパスワードの管理）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを管理するものとします。<br />
        ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与することはできません。当社グループは、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。<br />
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第４条（禁止事項）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。<br />
        法令または公序良俗に違反する行為<br />
        犯罪行為に関連する行為<br />
        当社グループのサーバーまたはネットワークの機能を破壊したり、妨害したりする行為<br />
        当社グループのサービスの運営を妨害するおそれのある行為<br />
        他のユーザーに関する個人情報等を収集または蓄積する行為<br />
        他のユーザーに成りすます行為<br />
        当社グループのサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為<br />
        その他、当社グループが不適切と判断する行為<br />
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第５条（本サービスの提供の停止等）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        当社グループは、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。<br />
        本サービスにかかるコンピュータシステムの保守点検または更新を行う場合<br />
        地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合<br />
        コンピュータまたは通信回線等が事故により停止した場合<br />
        その他、当社グループが本サービスの提供が困難と判断した場合<br />
        当社グループは、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。<br />
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第６条（利用制限および登録抹消）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        当社グループは、以下の場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消し、本契約を終了することができるものとします。<br />
        本規約のいずれかの条項に違反した場合<br />
        登録事項に虚偽の事実があることが判明した場合<br />
        その他、当社グループが本サービスの利用を適当でないと判断した場合<br />
        当社グループは、本条に基づき当社グループが行った行為によりユーザーまたは第三者に生じた損害について、一切の責任を負いません。<br />
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第７条（免責事項）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        本サービスの対価が無償の場合、本規約は、当社グループのユーザーに対する債務を生じさせるものではないため、当社グループは、本サービスに関し、ユーザーに対して債務不履行責任その他一切の責任を負わないものとします。<br />
        本サービスの対価が有償の場合であっても、当社グループは、本サービスの提供にあたり故意または重過失がある場合を除いて、ユーザーに対し債務不履行責任その他責任を負わないものとします。<br />
        当社グループは、不法行為その他何らかの理由によって責任を負う場合にも、通常生じうる損害の範囲内かつ有料サービスにおいては代金額（継続的サービスの場合には1か月分相当額）の範囲内においてのみ賠償の責任を負うものとします。<br />
        当社グループは、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。<br />
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第８条（サービス内容の変更等）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        当社グループは、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、ユーザーが本サービスの利用を継続することによってこれに同意したものとみなされるものとします。なお、当該変更等によってユーザーに生じた損害について、当社グループは一切の責任を負いません。
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第９条（利用規約の変更）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        当社グループは、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとし、ユーザーが本サービスの利用を継続することによってこれに同意したものとみなされるものとします。なお、当該変更等にこれによってユーザーに生じた損害について、当社グループは一切の責任を負いません。
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第１０条（通知または連絡）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        ユーザーと当社グループとの間の通知または連絡は、当社グループの定める方法によって行うものとします。
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第１１条（権利義務の譲渡の禁止）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        ユーザーは、当社グループの書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第１２条（準拠法・裁判管轄）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        本規約の解釈にあたっては、日本法を準拠法とします。<br />
        本サービスに関して紛争が生じた場合には、東京地方裁判所を専属的合意管轄とします。<br />
        以上<br />
      </Typography>
      <Typography variant='body1' className={classes.paragraph}>
        最終更新日：2020年6月20日
      </Typography>
    </div>
  )
}
