import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Collapse, Grid, List, ListItem, ListItemText } from '@material-ui/core'
import { NmButton } from 'shared/atoms/NmButton.js'
import { ResourceContext } from 'App'
import Alert from '@material-ui/lab/Alert/Alert'
import { getIdToken } from 'auth/AwsAuth'
import { FetchAttendances } from 'api/NetamatchApi'
import { sqlFormatToJapaneseString } from 'utility/DateTimeUtil'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.cassette.main,
    textAlign: 'center'
  },
  detail: {
    margin: theme.spacing(2),
    width: '90%',
    borderRadius: '9px',
    backgroundColor: theme.palette.common.white,
    paddingBottom: '16px'
  },
  collapse: {
    width: '500px',
    margin: '10px auto 0px'
  },
  collapseInner: {
    marginBottom: '10px'
  },
  listTitle: {
    width: '60px',
    flex: 'none'
  },
  descriptionContainer: {
    padding: '28px 28px 0px 28px'
  },
  listItem: {
    padding: '0px'
  },
  recommendMessage: {
    width: '100%',
    fontSize: '8px',
    fontWeight: 'bold'
  },
  entryButton: {
    backgroundColor: 'rgba(255, 90, 90, 0.3)',
    color: theme.palette.text.primary,
    marginBottom: '20px',
    '&:hover': {
      backgroundColor: 'rgba(210, 44, 52, 0.3)'
    }
  },
  loginButton: {
    marginBottom: '20px'
  },
  signUpButton: {
    marginBottom: '20px'
  }
}))

export const Entry = (props) => {
  const context = React.useContext(ResourceContext)
  const [error, setError] = useState(false)
  const [apiError, setApiError] = useState(false)
  const [isAttend, setIsAttend] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    getIdToken().then((result) => {
      FetchAttendances(1, result).then((response) => {
        if (response.status > 200) {
          console.log('error: {statusCode: ' + response.status + ', body: ' + response.data + '}')
          return
        }
        if (response.data.attendances.find((v) => String(v.postId) === String(props.event_id))) {
          setIsAttend(true)
        }
      })
    }).catch((err) => {
      console.log(err)
    })
  }, [props.event])

  const handleClickEntry = () => {
    window.location.href = '/EventEntry?postId=' + props.event_id
  }

  const ButtonView = () => {
    if (isAttend) {
      return (<NmButton text='申し込み済み' disabled color='primary' onClick={() => { }} />)
    }

    if (context.user.isLogin()) {
      return (<NmButton text='イベントへ申し込む' color='primary' onClick={() => { handleClickEntry() }} />)
    }

    return (
      <div>
        <div>
          <NmButton className={classes.entryButton} text='申し込む' color='accent' onClick={() => { handleClickEntry() }} />
        </div>
        <div>
          <NmButton className={classes.loginButton} text='ログインして申し込む' link='/Login' color='primary' />
        </div>
      </div>)
  }

  const DateListArea = () => {
    return (
      <ListArea name='日時' text={sqlFormatToJapaneseString(props.event.scheduledDate) + '〜'} />
    )
  }

  const PlaceListArea = () => {
    switch (props.event.fetchAttendanceTypeString()) {
      case props.event.attendanceTypeStringList.online:
        return <ListArea name='会場' text='オンライン' />
      case props.event.attendanceTypeStringList.offline:
      case props.event.attendanceTypeStringList.both:
      default:
        return <ListArea name='会場' text={props.event.location} />
    }
  }

  const ListArea = (props) => {
    return (
      <ListItem className={classes.listItem}>
        <ListItemText className={classes.listTitle} primary={props.name} />
        <ListItemText primary={props.text} />
      </ListItem>
    )
  }

  return (
    <Grid container className={classes.wrapper}>
      <Grid item className={classes.detail}>

        <List className={classes.descriptionContainer}>
          <DateListArea />
          <PlaceListArea />
        </List>
        <Collapse className={classes.collapse} in={error}>
          <Alert className={classes.collapseInner} severity='error' onClose={() => { setError(false) }}>出席の種類を選択してください</Alert>
        </Collapse>
        <Collapse className={classes.collapse} in={apiError}>
          <Alert className={classes.collapseInner} severity='error' onClose={() => { setApiError(false) }}>エラーが発生しました。大変お手数ですがお問い合わせください</Alert>
        </Collapse>
        <ButtonView />
      </Grid>
    </Grid>
  )
}
