import InfiniteQueryUtil from 'utility/InfiniteQueryUtil'
import QueryItemCategoryPost from 'utility/query_item/CategoryPost'

export const useInfiniteCategoryPosts = (categoryId) => {
  const queryItemCategoryPost = new QueryItemCategoryPost({ categoryId, perPage: 12 })
  const infiniteQueryUtil = new InfiniteQueryUtil()
  infiniteQueryUtil.setQueryItem(queryItemCategoryPost)
  const res = infiniteQueryUtil.fetch(['categoryPost', categoryId])
  const categoryPosts = res?.data?.pages?.reduce((posts, page) => posts.concat(page?.items), [])

  return {
    ...res,
    data: categoryPosts
  }
}
