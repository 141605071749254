import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { withFormik } from 'formik'
import { resendConfirmationCode } from 'auth/AwsAuth'
import { NmFormTextField } from 'shared/NmForm/NmFormTextField'
import { NmForm } from 'shared/NmForm'
import { NmFormText } from 'shared/NmForm/NmFormText'
import { NmFormSubmitGroup } from 'shared/NmForm/NmFormSubmitGroup'
import { NmFormButton } from 'shared/NmForm/NmFormButton'
import * as Yup from 'yup'
import { AuthErrorCode } from 'auth/ConstErrorCode'

const useStyles = theme => ({
  root: {
    margin: '0 auto 0',
    padding: '10px',
    maxWidth: '480px',
    justifyContent: 'center',
  },
  isConsent: {
    paddingBottom: theme.spacing(1)
  },
  consentHelperText: {
    minHeight: 0,
    margin: 0
  }
})

const form = props => {
  const {
    classes,
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit
  } = props

  return (
    <Grid className={classes.root}>
      <Grid>
        <NmForm title='認証コードの再発行' onSubmit={handleSubmit}>
          <NmFormText>
            ご登録されたメールアドレスを入力すると認証コードが送信されます。
          </NmFormText>
          <NmFormTextField
            name='email'
            label='メールアドレス'
            required
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email ? errors.email : ''}
          />
          <NmFormSubmitGroup>
            <NmFormButton title='送信する' disabled={isSubmitting} />
          </NmFormSubmitGroup>
        </NmForm>
      </Grid>
    </Grid>
  )
}
const Form = withFormik({
  mapPropsToValues: () => {
    return {
      email: ''
    }
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .required('必須項目です')
      .email('メールアドレスの形式が正しくありません')
  }),
  handleSubmit: (values, { setSubmitting, setFieldError }) => {
    resendConfirmationCode(values.email)
      .then(result => {
        window.location.href = '/SignupConfirm?email=' + encodeURIComponent(values.email)
      })
      .catch(err => {
        switch (err.message) {
          case AuthErrorCode.UserNotFoundError:
            setFieldError('email', 'このメールアドレスは登録されていません')
            break
          case AuthErrorCode.InvalidParameterError:
            setFieldError('email', 'このメールアドレスは認証済みです')
            break
          default:
            setFieldError('email', '認証コードを送信することができませんでした。大変お手数ですがお問い合わせください。')
        }
      })
    setSubmitting(false)
  }
})(form)

export const Body = withStyles(useStyles)(Form)
