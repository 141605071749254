import React, { useEffect, useState } from 'react'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'
import { Point } from './Point'
import { Image } from './Image'
import { Title } from './Title'
import { Date } from './Date'
import { Content } from './Content'
import WpApi from 'api/WpApi'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Entry } from './Entry'
import { PostSkeleton } from 'shared/skeletons/PostSkeleton'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '702px',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

export const Body = (props) => {
  const classes = useStyles()
  const [event, setEvent] = useState(null)

  useEffect(() => {
    WpApi.getEvent(props.event_id, props.nonce, props.isPreview).then((event) =>
      setEvent(event)
    )
  }, [props])
  setPageTitle(TitleUtil.eventDetail(event))

  if (event !== null) {
    return (
      <Container className={classes.root}>
        <Title event={event} />
        <Date event={event} />
        <Image event={event} />
        <Point event={event} />
        <Content event={event} />
        <Entry event={event} event_id={props.event_id} />
      </Container>
    )
  }
  return <PostSkeleton />
}
