import React from 'react'
import { Grid, withStyles, Collapse } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { signIn as signInAws } from 'auth/AwsAuth'
import { NmForm } from 'shared/NmForm'
import { NmFormTextField } from 'shared/NmForm/NmFormTextField'
import { NmFormSubmitGroup } from 'shared/NmForm/NmFormSubmitGroup'
import { NmFormButton } from 'shared/NmForm/NmFormButton'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { NmFormTextLink } from 'shared/NmForm/NmFormTextLink'
import { NmFormOuterTextLink } from 'shared/NmForm/NmFormOuterTextLink'
import { AuthErrorCode } from 'auth/ConstErrorCode'

const useStyles = theme => ({
  root: {
    margin: '0 auto 0',
    padding: '10px',
    maxWidth: '480px',
    justifyContent: 'center'
  }
})

const form = props => {
  const {
    classes,
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit
  } = props
  return (
    <Grid className={classes.root}>
      <Grid>
        <NmForm title='ログイン' onSubmit={handleSubmit}>
          <Collapse in={props.status && props.status.authError}>
            <Alert severity='error' onClose={() => { props.setStatus({ authError: false }) }}>{props.status && props.status.authErrorMessage}</Alert>
          </Collapse>
          <NmFormTextField
            name='email'
            label='メールアドレス'
            required
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email ? errors.email : ''}
          />
          <NmFormTextField
            name='password'
            label='パスワード'
            type='password'
            required
            onChange={handleChange}
            value={values.password}
            onBlur={handleBlur}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password ? errors.password : ''}
          />
          <NmFormTextLink to='/ForgotPassword'>
            パスワードをお忘れの場合
          </NmFormTextLink>
          <NmFormSubmitGroup>
            <NmFormButton title='ログイン' disabled={isSubmitting} />
          </NmFormSubmitGroup>
        </NmForm>
        <NmFormOuterTextLink to='https://app.netamatch.com/'>
          企業会員の方はこちら
        </NmFormOuterTextLink>
      </Grid>
    </Grid>
  )
}

const Form = withFormik({
  mapPropsToValues: (
    {
      email,
      password
    }) => {
    return {
      email: email || '',
      password: password || ''
    }
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .required('必須項目です'),
    password: Yup.string()
      .required('必須項目です')
  }),
  handleSubmit: (values, { setSubmitting, setStatus }) => {
    signInAws(values.email, values.password)
      .then(result => {
        window.location.href = getReturnPath()
      })
      .catch(err => {
        switch (err.message) {
          case AuthErrorCode.NotAuthorizedError:
          case AuthErrorCode.UserNotFoundError:
            setStatus({ authErrorMessage: 'メールアドレスまたはパスワードに誤りがあります。', authError: true })
            break
          case AuthErrorCode.UserNotConfirmedError:
            setStatus({ authErrorMessage: 'メールアドレスの認証が完了していません。', authError: true })
            break
          default:
            setStatus({ authErrorMessage: 'エラーが発生しました。大変お手数ですがお問い合わせください。', authError: true })
        }
      }).finally(() => {
        setSubmitting(false)
      })
  }
})(form)

const getReturnPath = () => {
  if (!existsReferrer()) return '/'
  if (isAccessFromOtherSites()) return '/'
  if (isReturnHomeTargetPath()) return '/'
  return document.referrer
}

const existsReferrer = () => {
  return document.referrer
}

const isAccessFromOtherSites = () => {
  return (new URL(document.referrer).hostname).indexOf(process.env.REACT_APP_HOST_NAME) === -1
}

const isReturnHomeTargetPath = () => {
  const pathsToReturnHome = ['Signup',
    'SignupConfirm',
    'SignupComplete',
    'ForgotPassword',
    'ResetPassword',
    'ResetPasswordComplete',
    'Contact',
    'ContactThanks',
    'Login',
    'Logout',
    'AccountDetail',
    'AccountUpdate']
  return pathsToReturnHome.find(item => (new URL(document.referrer).pathname).indexOf(item) !== -1)
}

export const Body = withStyles(useStyles)(Form)
