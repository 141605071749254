import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Collapse, Grid, Typography } from '@material-ui/core'
import { NmButton } from 'shared/atoms/NmButton.js'
import { ResourceContext } from 'App'
import { PostInquiryNeta } from 'api/NetamatchApi'
import Alert from '@material-ui/lab/Alert/Alert'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.cassette.main,
    textAlign: 'center'
  },
  detail: {
    margin: theme.spacing(2),
    width: '90%',
    borderRadius: '9px',
    backgroundColor: theme.palette.common.white
  },
  detailContainer: {
    margin: '10px auto',
    textAlign: 'left',
    color: theme.palette.text.accent,
    width: '300px'
  },
  leftButtonArea: {
    [theme.breakpoints.down('sm')]: {
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
      display: 'inline-block'
    }
  },
  rightButtonArea: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
      display: 'inline-block'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
  collapse: {
    marginBottom: theme.spacing(2)
  },
  buttonViewArea: {
    marginTop: theme.spacing(2)
  },
  Button: {
    marginBottom: theme.spacing(2)
  }
}))

export const Contact = (props) => {
  const context = React.useContext(ResourceContext)
  const [error, setError] = useState(false)

  const handleClick = () => {
    PostInquiryNeta(props.post.id)
      .then(() => {
        window.location.href = '/ContactThanks'
      })
      .catch(err => {
        console.log(err)
        setError(true)
      })
  }

  const ButtonView = () => {
    if (context.user.isLogin()) return (<NmButton className={classes.Button} text='お問い合わせはこちら' color='accent' onClick={() => { handleClick() }} />)
    if (context.isInvited) {
      if (props.post.hasDocUrl()) return (<NmButton className={classes.Button} text='本記事をダウンロード' color='accent' link={props.post.docUrl} />)
      if (!props.post.hasDocUrl()) return (<NmButton className={classes.Button} text='ログインして問い合わせる' link='/Login' color='primary' />)
    }
    return (
      <>
        <div className={classes.leftButtonArea}>
          <NmButton className={classes.Button} text='もっと読む' link='/Signup' color='accent' />
        </div>
        <div className={classes.rightButtonArea}>
          <NmButton className={classes.Button} text='ログインして読む' link='/Login' color='primary' />
        </div>
      </>
    )
  }

  const classes = useStyles()
  return (
    <Grid container className={classes.wrapper}>
      <Grid item className={classes.detail}>
        <div className={classes.detailContainer}>
          <Typography variant='subtitle1'>
            実名を含む詳細な記事内容をご提供<br />
            取材先の専門家をご紹介
          </Typography>
        </div>
      </Grid>
      <Grid item className={classes.buttonViewArea}>
        {ButtonView()}
      </Grid>
      <Grid item>
        <Collapse className={classes.collapse} in={error}>
          <Alert severity='error' onClose={() => { setError(false) }}>エラーが発生しました。大変お手数ですがお問い合わせください。</Alert>
        </Collapse>
      </Grid>
    </Grid>
  )
}
