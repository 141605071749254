import React from 'react'
import { useQuery } from "react-query"
import { Box, makeStyles, Typography } from '@material-ui/core'
import WpApi from "api/WpApi"
import { Tag } from "shared/atoms/Tag"

const useStyles = makeStyles(() => ({
  tagWrapper: {
    marginLeft: '-8px',
    marginRight: '-8px',
    '&>.tag': {
      margin: '8px',
      marginTop: '6px',
      marginBottom: '6px',
    }
  }
}))

export const SearchByTags = (props) => {
  const classes = useStyles(props)
  const { data } = useQuery(['tags'], () => WpApi.getTags())
  const tags = data?.body
  
  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Typography variant={'h2'}>キーワードから探す</Typography>
      <Box
        className={`${classes.tagWrapper}`}
        marginTop={'12px'}
        display="flex"
        flexDirection="row"
        flexWrap={'wrap'}
      >
        {tags?.map(tag => <Tag id={tag.id}>{tag.name}</Tag>)}
      </Box>
    </Box>
  )

}