import React, { useEffect, useState } from 'react'
import WpApi from 'api/WpApi'
import { Posts } from 'shared/Posts'
import { Container } from '@material-ui/core'
import Pagination from 'material-ui-flat-pagination/lib/Pagination'
import { defaultPostPerPage } from 'const/Const'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  pagination: {
    textAlign: 'center'
  }
}))

export const SearchPosts = (props) => {
  const classes = useStyles()
  const [pageOffset, setPageOffset] = React.useState(0)
  const [searchPosts, setSearchPosts] = useState([])
  const [searchPostsCount, setSearchPostsCount] = useState([])
  const sectionTitle = '「' + props.searchWord + '」の検索結果'

  const handleClickPagination = (offset) => {
    setPageOffset(offset)
    WpApi.getSearchPosts(props.searchWord, defaultPostPerPage, offset).then((response) => {
      setSearchPosts(response.body)
    })
  }

  useEffect(() => {
    WpApi.getSearchPosts(props.searchWord).then((response) => {
      setSearchPosts(response.body)
      setSearchPostsCount(response.count)
    })
  }, [props])

  return (
    <Container>
      <Posts posts={searchPosts} sectionTitle={sectionTitle} />
      <Pagination
        className={classes.pagination}
        limit={defaultPostPerPage}
        offset={pageOffset}
        total={searchPostsCount}
        onClick={(e, offset) => handleClickPagination(offset)}
      />
    </Container>
  )
}
