class IApi {
  static getPost (id) {
  }

  static getNewPosts () {
  }

  static getSearchPosts () {
  }

  static getCategoryPosts () {
  }

  static getTagPosts () {
  }

  static getFeaturePosts () {
  }

  static getEvent () {
  }

  static getFeature () {
  }

  static getFeatures () {
  }

  static getCategory () {
  }

  static getCategories () {
  }

  static getTag () {
  }

  static getTags () {
  }

  static async sendRequest (path, method, headers) {
  }
}

export default IApi
