import React from 'react'
import { Container, Grid } from '@material-ui/core'
import { Post } from 'domain/entity/Post'
import { useQuery } from 'react-query'
import WpApi from 'api/WpApi'
import { Posts } from 'shared/Posts'
import { limitedCategoryLength } from 'const/Const'

export const RelatedPosts = (props) => {
  const { post: postObj } = props
  const post = (postObj instanceof Post) ? postObj : new Post(postObj)
  const relatedPostIds = post.relatedPostIds
  const hasRelatedPosts = Array.isArray(relatedPostIds) && relatedPostIds.length > 0
  const { data: relatedPostsData } = useQuery(['relatedPosts', post.id, relatedPostIds], () => WpApi.getPostsByIds(relatedPostIds), {
    enabled: hasRelatedPosts
  })
  const relatedPosts = relatedPostsData?.body

  const { data: dataRootCategories } = useQuery(['rootCategories'], () =>
    WpApi.getRootCategories(), {
      enabled: !hasRelatedPosts
    }
  )
  const rootCategories = dataRootCategories?.body || []
  const rootCategoryIds = rootCategories.map(rootCategory => rootCategory.id) || []
  const parentCategoryIds = rootCategoryIds.length ? post.getCategoryCollection()
    .extractRootCategoriesByIds(rootCategoryIds)
    .slice(0, limitedCategoryLength) : []
  const { data: categoryPostsData } = useQuery(['categoryPosts', post.id, parentCategoryIds], () => WpApi.getLatestCategoryPostsByCategoryIds(parentCategoryIds), {
    enabled: !hasRelatedPosts && parentCategoryIds.length > 0
  })
  const categoryPosts = categoryPostsData?.body

  if (!relatedPosts && !categoryPosts) return null
  if (relatedPosts?.length === 0 && categoryPosts?.length === 0) return null

  return (
    <Container>
      <Posts sectionTitle={'関連記事'} posts={relatedPosts || categoryPosts} />
    </Container>
  )
  
}
