import React from 'react'
import { Content } from './Content'
import { GuestContent } from './GuestContent'
import { ResourceContext } from 'App'

export const Body = (props) => {
  const { searchParams } = props
  const { user } = React.useContext(ResourceContext)
  return (
    <>
      {
        user && user.isLogin()
          ? <Content />
          : <GuestContent searchParams={searchParams} />
      }
    </>
  )
}
