import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    maxWidth: '100%',
    width: '100%',
    fontSize: theme.typography.body2.fontSize,
    margin: '0 auto'
  },
  list: {
    maxWidth: '900px',
    justifyContent: 'center',
    padding: theme.spacing(1),
    textAlign: 'center',
    paddingLeft: 0
  },
  tag: {
    display: 'inline-block',
    fontSize: '16px'
  },
  tagInner: {
    display: 'inline-block',
    borderRadius: theme.spacing(1),
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: theme.palette.text.primary,
    color: theme.palette.text.primary,
    margin: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    'text-decoration': 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light
    }
  }
}))

export const MenuTag = (props) => {
  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <Grid container justifyContent='center'>
        <ul className={classes.list}>
          {props.tags.map(tag => (
            <li className={classes.tag} key={tag.id}>
              <Link
                className={classes.tagInner}
                to={'/Tag/' + tag.id}
              >#{tag.name}
              </Link>
            </li>
          ))}
        </ul>
      </Grid>
    </Container>
  )
}
