import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Container, Typography } from '@material-ui/core'
import { ResourceContext } from 'App'

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    width: '100%',
    maxWidth: '450px',
    marginTop: theme.spacing(6),
    padding: theme.spacing(1),
    borderStyle: 'solid',
    borderColor: '#1471D7',
    borderWidth: '3px'
  },
  title: {
    margin: '16px'
  },
  sourceContainer: {
    height: '40px',
    width: '100%'
  }
}))

export const NewsSource = (props) => {
  const classes = useStyles()
  const context = React.useContext(ResourceContext)

  // 未ログインの場合は見せない
  if (!context.user.isLogin()) return (<div />)
  return (
    <>
      <Container className={classes.container}>
        <Box className={classes.title}>
          <Typography variant='h2'>紹介可能な取材先</Typography>
        </Box>
        <Source value={props.post.maker} />
        <Source value={props.post.specialist} />
        <Source value={props.post.otherSpecialist} />
      </Container>
    </>
  )
}

const Source = (props) => {
  const classes = useStyles()
  if (props.value) {
    return (
      <Box className={classes.sourceContainer}>
        <Typography variant='subtitle2'>{props.value}</Typography>
      </Box>
    )
  }
  return (<div />)
}
