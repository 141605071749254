/**
 * @typedef TagProps
 * @property {number} id
 * @property {string} name
 */

export default class Tag {
  /**
   * @param {TagProps} props
   */
  constructor (props) {
    this.id = props.id
    this.name = props.name
  }

}
