
import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '702px',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  item: {
    paddingTop: theme.spacing(3)
  }
}))

export const PostSkeleton = () => {
  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <Typography variant='h1' className={classes.item}>
        <Skeleton variant='text' />
      </Typography>
      <Skeleton variant='rect' height={390} />
      <Skeleton variant='text' className={classes.item} />
      <Skeleton variant='text' className={classes.item} />
      <Skeleton variant='text' className={classes.item} />
      <Skeleton variant='text' className={classes.item} />
    </Container>
  )
}
