import React from 'react'

import { Form } from './Form'
import { GuestForm } from './GuestForm'
import { useAuth } from 'hooks/useAuth'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto 0',
    padding: '10px',
    maxWidth: '480px',
    justifyContent: 'center'
  }
}))

export const Container = (props) => {
  const classes = useStyles()
  const { user } = useAuth()
  const postId = props.postId
  const form = user.isLogin() ? <Form postId={postId} user={user} /> : <GuestForm postId={postId} />
  return (
    <Grid className={classes.root}>
      <Grid>{form}</Grid>
    </Grid>
  )
}
