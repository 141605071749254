import { useEffect } from 'react'
import { useAuth } from 'hooks/useAuth'
import { getIdToken } from 'auth/AwsAuth'

export const AuthState = (props) => {
  const { user, setToken } = useAuth()
  useEffect(() => {
    const fetch = async () => {
      const token = await getIdToken()
      setToken && setToken(token)
    }
    if (user.isLogin()) {
      fetch()
    }
  }, [user])

  return props.children
}
