import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '15px 0px',
    textAlign: 'left'
  }
}))

export const NmFormText = (props) => {
  const classes = useStyles()
  return (
    <Typography variant='body2' className={classes.root}>
      {props.children}
    </Typography>
  )
}
