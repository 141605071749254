import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Grid, MenuItem, Typography } from '@material-ui/core'
import { withFormik } from 'formik'
import { NmFormTextField } from 'shared/NmForm/NmFormTextField'
import { NmFormSubmitGroup } from 'shared/NmForm/NmFormSubmitGroup'
import { NmFormButton } from 'shared/NmForm/NmFormButton'
import { updateAttributes } from 'auth/AwsAuth'
import * as Yup from 'yup'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction'
import { Link } from 'react-router-dom'

const useStyles = theme => ({
  root: {
    margin: '0 auto 0',
    padding: '10px',
    maxWidth: '670px',
    justifyContent: 'center',
    display: 'flex'
  },
  isConsent: {
    paddingBottom: theme.spacing(1)
  },
  consentHelperText: {
    minHeight: 0,
    margin: 0
  },
  nmForm: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10)
  },
  formControl: {
    width: '328px',
    backgroundColor: theme.palette.cassette.main,
    textAlign: 'center',
    padding: '30px 76px',
    display: 'block'
  },
  link: {
    fontSize: '16px',
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  list: {
    marginBottom: theme.spacing(2),
    paddingBottom: 0,
    paddingTop: 0
  },
  listItem: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 0
  }
})
const jobCategories = [
  'プロデューサー',
  'アシスタントプロデューサー',
  'ディレクター',
  'アシスタントディレクター',
  'リサーチャー',
  '編集担当',
  '編集長',
  '記者/ライター',
  'その他'
]

const form = props => {
  const {
    classes,
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit
  } = props

  return (
    <Grid container className={classes.root}>
      <Grid>
        <div className={classes.nmForm}>
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <Typography variant='h2'>
                アカウント編集
              </Typography>

              <ListItemSecondaryAction>
                <Link to='/AccountDetail' className={classes.link}>
                  <Button color='primary'>キャンセル</Button>
                </Link>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <form onSubmit={handleSubmit} className={classes.formControl} autoComplete='off'>
            <NmFormTextField
              name='name'
              label='お名前'
              required
              onChange={handleChange}
              value={values.name}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name ? errors.name : ''}
            />
            <NmFormTextField
              name='company'
              label='会社名'
              required
              onChange={handleChange}
              value={values.company}
              onBlur={handleBlur}
              error={touched.company && Boolean(errors.company)}
              helperText={touched.company ? errors.company : ''}
            />
            <NmFormTextField
              name='media'
              label='媒体(番組)名'
              required
              onChange={handleChange}
              value={values.media}
              onBlur={handleBlur}
              error={touched.media && Boolean(errors.media)}
              helperText={touched.media ? errors.media : ''}
            />
            <NmFormTextField
              select
              name='job'
              label='職種'
              required
              onChange={handleChange}
              value={values.job}
              onBlur={handleBlur}
              error={touched.job && Boolean(errors.job)}
              helperText={touched.job ? errors.job : ''}
            >
              {jobCategories.map(category => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </NmFormTextField>

            <NmFormSubmitGroup>
              <NmFormButton title='変更を保存する' disabled={isSubmitting} />
            </NmFormSubmitGroup>
          </form>
        </div>

      </Grid>
    </Grid>
  )
}
const Form = withFormik({

  enableReinitialize: true,
  mapPropsToValues: (
    {
      name,
      company,
      media,
      job
    }) => {
    return {
      name: name || '',
      company: company || '',
      media: media || '',
      job: job || ''
    }
  },
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required('必須項目です'),
    company: Yup.string()
      .required('必須項目です'),
    media: Yup.string()
      .required('必須項目です'),
    job: Yup.string()
      .required('必須項目です')
  }),
  handleSubmit: (values, { setSubmitting, setFieldError }) => {
    console.log('fired!')
    updateAttributes(values.name, values.company, values.media, values.job)
      .then(result => {
        window.location.href = '/AccountDetail'
      })
      .catch(err => {
        switch (err.message) {
          case 'UsernameExistsError':
            setFieldError('email', '指定されたメールアドレスは既に登録されています')
            break
          default:
            setFieldError('email', 'エラーが発生しました。大変お手数ですがお問い合わせください')
        }
      }).finally(() => {
        setSubmitting(false)
      })
  }
})(form)

export const Update = withStyles(useStyles)(Form)
