export class Category {
  constructor (input) {
    this.id = input?.id
    this.name = input?.name
    this.parent = input?.parent
    this.count = input?.count
    this.description = input?.description
    this.slug = input?.slug
    this.taxonomy = input?.taxonomy
    this.meta = input?.meta
    this.acf = input?.acf
    this.link = input?.link
  }
}
