import WpApi from 'api/WpApi'
import QueryItem from 'utility/query_item/QueryItem'

export default class QueryItemNewPost extends QueryItem {
  perPage = 9
  constructor({ perPage }) {
    super()
    this.perPage = perPage
  }
  async fetch(perPage, offset) {
    return WpApi.getNewPosts(perPage, offset)
  }
}
