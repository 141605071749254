import React, { useEffect, useState } from 'react'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'
import WpApi from 'api/WpApi'
import { Posts } from 'shared/Posts'
import { Container } from '@material-ui/core'
import Pagination from 'material-ui-flat-pagination/lib/Pagination'
import { makeStyles } from '@material-ui/core/styles'
import { ButtonReadMore } from 'shared/molecules/button/ReadMore'
import { useInfiniteTagPosts } from 'hooks/useInfiniteTagPosts'

const useStyles = makeStyles((theme) => ({
  pagination: {
    textAlign: 'center'
  }
}))

export const TagPosts = (props) => {
  const classes = useStyles()
  const { tagId } = props
  const [tag, setTag] = useState([])
  const {
    data: tagPosts,
    fetchNextPage,
    hasNextPage
  } = useInfiniteTagPosts(tagId)

  useEffect(() => {
    WpApi.getTag(props.tagId).then((response) => {
      setTag(response)
    })
  }, [props])

  if (tag) setPageTitle(TitleUtil.tagPosts(tag))

  return (
    <Container>
      <Posts posts={tagPosts} sectionTitle={tag.name} />
      {/* <Pagination
        className={classes.pagination}
        limit={defaultPostPerPage}
        offset={pageOffset}
        total={tagPostsCount}
        onClick={(e, offset) => handleClickPagination(offset)}
      /> */}
      <ButtonReadMore hasNextPage={hasNextPage} onClick={fetchNextPage} />
    </Container>
  )
}
