import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  MenuItem,
  Typography,
  FormControl,
  FormHelperText,
  Button,
  FormControlLabel,
  FormLabel
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { NmFormTextField } from 'shared/NmForm/NmFormTextField'
import { NmForm } from 'shared/NmForm'
import { NmFormText } from 'shared/NmForm/NmFormText'
import { NmFormSubmitGroup } from 'shared/NmForm/NmFormSubmitGroup'
import { NmFormButton } from 'shared/NmForm/NmFormButton'
import { NmFormCheckbox } from 'shared/NmForm/NmFormCheckbox'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { signUp } from 'auth/AwsAuth'
import { AuthErrorCode } from 'auth/ConstErrorCode'

import {
  jobCategories,
  mediaCategories
} from 'const/Const'
import { NmFormSelect } from 'shared/NmForm/NmFormSelect'

const schema = Yup.object().shape({
  name: Yup.string().required('必須項目です'),
  email: Yup.string()
    .required('必須項目です')
    .email('メールアドレスの形式が正しくありません'),
  tel: Yup.string()
    .required('必須項目です')
    .matches(
      /^[0-9]{10,11}$/,
      '半角数字10-11文字で入力してください 例)0312345678'
    ),
  company: Yup.string().required('必須項目です'),
  media: Yup.string().required('必須項目です'),
  job: Yup.string().required('必須項目です'),
  password: Yup.string()
    .required('必須項目です')
    .min(8, 'パスワードは8文字以上で入力してください')
    .matches(
      /^(?=.*?[a-z])(?=.*?\d)[a-zA-Z\d!-/:-@[-`{-~]{8,99}$/,
      '半角英数字を組み合わせて入力してください'
    ),
  passwordConfirm: Yup.string()
    .required('必須項目です')
    .oneOf([Yup.ref('password')], 'パスワードと一致していません'),
  isConsent: Yup.boolean().oneOf([true], '必須項目です')
})

const Form = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { classes, ...defaultValues } = props
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      isConsent: false,
      ...defaultValues
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = (values) => {
    signUp(
      values.name,
      values.email,
      values.tel,
      values.company,
      values.media,
      values.job,
      values.password
    )
      .then((result) => {
        window.location.href =
          '/SignupConfirm?email=' + encodeURIComponent(values.email)
      })
      .catch((err) => {
        switch (err.message) {
          case AuthErrorCode.UsernameExistsError:
            setError('email', {
              type: 'manual',
              message: '指定されたメールアドレスは既に登録されています'
            })
            break
          default:
            setError('email', {
              type: 'manual',
              message:
                'エラーが発生しました。大変お手数ですがお問い合わせください'
            })
        }
      })
  }

  return (
    <NmForm title='新規会員登録' onSubmit={handleSubmit(onSubmit)}>
      <NmFormText>
        ネタについての情報提供をご希望の方は会員登録をお願いします。
      </NmFormText>
      <NmFormTextField
        label='お名前'
        {...register('name')}
        error={Boolean(errors.name)}
        helperText={errors?.name?.message}
      />
      <NmFormTextField
        label='メールアドレス'
        {...register('email')}
        error={Boolean(errors.email)}
        helperText={errors?.email?.message}
      />
      <NmFormTextField
        label='電話番号(ハイフン無し)'
        {...register('tel')}
        error={Boolean(errors.tel)}
        helperText={errors?.tel?.message}
      />
      <NmFormTextField
        label='会社名'
        {...register('company')}
        error={Boolean(errors.company)}
        helperText={errors?.company?.message}
      />
      <NmFormSelect
        name='job'
        label='職種'
        control={control} 
        error={Boolean(errors.job)}
        helperText={errors?.job?.message}
      >
        {jobCategories.map((category) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </NmFormSelect>
      <NmFormTextField
        label='媒体(番組)名'
        {...register('media')}
        error={Boolean(errors.media)}
        helperText={errors?.media?.message}
      />
      <NmFormTextField
        label='パスワード'
        type='password'
        {...register('password')}
        error={Boolean(errors.password)}
        helperText={errors?.password?.message}
      />
      <NmFormTextField
        label='パスワード(確認用)'
        type='password'
        {...register('passwordConfirm')}
        error={Boolean(errors.passwordConfirm)}
        helperText={errors?.passwordConfirm?.message}
      />
      <NmFormSubmitGroup>
        <FormControl
          error={Boolean(errors.isConsent)}
          className={classes.isConsent}
        >
          <div>
            <NmFormCheckbox {...register('isConsent')} />
            <Typography variant='caption'>
              <Link className={classes.policyLink} to='/Terms' target='_blank'>
                利用規約
              </Link>
              に同意する
            </Typography>
          </div>
          <FormHelperText className={classes.consentHelperText}>
            {errors?.isConsent?.message}
          </FormHelperText>
        </FormControl>
        <NmFormButton title='送信する' disabled={isSubmitting} />
        <Link to='/Login' className={classes.link}>
          <Button color='primary' className={classes.login}>
            ログインはこちら
          </Button>
        </Link>
      </NmFormSubmitGroup>
    </NmForm>
  )
}

const useStyles = (theme) => ({
  isConsent: {
    paddingBottom: theme.spacing(1)
  },
  consentHelperText: {
    minHeight: 0,
    margin: 0
  },
  link: {
    display: 'block',
    marginTop: '16px',
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  login: {
    fontSize: '14px'
  },
  policyLink: {
    'text-decoration': 'none'
  },
  inputTitle: {
    paddingTop: '25px',
    textAlign: 'left'
  }
})

export const StyledForm = withStyles(useStyles)(Form)
