// eslint-disable-next-line no-unused-vars
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withFormik } from 'formik'
import { PostAttendance, Attend } from 'api/NetamatchApi'
import * as Yup from 'yup'
import { Form } from './Form'
import { getIdToken } from 'auth/AwsAuth'

const useStyles = theme => ({
  root: {
    margin: '0 auto 0',
    padding: '10px',
    maxWidth: '670px',
    justifyContent: 'center',
    display: 'flex'
  },
  isConsent: {
    paddingBottom: theme.spacing(1)
  },
  consentHelperText: {
    minHeight: 0,
    margin: 0
  },
  link: {
    display: 'block',
    marginTop: '16px',
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  login: {
    fontSize: '14px'
  },
  policyLink: {
    'text-decoration': 'none'
  },
  inputTitle: {
    paddingTop: '25px',
    textAlign: 'left'
  }
})

const memberValidationSchema = {
  name: Yup.string()
    .required('必須項目です'),
  email: Yup.string()
    .required('必須項目です')
    .email('メールアドレスの形式が正しくありません'),
  tel: Yup.string()
    .required('必須項目です')
    .matches(/^[0-9]{10,11}$/, '半角数字10-11文字で入力してください 例)0312345678'),
  company: Yup.string()
    .required('必須項目です'),
  media: Yup.string()
    .required('必須項目です'),
  job: Yup.string()
    .required('必須項目です')
}

const createEventValidationSchema = (countMovieCamera, countPhotoCamera, companionOption, c1, c2, c3, c4, isC1Req, isC2Req, isC3Req, isC4Req) => {
  const schema = {}
  if (companionOption) {
    schema.companionCount = Yup.number().required('必須項目です')
    schema.companionNames = Yup.string()
  }
  if (countMovieCamera) schema.movieCameraCount = Yup.number().required('必須項目です')
  if (countPhotoCamera) schema.photoCameraCount = Yup.number().required('必須項目です')
  if (c1.length > 0 && isC1Req) schema.customFieldValue1 = Yup.string().required('必須項目です')
  if (c2.length > 0 && isC2Req) schema.customFieldValue2 = Yup.string().required('必須項目です')
  if (c3.length > 0 && isC3Req) schema.customFieldValue3 = Yup.string().required('必須項目です')
  if (c4.length > 0 && isC4Req) schema.customFieldValue4 = Yup.string().required('必須項目です')
  return schema
}

const createValidationSchema = (isLogin, event) => {
  console.log(isLogin)
  console.log(event.cameraOption)

  const schema = createEventValidationSchema(
    event.cameraOption,
    event.cameraOption,
    event.companionOption,
    event.customFieldTitle1,
    event.customFieldTitle2,
    event.customFieldTitle3,
    event.customFieldTitle4,
    event.isCustomField1Required,
    event.isCustomField2Required,
    event.isCustomField3Required,
    event.isCustomField4Required
  )

  if (isLogin) return schema
  return {
    ...memberValidationSchema,
    ...schema
  }
}

const NewForm = withFormik({
  mapPropsToValues: (
    {
      name,
      email,
      tel,
      company,
      media,
      job,
      isOnline,
      postId,
      event,
      companionCount,
      companionNames,
      movieCameraCount,
      photoCameraCount,
      customFieldValue1,
      customFieldValue2,
      customFieldValue3,
      customFieldValue4,
      apiError
    }) => {
    return {
      name: name || '',
      email: email || '',
      tel: tel || '',
      company: company || '',
      media: media || '',
      job: job || '',
      isOnline: isOnline || '',
      postId: postId || '',
      event: event || {},
      companionCount: companionCount || 0,
      companionNames: companionNames || '',
      movieCameraCount: movieCameraCount || 0,
      photoCameraCount: photoCameraCount || 0,
      customFieldValue1: customFieldValue1 || '',
      customFieldValue2: customFieldValue2 || '',
      customFieldValue3: customFieldValue3 || '',
      customFieldValue4: customFieldValue4 || '',
      apiError: apiError || ''
    }
  },
  validationSchema: (props) => { return Yup.object().shape(createValidationSchema(props.user.isLogin(), props.event)) },

  handleSubmit: (values, { props, setSubmitting, setFieldError }) => {
    const genReturnPath = (name, email, tel, company, job, media) => {
      const params = {
        name: name,
        email: email,
        tel: tel,
        company: company,
        job: job,
        media: media
      }
      const queryString = Object.entries(params).map((e) => `${e[0]}=${encodeURIComponent(e[1])}`).join('&')
      return 'EventEntryComplete?' + queryString
    }

    try {
      if (props.user.isLogin()) {
        getIdToken().then(accessToken => {
          Attend(values.postId,
            values.isOnline,
            accessToken,
            values.companionCount,
            values.companionNames,
            values.movieCameraCount,
            values.photoCameraCount,
            props.event.customFieldTitle1,
            values.customFieldValue1,
            props.event.customFieldTitle2,
            values.customFieldValue2,
            props.event.customFieldTitle3,
            values.customFieldValue3,
            props.event.customFieldTitle4,
            values.customFieldValue4
          )
            .then(result => {
              window.location.href = '/EventEntryComplete'
            })
        })
      } else {
        PostAttendance(values.postId,
          values.isOnline,
          values.name,
          values.email,
          values.tel,
          values.job,
          values.media,
          values.company,
          values.companionCount,
          values.companionNames,
          values.movieCameraCount,
          values.photoCameraCount,
          props.event.customFieldTitle1,
          values.customFieldValue1,
          props.event.customFieldTitle2,
          values.customFieldValue2,
          props.event.customFieldTitle3,
          values.customFieldValue3,
          props.event.customFieldTitle4,
          values.customFieldValue4)
          .then(result => {
            window.location.href = genReturnPath(values.name, values.email, values.tel, values.company, values.job, values.media)
          })
      }
    } catch (err) {
      console.log(err)
      values.apiError = true
    } finally {
      setSubmitting(false)
    }
  }
})(Form)

export const Body = withStyles(useStyles)(NewForm)
