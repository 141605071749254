import React from 'react'
import { Box, makeStyles, Paper, Typography } from '@material-ui/core'
import { PostRanking } from './PostRanking'
import { PressReleaseRanking } from './PressReleaseRanking'
import { useQuery } from 'react-query'
import WpApi from '../../../api/WpApi'

const useStyles = makeStyles(() => ({
  root: {
    padding: '8px',
    backgroundColor: '#DEF0FF'
  }
}))

export const Ranking = React.memo(() => {
  const classes = useStyles()
  const { data: dataRootCategories } = useQuery(['rootCategories'], () =>
    WpApi.getRootCategories()
  )
  const rootCategories = dataRootCategories?.body || []
  return (
    <Paper elevation={2} className={classes.root}>
      <Box
        display='flex'
        flexDirection='column'
      >
        <Box display='flex' alignItems='baseline'>
          <Box>
            <Typography variant='h2'>
              週間アクセスランキング
            </Typography>
          </Box>
        </Box>
        <PostRanking rootCategories={rootCategories} />
        <PressReleaseRanking rootCategories={rootCategories} />
      </Box>
    </Paper>

  )
})
