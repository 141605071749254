import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { ResourceContext } from 'App'
import { signOut } from 'auth/AwsAuth'
import { User } from 'domain/entity/User'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px'
  },
  link: {
    'text-decoration': 'none'
  },
  contentHeader: {
    marginTop: '70px',
    marginBottom: '15px'
  },
  contentBody: {
    marginBottom: '50px'
  }
}))

export const Body = () => {
  const classes = useStyles()
  const context = React.useContext(ResourceContext)

  useEffect(() => {
    signOut().then(() => {
      context.setUser(new User())
    })
  }, [])

  return (
    <Container className={classes.root}>
      <Typography
        variant='h1' align='center'
        className={classes.contentHeader}
      >ログアウトしました
      </Typography>
      <Typography variant='body1' align='center' className={classes.contentBody}>
        <Link className={classes.link} to='/'>
          トップに戻る
        </Link>
      </Typography>
    </Container>
  )
}
