import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  sideColumn: {
    '&>*': {
      marginTop: '12px',
      marginBottom: '12px'
    },
    '&>*:first-child': {
      marginTop: '0px'
    },
    '&>*:last-child': {
      marginBottom: '0px'
    }
  }
}))

export const SideColumnWrapper = (props) => {
  const classes = useStyles(props)
  const { children } = props
  return (
    <Box
      className={`${classes.sideColumn}`}
      display='flex'
      flexDirection='column'
      flexShrink={0}
      width='360px'
      maxWidth='360px'
      ml='20px'
    >
      {children}
    </Box>
  )
}
