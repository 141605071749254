import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { NmForm } from 'shared/NmForm'
import { NmFormText } from 'shared/NmForm/NmFormText'
import { NmFormSubmitGroup } from 'shared/NmForm/NmFormSubmitGroup'
import { NmFormButton } from 'shared/NmForm/NmFormButton'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 auto 0',
    padding: '10px',
    maxWidth: '480px',
    justifyContent: 'center'
  },
  link: {
    textDecoration: 'none'
  }
}))

export const Body = (props) => {
  const classes = useStyles()
  const onSubmit = (event) => {
    event.preventDefault()
  }
  return (
    <Grid className={classes.root}>
      <Grid>
        <NmForm onSubmit={onSubmit} title='パスワード変更完了'>
          <NmFormText>
            パスワードの変更が完了しました。
          </NmFormText>
          <NmFormSubmitGroup>
            <Link to='Login' className={classes.link}>
              <NmFormButton title='ログイン画面へ' />
            </Link>
          </NmFormSubmitGroup>
        </NmForm>
      </Grid>
    </Grid>
  )
}
