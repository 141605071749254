import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { PostRankingCard } from 'shared/template/SideColumnComponents/PostRankingCard'
import { PostRanking as ranking } from 'api/NetamatchApi'
import { useQuery } from 'react-query'
import wpApi from 'api/WpApi'

const useStyles = makeStyles(() => ({
  cardWrapper: {
    backgroundColor:'#FFFFFF',
    borderRadius:'10px',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 7px 12px 9px',
    margin: '8px 0',
    '&>*': {
      marginTop: '4px',
      marginBottom: '4px'
    },
    '&>*:first-child': {
      marginTop: '0px'
    },
    '&>*:last-child': {
      marginBottom: '0px'
    }
  },
  title: {
    fontWeight: '700'
  }
}))

export const PostRanking = (props) => {
  const { rootCategories } = props
  const { data: d } = useQuery(['PostRanking'], () => ranking())
  const ranks = d?.data?.ranking || []
  const { data: ps } = useQuery(['postByIds',...ranks], () => wpApi.getPostsByIds(ranks))
  const posts = ps?.body || []
  const classes = useStyles()
  return (
    <Box className={classes.cardWrapper}>
      <Box display='flex' alignItems='baseline'>
        <Box><Typography variant='h4' className={classes.title}>特集記事</Typography></Box>
      </Box>
      {ranks.map((rank, index) => {
        const post = posts.find( p => String(p.id) === rank)
        if(!post) return
        return (
          <PostRankingCard key={index} rank={index + 1} post={post} rootCategories={rootCategories}/>
        )
      })}
    </Box>
  )
}
