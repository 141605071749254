import React from 'react'
import { Box, makeStyles, CardMedia, Link } from '@material-ui/core'
import { Text } from 'shared/atoms/Text'
import { CategoryChip } from 'shared/molecules/CategoryChip'

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'black',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  wrapper: {
    boxSizing: 'border-box',
    height: '90px',
    padding: '7px 0'
  },
  rankingPostCard: {
    height: '100%',
    borderRadius: '3px',
    overflow: 'hidden',
    position: 'relative',
    '&::before': {
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '34px 36px 0 0',
      borderColor: '#F5D668 transparent transparent transparent',
      content: '""',
      position: 'absolute'
    },
    '&:hover': {
      transition: 'all 1s 0s ease',
      backgroundColor: theme.palette.primary.light
    }
  },
  'rank-1': {
    '&::before': {
      borderColor: '#F5D668 transparent transparent transparent'
    }
  },
  'rank-2': {
    '&::before': {
      borderColor: '#DBECF0 transparent transparent transparent'
    }
  },
  'rank-3': {
    '&::before': {
      borderColor: '#BBA03F transparent transparent transparent'
    }
  },
  cardMedia: {
    width: '100%',
    borderRadius: '3px',
    aspectRatio: '16/9'
  },
  rank: {
    position: 'absolute',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    left: '5px',
    top: '5px'
  },
  imageWrapper: {
    width: '112px',
    minWidth: '112px',
    height: '100%',
    marginRight: '8px'
  },
  title: {
    fontWeight: 700
  },
  createdBy: {
    fontSize: '13px',
    color: theme.palette.text.secondary,
    marginTop: '2px',
    marginBottom: '8px'
  },
  chipWrapper: {
    height: '26px',
    marginBottom: '2px'
  }
}))

export const PostRankingCard = (props) => {
  const classes = useStyles(props)
  const { rank, post, rootCategories } = props

  function GetMetaData (props) {
    if (post.type === 'post') return (<CategoryChip post={props.post} rootCategories={props.rootCategories} />)
    if (post.type === 'press_release') {
      return (
        <Box flexShrink={1} flexGrow={1} className={classes.createdBy}>
          {post.getCreatedBy()}
        </Box>
      )
    }
    return <></>
  }

  return (
    <Link className={classes.link} href={post.getDetailPath()}>
      <Box className={classes.wrapper}>
        <Box
          display='flex'
          className={`${classes.rankingPostCard} ${classes[`rank-${rank || 1}`]}`}
        >
          <Box className={classes.imageWrapper}>
            <Box className={classes.rank}>{rank}</Box>
            <CardMedia
              className={classes.cardMedia}
              image={post.imageUrl}
              title='Media'
            />
          </Box>
          <Box display='flex' flexDirection='column' textDecoration='none' flexShrink={1}>
            <Box flexShrink={1} flexGrow={1} overflow='hidden'><Text className={classes.title} variant='h6'>{post.title}</Text></Box>
            <Box className={classes.chipWrapper} flexShrink={1} flexGrow={1} overflow='hidden'>
              <GetMetaData post={post} rootCategories={rootCategories} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Link>
  )
}
