import React, { useState } from 'react'
import { googleAnalyticsIdUA, googleAnalyticsIdGA4 } from 'utility/Gtag'
import './App.css'
import { BrowserRouter as Router } from 'react-router-dom'
import theme from './Theme'
import { User } from './domain/entity/User'
import { ThemeProvider } from '@material-ui/core/styles'
import { ReactQueryProvider } from './provider/ReactQueryProvider'
import { AuthState } from './auth/AuthState'
import { ScrollToTop } from './shared/atoms/scrollToTop'
import { Routing } from './Routing'
import { GtagScript } from './shared/atoms/gtag'
import Cookies from 'js-cookie'

export const ResourceContext = React.createContext({
  user: {},
  setUser: () => {},
  isInvited: false
})

export const App = () => {
  const [user, setUser] = useState(new User())
  const [token, setToken] = useState('')
  let [isInvited] = useState(false)

  // hubspotからuidが指定されているかをcontextで引き回す
  const userId = Cookies.get('uid') === undefined ? '' : Cookies.get('uid')
  if (userId.length > 0) isInvited = true

  return (
    <ResourceContext.Provider
      value={{
        user: user,
        setUser: setUser,
        token: token,
        setToken: setToken,
        isInvited: isInvited
      }}
    >
      <ReactQueryProvider>
        <AuthState>
          <ThemeProvider theme={theme}>
            <div>
              <GtagScript ga4Id={googleAnalyticsIdGA4} uaId={googleAnalyticsIdUA} userId={userId} />
              <Router>
                {/*
                 * react-router-domではページ遷移後もスクロール位置が維持されてしまうため
                 * pathnameが変わるたびに一番上まで引き上げる
                 * https://reactrouter.com/web/guides/scroll-restoration
                 * https://qiita.com/dnrsm/items/e424701aee9f922850d3
                 */}
                <ScrollToTop />
                <Routing />
              </Router>
            </div>
          </ThemeProvider>
        </AuthState>
      </ReactQueryProvider>
    </ResourceContext.Provider>
  )
}
