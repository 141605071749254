import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  CardActionArea,
  CardMedia,
  Typography,
  Card,
  Grid,
  Link
} from '@material-ui/core'
import { Chip } from 'shared/atoms/Chip'
import * as DateTimeUtil from 'utility/DateTimeUtil'
import { limitedCategoryLength } from 'const/Const'

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'block',
    height: '100%',
    textDecoration: 'none'
  },
  xsWrapper: {
    position: 'relative',
    height: 110
  },
  xsMedia: {
    width: '31%',
    aspectRatio: '16/9', // 要求:縦横比9:16を固定とする
    maxWidth: '100px',
    maxHeight: '70px',
    margin: '11px 0px 9px 0px',
    float: 'right'
  },
  xsCard: {
    width: '100%',
    height: 110,
    margin: '0',
    boxShadow: 'none',
    borderBottom: '1px solid #D3D3D3'
  },
  xsPressReleaseCard: {
    width: '100%',
    height: 110,
    margin: '0',
    boxShadow: 'none',
    borderBottom: '1px solid #D3D3D3'
  },
  xsContentTitle: {
    width: '100%',
    height: 44,
    lineHeight: '1.4rem',
    overflow: 'hidden',
    margin: '0px'
  },
  xsTitleAndDate: {
    width: '69%',
    height: 92,
    display: 'inline-block',
    padding: '11px 4px 12px 6px',
    boxSizing: 'border-box'
  },
  xsTitleGrid: {
    width: '100%'
  },
  xsCreatedBy: {
    fontSize: '10px',
    margin: '4px 0px 0px 0px',
    color: theme.palette.text.secondary,
    width: '100%'
  },
  xsDate: {
    fontSize: '10px',
    margin: '8px 0px 6px 0px',
    color: theme.palette.text.secondary,
    width: '25%',
    float: 'left',
    minWidth: '55px'
  },
  xsCategoryChipGrid: {
    margin: '3px 0px 12px 0px',
    float: 'left'
  },
  xsCategoryChip: {
    borderRadius: 0,
    fontSize: '10px',
    fontWeight: 400,
    height: '20px'
  },
  xsPostChip: {
    '&:nth-child(even)': {
      marginLeft: '8px'
    },
    backgroundColor: '#FFF0F2'
  },
  xsPressReleaseChip: {
    '&:nth-child(even)': {
      marginLeft: '8px'
    },
    backgroundColor: '#DEF0FF'
  }
}))

export const XsPostCard = (props) => {
  const classes = useStyles()
  const post = props.post
  const rootCategories = props.rootCategories || []

  const genetateXsCategoryChip = (post) => {
    if (post.type === 'press_release') {
      return <Chip className={`${classes.xsCategoryChip} ${classes.xsPressReleaseChip}`} label='プレスリリース' />
    }
    if (post.type === 'post') {
      const rootCategoryIds = rootCategories.map(rootCategory => rootCategory.id)
      const categoryCollection = post.getCategoryCollection()
      const parentRootCategoryCollection = categoryCollection
        .extractRootCategoriesByIds(rootCategoryIds)
        .slice(0, limitedCategoryLength)
      const categoryNames = parentRootCategoryCollection.getCategoryNames(rootCategories)

      return <Chip className={`${classes.xsCategoryChip} ${classes.xsPostChip}`} label={categoryNames[0]} />
    }
    return <></>
  }

  return (
    <Card
      className={
        post.type === 'press_release'
          ? classes.xsPressReleaseCard
          : classes.xsCard
      }
    >
      <CardActionArea className={classes.xsWrapper}>
        <Link className={classes.link} href={post.getDetailPath()} underline='none'>

          <Grid xs container direction='column' spacing={0} className={classes.xsTitleAndDate}>
            <Grid item xs className={classes.xsTitleGrid}>
              <Typography gutterBottom variant='h6' component='h6' className={classes.xsContentTitle}>
                {post.title}
              </Typography>
            </Grid>
            <Grid item xs className={classes.xsCreatedBy}>
              {post.createdBy}
            </Grid>
            <Grid item className={classes.xsDate}>
              {DateTimeUtil.fromIsoToFormat(post.date, 'yyyy.MM.dd')}
            </Grid>
            <Grid item xs className={classes.xsCategoryChipGrid}>
              {genetateXsCategoryChip(post)}
            </Grid>
          </Grid>
          <CardMedia
            component='img'
            className={classes.xsMedia}
            alt='記事'
            image={post.getImage()}
            title='記事'
          />
        </Link>
      </CardActionArea>
    </Card>
  )
}
