import WpApi from 'api/WpApi'
import QueryItem from 'utility/query_item/QueryItem'

export default class QueryItemFeaturePost extends QueryItem {
  constructor ({ featureId, perPage }) {
    super()
    this.featureId = featureId
    this.perPage = perPage
  }

  async fetch (perPage, offset) {
    return WpApi.getFeaturePosts(this.featureId, perPage, offset)
  }
}
