import React from 'react'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'
import { Body } from './Body'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = (props) => {
  const params = new URLSearchParams(props.location.search)

  setPageTitle(TitleUtil.contact())

  return (
    <TemplateSideColumn content={<Body post_id={params.get('post_id')} />} />
  )
}
