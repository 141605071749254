import React from 'react'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'
import { Body } from './Body'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = (props) => {
  let name = decodeURI(new URLSearchParams(props.location.search).get('name'))
  if (name === 'null') name = ''
  let email = decodeURI(new URLSearchParams(props.location.search).get('email'))
  if (email === 'null') email = ''
  let tel = decodeURI(new URLSearchParams(props.location.search).get('tel'))
  if (tel === 'null') tel = ''
  let company = decodeURI(
    new URLSearchParams(props.location.search).get('company')
  )
  if (company === 'null') company = ''
  let job = decodeURI(new URLSearchParams(props.location.search).get('job'))
  if (job === 'null') job = ''
  let media = decodeURI(new URLSearchParams(props.location.search).get('media'))
  if (media === 'null') media = ''

  setPageTitle(TitleUtil.signUp())
  const content = <>
    <Body
        email={email}
        tel={tel}
        name={name}
        job={job}
        company={company}
        media={media}
      />
  </>

  return <TemplateSideColumn content={content}/>
}
