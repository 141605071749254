import WpApi from 'api/WpApi'
import QueryItem from 'utility/query_item/QueryItem'

export default class QueryItemCategoryPost extends QueryItem {
  perPage = 12
  categoryId = 0
  constructor({categoryId, perPage}) {
    super()
    this.categoryId = categoryId
    this.perPage = perPage || this.perPage
  }
  async fetch(perPage, offset) {
    return WpApi.getCategoryPosts(this.categoryId, perPage, offset)
  }
}