import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

const baseFontStyle = {
  fontFamily: 'Noto Sans JP',
  fontStyle: 'normal',
  fontWeight: 400
}
const useStyles = makeStyles(theme => ({
  'variant-h2': {
    ...baseFontStyle,
    fontSize: '22px',
    lineHeight: '31px'
  },
  'variant-sub1': {
    ...baseFontStyle,
    fontSize: '18px',
    lineHeight: '27px'
  },
  'variant-body1': {
    ...baseFontStyle,
    fontSize: '16px',
    lineHeight: '26px'
  },
  'variant-body2': {
    ...baseFontStyle,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.25px'
  },
  'variant-property': {
    ...baseFontStyle,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#6F6F6F'
  }
}))

export const Text = (props) => {
  const classes = useStyles(props)
  const { children, variant, classNameFromProps } = props
  const variantName = variant || 'h2'

  return <Typography className={`${classes[`variant-${variantName}`]} ${classNameFromProps}`} {...props}>{children}</Typography>
}
