import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  modalDivider: {
    [theme.breakpoints.up('sm')]: {
      height: '180px',
      width: '1px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '280px',
      height: '1px',
      margin: '4px 0'
    },
    backgroundColor: theme.palette.text.subtext
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      width: 280,
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '-webkit-transform': 'translate(-50%, -50%)',
      '-ms-transform': 'translate(-50%, -50%)',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(10, 5, 10)
    },
    [theme.breakpoints.up('sm')]: {
      width: 580,
      display: 'flex',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '-webkit-transform': 'translate(-50%, -50%)',
      '-ms-transform': 'translate(-50%, -50%)',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(10, 2, 10)
    }
  },
  title: {
    textAlign: 'center'
  },
  pd: {
    [theme.breakpoints.up('sm')]: {
      padding: '0 40px'
    }
  },
  accentButton: {
    width: '100%',
    backgroundColor: theme.palette.accent.main,
    '&:hover': {
      backgroundColor: theme.palette.accent.dark
    }
  },
  signupButton: {
    width: '100%'
  }
}))

export const SignUpModal = () => {
  const classes = useStyles()
  return (
    <div className={classes.modal}>
      <div className={classes.paper}>
        <div className={classes.pd}>
          <h4 className={classes.title}>企画を検討中の方</h4>
          <Button className={classes.signupButton} variant='contained' color='primary' href='/Signup'>メディア会員登録</Button>
          <p><i class='far fa-check-circle'>企画のアイデアがほしい</i></p>
          <p><i class='far fa-check-circle'>取材先を探したい</i></p>
        </div>
        <div className={classes.modalDivider} />
        <div className={classes.pd}>
          <h4 className={classes.title}>リリースを投稿したい方</h4>
          <Button className={classes.accentButton} variant='contained' color='secondary' href='/CompanySignup'>企業会員登録</Button>
          <p><i class='far fa-check-circle'>リリースを配信したい</i></p>
          <p><i class='far fa-check-circle'>メディアから問合せがほしい</i></p>
        </div>
      </div>
    </div>
  )
}
