import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px',
    textAlign: 'left',
    color: theme.palette.primary.main
  },
  link: {
    'text-decoration': 'none'
  }
}))

export const NmFormTextLink = (props) => {
  const classes = useStyles()
  return (
    <Link
      className={classes.link}
      to={props.to}
    >
      <Typography variant='body2' className={classes.root}>
        {props.children}
      </Typography>
    </Link>
  )
}
