import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({
  container: {
    maxWidth: 750
  },
  img: {
    width: '100%'
  }
}))

export const Image = (props) => {
  const classes = useStyles()
  return (
    <img
      className={classes.img}
      alt='nothing'
      src={props.post.getImage()}
    />
  )
}
