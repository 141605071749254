import React from 'react'
import { TagPosts } from './TagPosts'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = (props) => {
  const content = <>
    <TagPosts tagId={props.match.params.id} />
  </>

  return <TemplateSideColumn content={content}/>
}
