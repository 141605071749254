import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * react-router-domではページ遷移後もスクロール位置が維持されてしまうため
 * pathnameが変わるたびに一番上まで引き上げる
 * https://reactrouter.com/web/guides/scroll-restoration
 * https://qiita.com/dnrsm/items/e424701aee9f922850d3
 * @returns {null}
 */
export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}