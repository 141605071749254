import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  CardActionArea,
  CardMedia,
  Typography,
  Card,
  Grid,
  Hidden,
  Link
} from '@material-ui/core'
import * as DateTimeUtil from 'utility/DateTimeUtil'
import { XsPostCard } from 'shared/molecules/XsPostCard'
import { CategoryChip } from 'shared/molecules/CategoryChip'

const useStyles = makeStyles((theme) => ({
  postCard: {
    height: 280,
    width: 240,
    margin: '0 auto'
  },
  categoryChip: {
    borderRadius: 0,
    fontSize: '10px',
    fontWeight: 400,
    height: '26px'
  },
  postChip: {
    '&:nth-child(even)': {
      marginLeft: '8px'
    },
    backgroundColor: '#FFF0F2'
  },
  pressReleaseCard: {
    height: 280,
    width: 240,
    margin: '0 auto'
  },
  pressReleaseChip: {
    fontSize: '10px',
    '&:nth-child(even)': {
      marginLeft: '8px'
    },
    backgroundColor: '#DEF0FF'
  },
  wrapper: {
    position: 'relative',
    height: 280
  },
  media: {
    height: '135px', // 要求:縦横比9:16
    paddingTop: '0'
  },
  link: {
    display: 'block',
    height: '100%',
    textDecoration: 'none'
  },
  contentArea: {
    height: '150px',
    padding: '16px',
    paddingTop: '8px',
    paddingBottom: '14px'
  },
  contentTitle: {
    lineHeight: '26px',
    height: '52px',
    overflow: 'hidden',
    margin: '0px',
    fontWeight: 700,
    maxWidth: '100%',
    overflowWrap: 'break-word'
  },
  date: {
    fontSize: '12px',
    color: theme.palette.text.secondary,
    marginTop: '4px',
    marginBottom: '2px'
  },
  createdBy: {
    fontSize: '13px',
    color: theme.palette.text.secondary,
    marginTop: '2px',
    marginBottom: '8px'
  },
  categoryChipParent: {
    overflow: 'hidden'
  }

}))

export const PostCard = (props) => {
  const classes = useStyles()
  const post = props.post
  const rootCategories = props.rootCategories || []

  return (
    <>
      <Hidden xsDown>
        <Card
          className={
            post.type === 'press_release'
              ? classes.pressReleaseCard
              : classes.postCard
          }
        >
          <CardActionArea className={classes.wrapper}>
            <Link className={classes.link} href={post.getDetailPath()} underline='none'>
              <CardMedia
                className={classes.media}
                alt='記事'
                image={post.getImage()}
                title='記事'
              />
              <Grid item xs container direction='column' spacing={0} className={classes.contentArea}>
                <Grid item xs>
                  <Typography gutterBottom variant='h6' component='h6' className={classes.contentTitle}>
                    {post.title}
                  </Typography>
                </Grid>
                <Grid item className={classes.date}>
                  {DateTimeUtil.fromIsoToFormat(post.date, 'yyyy.MM.dd')}
                </Grid>
                <Grid item className={classes.createdBy}>
                  {post.getCreatedBy()}
                </Grid>
                <Grid item xs container direction='row' alignItems='center' justifyContent='flex-end' spacing={0} className={classes.categoryChipParent}>
                  <CategoryChip post={post} rootCategories={rootCategories} />
                </Grid>
              </Grid>
            </Link>
          </CardActionArea>
        </Card>
      </Hidden>
      <Hidden smUp>
        <XsPostCard post={post} rootCategories={rootCategories} />
      </Hidden>
    </>
  )
}
