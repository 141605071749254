import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import WpApi from '../api/WpApi'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    maxWidth: '100%',
    fontSize: theme.typography.body2.fontSize,
    margin: '0 auto',
    paddingTop: theme.spacing(2)
  },
  title: {
    marginLeft: theme.spacing(2)
  },
  list: {
    justifyContent: 'center',
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  tag: {
    display: 'inline-block'
  },
  tagInner: {
    display: 'inline-block',
    borderRadius: theme.spacing(1),
    backgroundColor: 'white',
    border: '1px solid #6E6E6E',
    color: theme.palette.text.primary,
    margin: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    'text-decoration': 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    }
  }
}))

export const TagCloud = () => {
  const classes = useStyles()
  const [tags, setTags] = useState([])

  useEffect(() => {
    WpApi.getTags().then((response) => {
      setTags(response.body)
    })
  }, [])

  return (
    <Container className={classes.root}>
      <Grid container justifyContent='center'>
        <Grid item xs={12}>
          <Typography className={classes.title} variant='h6'>
            注目のキーワード ▼
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <ul className={classes.list}>
            {tags.map(tag => (
              <li className={classes.tag} key={tag.id}>
                <Link
                  className={classes.tagInner}
                  to={'/Tag/' + tag.id}
                ># {tag.name}
                </Link>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </Container>

  )
}
