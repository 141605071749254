import BemapApi from 'api/BemapApi'
import QueryItem from 'utility/query_item/QueryItem'

export default class QueryItemPressRelease extends QueryItem {
  perPage = 3
  constructor({ perPage }) {
    super()
    this.perPage = perPage
  }
  async fetch(perPage, offset) {
    return BemapApi.getPressReleases(perPage, offset)
  }
}
