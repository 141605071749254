export class Categories {
  constructor (categories) {
    if (!Array.isArray(categories)) throw new Error('categories is not array')
    this.categories = categories || []
    this.rootCategories = this.categories.filter(category => category.parent === 0)
  }

  getRootCategories () {
    return this.rootCategories
  }

  getCategoriesGroupedByRootCategoryId () {
    const result = new Map()
    this.rootCategories.map(rootCategory => {
      const relatedCategories = this.categories.filter(category => category.parent === rootCategory.id)
      result.set(rootCategory.id, relatedCategories)
    })
    return result
  }
}
