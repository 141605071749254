import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '40px 0',
    backgroundColor: theme.palette.cassette.main
  },
  title: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    color: '#1471D7'
  },
  boxRecommendedPoints: {
    width: '100%',
    paddingBottom: theme.spacing(2)
  },
  boxRecommendedPoint: {
    paddingRight: '32px',
    marginBottom: '20px'
  }
}))

export const Point = (props) => {
  const classes = useStyles()
  if (props.event.isPointsExist()) {
    return (
      <>
        <div className={classes.container}>
          <Typography variant='h2' className={classes.title}>
            いまオススメする{props.fetchPointsCount}つの理由
          </Typography>
          <div className={classes.boxRecommendedPoints}>
            <Typography variant='subtitle1'>
              <ol>
                {props.event.points.map((value, key) => {
                  return <RecommendedPointRecord key={key} value={value.point} />
                })}
              </ol>
            </Typography>
          </div>
        </div>

      </>
    )
  }
  return (<div />)
}

const RecommendedPointRecord = (props) => {
  const classes = useStyles()
  return (
    <li className={classes.boxRecommendedPoint}>{props.value}</li>
  )
}
