import { createTheme } from '@material-ui/core/styles'

const rawTheme = createTheme({
  palette: {
    primary: {
      light: '#E7F1FB',
      main: '#1471D7',
      dark: '#0F42A6'
    },
    secondary: {
      light: '#a2aabf',
      main: '#606c8c',
      dark: '#313747'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f'
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00'
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2'
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c'
    },
    accent: {
      light: '#FECDD3',
      main: '#FF5A5A',
      dark: '#D22C34'
    },
    text: {
      primary: '#000000',
      accent: '#111111',
      subtext: '#858585'
    },
    cassette: {
      main: '#E9E9E9'
    },
    line: {
      primary: '#D6D6D6'
    }
  },
  typography: {
    fontFamily: "'hiragino sans','Noto Sans CJK JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', 'Hiragino Kaku Gothic ProN', 'メイリオ', meiryo, sans-serif",
    fontSize: 14,
    color: '#000000',
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontFamilySecondary: "'hiragino sans', 'Noto Sans CJK JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', 'Hiragino Kaku Gothic ProN', 'メイリオ', meiryo, sans-serif"
  }
})

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamily,
  lineHeight: '140%',
  textTransform: 'none'
}

const fontSubtitle = {
  color: rawTheme.palette.text.primary,
  lineHeight: '150%',
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamily
}

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: '#606c8c'
    }
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 32
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 22,
      fontWeight: rawTheme.typography.fontWeightBold
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 20
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 18
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 18,
      fontWeight: rawTheme.typography.fontWeightLight
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 16,
      fontWeight: rawTheme.typography.fontWeightBold
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      ...fontSubtitle,
      fontSize: 18
    },
    subtitle2: {
      ...rawTheme.typography.subtitle2,
      ...fontSubtitle,
      fontSize: 16
    },
    body1: {
      ...rawTheme.typography.body1,
      fontWeight: rawTheme.typography.fontWeightRegular,
      color: rawTheme.palette.text.primary,
      lineHeight: '160%',
      fontSize: 16
    },
    body2: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      color: rawTheme.palette.text.primary,
      fontSize: 14,
      lineHeight: '150%',
      spacing: '0.25px'
    },
    caption: {
      ...rawTheme.typography.caption,
      fontSize: 12,
      lineHeight: '140%'
    }
  }
}

export default theme
