import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles(theme => ({
  button2: {
    color: '#FFFFFF',
    backgroundColor: '#0352A8',
    textAlign: 'left',
    paddingRight: '20px',
    paddingLeft: '20px',
    height: '100%',
    paddingTop: '21px',
    paddingBottom: '21px',
    display: 'flex',
    fontSize: '14px',
    boxSizing: 'border-box',
    transition: 'all 0s',
    borderRadius: '0 0 0 0',
    '&:hover': {
      color: '#0352A8',
      backgroundColor: '#FFFFFF',
      borderColor: '#0352A8',
      border: '0.1px solid'
    }
  },
  buttonHover2: {
    textAlign: 'left',
    paddingRight: '20px',
    paddingLeft: '20px',
    height: '100%',
    paddingTop: '21px',
    paddingBottom: '21px',
    display: 'flex',
    fontSize: '14px',
    boxSizing: 'border-box',
    transition: 'all 0s',
    borderRadius: '0 0 0 0',
    color: '#0352A8',
    backgroundColor: '#FFFFFF',
    borderColor: '#0352A8',
    border: '0.1px solid'
  },
  button: {
    color: '#FFFFFF',
    backgroundColor: '#0352A8',
    textAlign: 'left',
    paddingRight: '20px',
    paddingLeft: '20px',
    height: '100%',
    paddingTop: '21px',
    paddingBottom: '21px',
    display: 'flex',
    fontSize: '17px',
    boxSizing: 'border-box',
    borderRadius: '0 0 0 0',
    opacity: 1,
    transition: 'all 0s',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px'
    }
  },
  buttonHover: {
    textAlign: 'left',
    paddingRight: '20px',
    paddingLeft: '20px',
    height: '100%',
    paddingTop: '21px',
    paddingBottom: '21px',
    display: 'flex',
    fontSize: '17px',
    boxSizing: 'border-box',
    borderRadius: '0 0 0 0',
    color: '#0352A8',
    backgroundColor: '#FFFFFF',
    borderColor: '#0352A8',
    border: '0.1px solid',
    transition: 'all 0s',
    opacity: 1,
    '&:hover': {
      color: '#0352A8',
      backgroundColor: '#FFFFFF'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px'
    }
  },
  menu: {
    'text-decoration': 'none',
    color: 'inherit'
  },
  menuItem: {
    justifyContent: 'left',
    '&:hover': {
      color: '#0352A8',
      backgroundColor: '#E9E9E9'
    }
  }
}))

const MyMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)

    // ↓buttonをhoverするとMenuItemの１つ目の要素がhoverになり、色がつくのを回避するための無理矢理実装
    event.currentTarget.focus()
    event.currentTarget.blur()
    event.currentTarget.focus()
    event.currentTarget.blur()
  }

  const handleClose = (e) => {
    if (e.currentTarget.localName !== 'ul') {
      const menu = document.getElementById('simple-menu').children[2]
      const button = e.currentTarget

      const buttonBoundary = {
        left: button.getBoundingClientRect().left + 10,
        top: button.getBoundingClientRect().top,
        right: button.getBoundingClientRect().right - 10,
        bottom: button.getBoundingClientRect().bottom
      }

      const menuBoundary = {
        left: menu.getBoundingClientRect().left,
        top: menu.getBoundingClientRect().top,
        right: menu.getBoundingClientRect().right,
        bottom: menu.getBoundingClientRect().bottom
      }

      if (
        (e.clientX >= menuBoundary.left &&
          e.clientX <= menuBoundary.right &&
          e.clientY <= menuBoundary.bottom &&
          e.clientY >= menuBoundary.top
        ) ||
        (e.clientX >= buttonBoundary.left &&
          e.clientX <= buttonBoundary.right &&
          e.clientY <= buttonBoundary.bottom &&
          e.clientY >= buttonBoundary.top
        )
      ) {
        return
      }
    }
    setOpen(false)
  }

  return (
    <div>
      <Button
        className={open ? classes.buttonHover : classes.button}
        id='menubutton1'
        aria-owns={open ? 'simple-menu' : null}
        aria-haspopup='true'
        onMouseOver={handleOpen}
        onMouseLeave={handleClose}
        style={{ zIndex: 1301 }}
        href={'/Category/' + props.rootCategory.id}
      >
        {props.name}
      </Button>
      <Menu
        className={classes.menu}
        id='simple-menu'
        anchorEl={anchorEl}
        open={open}
        transitionDuration={0}
        anchorOrigin={{
          horizontal: 'left'
        }}
        PaperProps={{
          style: {
            transform: 'translateY(56px)'
          }
        }}
        MenuListProps={{ onMouseLeave: handleClose }}
      >
        {props.categories.map(category => (
          <MenuItem
            key={category.id}
            className={classes.menuItem}
            component='a'
            href={'/Category/' + category.id}
          >
            {category.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default MyMenu
