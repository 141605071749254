import React from 'react'
import { isoDateToYMD } from 'utility/DateTimeUtil'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  box: {
    textAlign: 'right'
  }
}))

export const Date = (props) => {
  const classes = useStyles()
  return (
    <Box className={classes.box}>
      <Typography variant='body1'>
        {isoDateToYMD(props.event.createdAt)}
      </Typography>
    </Box>
  )
}
