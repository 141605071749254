export const UrlUtil = {
  WpApi: () => process.env.REACT_APP_WP_API_URL,
  BemapApiBase: () => process.env.REACT_APP_BEMAP_API_URL,
  PressReleaseInquiry: (id) => `/PressRelease/${id}/Inquiry`,
  PressReleaseInquiryComplete: (params) => {
    return UrlUtil.attachSearchParams('/PressReleaseInquiryComplete', params)
  },
  SignUp: (params) => {
    return UrlUtil.attachSearchParams('Signup', params)
  },
  attachSearchParams: (url, params) => {
    if (!params) return url
    const paramsObj = new URLSearchParams(params)
    return `${url}?${paramsObj.toString()}`
  }
}
