import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  img: {
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
    maxWidth: '100%',
    maxHeight: '500px'
  }
}))

export const Image = (props) => {
  const classes = useStyles()
  const { post } = props

  return (
    <>
      <img
        className={classes.img}
        alt='nothing'
        src={post.getImage()}
      />
    </>
  )
}
