import React from 'react'
import { Box, makeStyles, Link } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  tag: {
    backgroundColor: 'white',
    border: '1px solid #6E6E6E',
    borderRadius: theme.spacing(1),
    color: theme.palette.text.primary,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textDecoration: 'none',
    fontSize: '14px',
    letterSpacing: '0.25px',
    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    }
  }
}))

export const Tag = (props) => {
  const classes = useStyles()
  const { children } = props

  return <Link className='tag' href={'/Tag/' + props.id} underline='none'>
    <Box className={`${classes.tag}`}> #{children} </Box>
  </Link>
}
