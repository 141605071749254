import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  button: {
    height: '44px',
    width: '260px',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  }
}))

export const NmFormButton = (props) => {
  const classes = useStyles()
  return (
    <Button className={classes.button} disabled={props.disabled} variant='contained' color='primary' type='submit'>{props.title}</Button>
  )
}
