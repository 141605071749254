export const AuthErrorCode = {
  CodeMismatchError: 'CodeMismatchError',
  InternalError: 'InternalError',
  ExpiredCodeError: 'ExpiredCodeError',
  NotAuthorizedError: 'NotAuthorizedError',
  NotConfirmedError: 'NotConfirmedError',
  UserNotConfirmedError: 'UserNotConfirmedError',
  UsernameExistsError: 'UsernameExistsError',
  InvalidParameterError: 'InvalidParameterError',
  UserNotFoundError: 'UserNotFoundError',
  InvalidPasswordError: 'InvalidPasswordError'
}
