import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { ResourceContext } from 'App'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px'
  },
  link: {
    'text-decoration': 'none'
  },
  contentHeader: {
    marginTop: '70px',
    marginBottom: '15px'
  },
  contentBody: {
    marginBottom: '50px'
  }
}))

export const Body = (props) => {
  const classes = useStyles()

  const context = React.useContext(ResourceContext)
  const genSignUpPath = (name, email, tel, company, job, media) => {
    const params = {
      name: name,
      email: email,
      tel: tel,
      company: company,
      job: job,
      media: media
    }
    const queryString = Object.entries(params).map((e) => `${e[0]}=${encodeURIComponent(e[1])}`).join('&')
    return 'Signup?' + queryString
  }

  const completeView = () => {
    if (context.user.isLogin()) {
      return (
        <Container className={classes.root}>
          <Typography
            variant='h1' align='center'
            className={classes.contentHeader}
          >お申し込みありがとうございます
          </Typography>
          <Typography variant='body1' align='center' className={classes.contentBody}>
            受付が完了いたしました。<br />
          </Typography>
          <Typography variant='body1' align='center' className={classes.contentBody}>
            <Link className={classes.link} to='/'>
              トップに戻る
            </Link>
          </Typography>
        </Container>
      )
    }
    return (
      <Container className={classes.root}>
        <Typography
          variant='h1' align='center'
          className={classes.contentHeader}
        >お申し込みありがとうございます
        </Typography>
        <Typography variant='body1' align='center' className={classes.contentBody}>
          受付が完了いたしました。<br />
          ネタマッチでは様々なネタやイベントをご紹介しております。<br />
          会員の方にのみご紹介しているものも多数ございます。<br />
          これを機会に是非会員登録ください。<br />
        </Typography>
        <Typography variant='body1' align='center' className={classes.contentBody}>
          <Link className={classes.link} to={genSignUpPath(props.name, props.email, props.tel, props.company, props.job, props.media)}>
            会員登録はこちら
          </Link>
        </Typography>
      </Container>
    )
  }

  return (
    <>
      {completeView()}
    </>
  )
}
