import React, { useEffect, useState } from 'react'
import WpApi from 'api/WpApi'
import { Posts } from 'shared/Posts'
import { Container } from '@material-ui/core'
import Pagination from 'material-ui-flat-pagination/lib/Pagination'
import { makeStyles } from '@material-ui/core/styles'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'
import { ButtonReadMore } from 'shared/molecules/button/ReadMore'
import { useInfiniteCategoryPosts } from 'hooks/useInfiniteCategoryPosts'

const useStyles = makeStyles((theme) => ({
  pagination: {
    textAlign: 'center'
  }
}))

export const CategoryPosts = (props) => {
  const classes = useStyles()

  const { categoryId } = props
  const [category, setCategory] = useState([])
  const {
    data: categoryPosts,
    fetchNextPage,
    hasNextPage
  } = useInfiniteCategoryPosts(categoryId)
  useEffect(() => {
    WpApi.getCategory(props.categoryId).then((response) => {
      setCategory(response)
    })
  }, [props])

  setPageTitle(TitleUtil.categoryPosts(category))

  return (
    <Container>
      <Posts posts={categoryPosts || []} sectionTitle={category.name} />
      {/* <Pagination
        className={classes.pagination}
        limit={defaultPostPerPage}
        offset={pageOffset}
        total={categoryPostsCount}
        onClick={(e, offset) => handleClickPagination(offset)}
      /> */}
      <ButtonReadMore hasNextPage={hasNextPage} onClick={fetchNextPage} />
    </Container>
  )
}
