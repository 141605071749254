import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { App as PostDetail } from './page/post_detail/App'
import { App as Home } from './page/home/App'
import { App as About } from './page/about/App'
import { App as Contact } from './page/contact/App'
import { App as CompanySignup } from './page/company_signup/App'
import { App as CompanySignupComplete } from './page/company_signup_complete/App'
import { App as SignupConfirm } from './page/signup_confirm/App'
import { App as SignupComplete } from './page/signup_complete/App'
import { App as Signup } from './page/signup/App'
import { App as ForgotPassword } from './page/forgot_password/App'
import { App as ResetPassword } from './page/reset_password/App'
import { App as ResetPasswordComplete } from './page/reset_password_complete/App'
import { App as ResendConfirmationCode } from './page/resend_confirmation_code/App'
import { App as ContactThanks } from './page/contact_thanks/App'
import { App as FeaturePosts } from './page/feature_posts/App'
import { App as CategoryPosts } from './page/category_posts/App'
import { App as TagPosts } from './page/tag_posts/App'
import { App as SearchPosts } from './page/search_posts/App'
import { App as Notfound } from './page/not_found/App'
import { App as Login } from './page/login/App'
import { App as Logout } from './page/logout/App'
import { App as AccountDetail } from './page/account_detail/App'
import { App as AccountUpdate } from './page/account_update/App'
import { App as AttendanceList } from './page/attendance_list/App'
import { App as PrivacyPolicy } from './page/privacy_policy/App'
import { App as EventDetail } from './page/event_detail/App'
import { App as Terms } from './page/terms/App'
import { App as EventEntryComplete } from './page/event_entry_complete/App'
import { App as EventEntry } from './page/event_entry/App'
import { App as PressReleaseDetail } from './page/press_release_detail/App'
import { App as PressReleaseInquiry } from './page/press_release_inquiry/App'
import { App as MediaDocDetail } from './page/media_doc_detail/App'
import { App as PressReleaseInquiryComplete } from './page/press_release_inquiry_complete'

export const Routing = () => {
  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route path='/Detail/:id' component={PostDetail} />
      <Route path='/Feature/:id' component={FeaturePosts} />
      <Route path='/Category/:id' component={CategoryPosts} />
      <Route path='/Tag/:id' component={TagPosts} />
      <Route path='/About' component={About} />
      <Route path='/Signup' component={Signup} />
      <Route path='/SignupConfirm' component={SignupConfirm} />
      <Route path='/SignupComplete' component={SignupComplete} />
      <Route path='/CompanySignup' component={CompanySignup} />
      <Route path='/CompanySignupComplete' component={CompanySignupComplete} />
      <Route path='/ForgotPassword' component={ForgotPassword} />
      <Route path='/ResetPassword' component={ResetPassword} />
      <Route path='/ResetPasswordComplete' component={ResetPasswordComplete} />
      <Route
        path='/ResendConfirmationCode'
        component={ResendConfirmationCode}
      />
      <Route path='/Contact' component={Contact} />
      <Route path='/ContactThanks' component={ContactThanks} />
      <Route path='/Search' component={SearchPosts} />
      <Route path='/Login' component={Login} />
      <Route path='/Logout' component={Logout} />
      <Route path='/AccountDetail' component={AccountDetail} />
      <Route path='/AccountUpdate' component={AccountUpdate} />
      <Route path='/AttendanceList' component={AttendanceList} />
      <Route path='/PrivacyPolicy' component={PrivacyPolicy} />
      <Route path='/EventDetail/:id' component={EventDetail} />
      <Route path='/Terms' component={Terms} />
      <Route path='/EventEntryComplete' component={EventEntryComplete} />
      <Route path='/EventEntry' component={EventEntry} />
      <Route
        path='/PressReleaseInquiryComplete'
        component={PressReleaseInquiryComplete}
      />
      <Route path='/PressReleaseDetail/:id' component={PressReleaseDetail} />
      <Route path='/PressRelease/:id/Inquiry' component={PressReleaseInquiry} />
      <Route path='/MediaDocDetail/:id' component={MediaDocDetail} />
      <Route component={Notfound} />
    </Switch>
  )
}
