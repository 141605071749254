export class Event {
  constructor (
    id,
    title,
    eyeCatchImageUrl,
    content,
    points,
    attendanceTypeChoices,
    location,
    scheduledDate,
    customFieldTitle1,
    customFieldTitle2,
    customFieldTitle3,
    customFieldTitle4,
    isCustomField1Required,
    isCustomField2Required,
    isCustomField3Required,
    isCustomField4Required,
    cameraOption,
    companionOption,
    createdAt,
    updatedAt
  ) {
    this.id = id
    this.title = title
    this.eyeCatchImageUrl = eyeCatchImageUrl
    this.content = content
    this.points = points
    this.attendanceTypeChoices = attendanceTypeChoices
    this.location = location
    this.scheduledDate = scheduledDate
    this.customFieldTitle1 = customFieldTitle1
    this.customFieldTitle2 = customFieldTitle2
    this.customFieldTitle3 = customFieldTitle3
    this.customFieldTitle4 = customFieldTitle4
    this.isCustomField1Required = isCustomField1Required
    this.isCustomField2Required = isCustomField2Required
    this.isCustomField3Required = isCustomField3Required
    this.isCustomField4Required = isCustomField4Required
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.cameraOption = cameraOption
    this.companionOption = companionOption

    // attendanceTypeに想定するvalue(Private)
    this.attendanceTypeList = { online: 'online', offline: 'offline' }
    // attendanceTypeStringとして返却しうるvalue
    this.attendanceTypeStringList = { online: 'online', offline: 'offline', both: 'both' }
  }

  // 判定用に利用するイベント参加方法の文字列を取得
  fetchAttendanceTypeString () {
    if (this.attendanceTypeChoices?.includes(this.attendanceTypeList.online) && this.attendanceTypeChoices?.includes(this.attendanceTypeList.offline)) {
      return this.attendanceTypeStringList.both
    }
    if (this.attendanceTypeChoices?.includes(this.attendanceTypeList.online)) {
      return this.attendanceTypeStringList.online
    }
    if (this.attendanceTypeChoices?.includes(this.attendanceTypeList.offline)) {
      return this.attendanceTypeStringList.offline
    }
  }

  // ポイントの数を取得
  fetchPointsCount () {
    return this.points.length
  }

  isPointsExist () {
    if (!this.points) return false
    if (this.points?.length === 0) return false
    if (this.points[0]?.point === '') return false
    return true
  }
}
