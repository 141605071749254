import React, { useEffect, useState } from 'react'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'
import { Body } from './Body'
import { ResourceContext } from 'App'
import WpApi from 'api/WpApi'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = (props) => {
  const context = React.useContext(ResourceContext)
  const [event, setEvent] = useState(null)
  const postId = decodeURI(
    new URLSearchParams(props.location.search).get('postId')
  )

  setPageTitle(TitleUtil.eventEntry())

  useEffect(() => {
    console.log(postId)
    WpApi.getEvent(postId).then((event) => {
      setEvent(event)
    })
  }, [postId])

  if (postId === 'null' || !event) return <></>

  const content = <>
    <Body postId={postId} user={context.user} event={event} />
  </>

  return (
    <TemplateSideColumn content={content} />
  )
}
