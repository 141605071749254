import React from 'react'
import {
  Grid, makeStyles,
  MenuItem,
  Typography
} from '@material-ui/core'
import { NmFormTextField } from 'shared/NmForm/NmFormTextField'
import { NmFormText } from 'shared/NmForm/NmFormText'
import { jobCategories } from 'const/Const'
import { Entry } from './Entry'
import { ResourceContext } from 'App'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '40px auto 40px'
  },
  formControl: {
    width: '328px',
    backgroundColor: theme.palette.cassette.main,
    textAlign: 'center',
    padding: '30px 76px',
    display: 'block',
    margin: '0px auto 0px'
  },
  title: {
    marginBottom: theme.spacing(2)
  }
}))

export const Form = (props) => {
  // 同行者数やカメラ台数の選択肢
  const allowCount = Array.from(Array(11), (v, k) => k)
  const context = React.useContext(ResourceContext)
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit
  } = props

  const classes = useStyles()

  const setApiError = (value) => {
    values.apiError = value
  }

  const needShowForm = () => {
    return !context.user.isLogin() ||
      (('event' in values) && ('companionOption' in values.event) && values.event.companionOption) ||
      (('event' in values) && ('cameraOption' in values.event) && values.event.cameraOption) ||
      (('event' in values) && ('customFieldTitle1' in values.event) && values.event.customFieldTitle1.length > 0) ||
      (('event' in values) && ('customFieldTitle2' in values.event) && values.event.customFieldTitle2.length > 0) ||
      (('event' in values) && ('customFieldTitle3' in values.event) && values.event.customFieldTitle3.length > 0) ||
      (('event' in values) && ('customFieldTitle4' in values.event) && values.event.customFieldTitle4.length > 0)
  }

  return (
    <Grid container className={classes.root}>
      <div className={classes.root}>
        <Typography variant='h2' className={classes.title}>
          イベント申し込み
        </Typography>
        <form onSubmit={handleSubmit} autoComplete='off'>
          {needShowForm() &&
            <div className={classes.formControl}>
              <Grid>
                <NmFormText>
                  イベント参加をご希望の方は以下の項目の入力をお願いします。
                </NmFormText>
                {!context.user.isLogin() &&
                  <>
                    <NmFormTextField
                      name='name'
                      label='お名前'
                      required
                      onChange={handleChange}
                      value={values.name}
                      onBlur={handleBlur}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name ? errors.name : ''}
                    />
                    <NmFormTextField
                      name='email'
                      label='メールアドレス'
                      required
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email ? errors.email : ''}
                    />
                    <NmFormTextField
                      name='tel'
                      label='電話番号(ハイフン無し)'
                      required
                      onChange={handleChange}
                      value={values.tel}
                      onBlur={handleBlur}
                      error={touched.tel && Boolean(errors.tel)}
                      helperText={touched.tel ? errors.tel : ''}
                    />
                    <NmFormTextField
                      name='company'
                      label='会社名'
                      required
                      onChange={handleChange}
                      value={values.company}
                      onBlur={handleBlur}
                      error={touched.company && Boolean(errors.company)}
                      helperText={touched.company ? errors.company : ''}
                    />
                    <NmFormTextField
                      select
                      name='job'
                      label='職種'
                      required
                      onChange={handleChange}
                      value={values.job}
                      onBlur={handleBlur}
                      error={touched.job && Boolean(errors.job)}
                      helperText={touched.job ? errors.job : ''}
                    >
                      {jobCategories.map(category => (
                        <MenuItem key={category} value={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </NmFormTextField>
                    <NmFormTextField
                      name='media'
                      label='媒体(番組)名'
                      required
                      onChange={handleChange}
                      value={values.media}
                      onBlur={handleBlur}
                      error={touched.media && Boolean(errors.media)}
                      helperText={touched.media ? errors.media : ''}
                    />
                  </>}

                {('event' in values) && ('companionOption' in values.event) && values.event.companionOption &&
                  <>
                    <NmFormTextField
                      select
                      name='companionCount'
                      label='同行者数'
                      required
                      onChange={handleChange}
                      value={values.companionCount}
                      onBlur={handleBlur}
                      error={touched.companionCount && Boolean(errors.companionCount)}
                      helperText={touched.companionCount ? errors.companionCount : ''}
                    >
                      {allowCount.map(count => (
                        <MenuItem key={count} value={count}>
                          {count}
                        </MenuItem>
                      ))}
                    </NmFormTextField>
                    <NmFormTextField
                      name='companionNames'
                      label='同行者名'
                      onChange={handleChange}
                      value={values.companionNames}
                      onBlur={handleBlur}
                      error={touched.companionNames && Boolean(errors.companionNames)}
                      helperText={touched.companionNames ? errors.companionNames : ''}
                    />
                  </>}

                {('event' in values) && ('cameraOption' in values.event) && values.event.cameraOption &&
                  <>
                    <NmFormTextField
                      select
                      name='photoCameraCount'
                      label='撮影スチール台数'
                      required
                      onChange={handleChange}
                      value={values.photoCameraCount}
                      onBlur={handleBlur}
                      error={touched.photoCameraCount && Boolean(errors.photoCameraCount)}
                      helperText={touched.photoCameraCount ? errors.photoCameraCount : ''}
                    >
                      {allowCount.map(count => (
                        <MenuItem key={count} value={count}>
                          {count}
                        </MenuItem>
                      ))}
                    </NmFormTextField>
                    <NmFormTextField
                      select
                      name='movieCameraCount'
                      label='撮影ムービー台数'
                      required
                      onChange={handleChange}
                      value={values.movieCameraCount}
                      onBlur={handleBlur}
                      error={touched.movieCameraCount && Boolean(errors.movieCameraCount)}
                      helperText={touched.movieCameraCount ? errors.movieCameraCount : ''}
                    >
                      {allowCount.map(count => (
                        <MenuItem key={count} value={count}>
                          {count}
                        </MenuItem>
                      ))}
                    </NmFormTextField>
                  </>}

                {('event' in values) && ('customFieldTitle1' in values.event) && values.event.customFieldTitle1.length > 0 &&
                  <NmFormTextField
                    name='customFieldValue1'
                    label={values.event.customFieldTitle1}
                    required={values.event.isCustomField1Required}
                    onChange={handleChange}
                    value={values.customFieldValue1}
                    onBlur={handleBlur}
                    error={touched.customFieldValue1 && Boolean(errors.customFieldValue1)}
                    helperText={touched.customFieldValue1 ? errors.customFieldValue1 : ''}
                  />}
                {('event' in values) && ('customFieldTitle2' in values.event) && values.event.customFieldTitle2.length > 0 &&
                  <NmFormTextField
                    name='customFieldValue2'
                    label={values.event.customFieldTitle2}
                    required={values.event.isCustomField2Required}
                    onChange={handleChange}
                    value={values.customFieldValue2}
                    onBlur={handleBlur}
                    error={touched.customFieldValue2 && Boolean(errors.customFieldValue2)}
                    helperText={touched.customFieldValue2 ? errors.customFieldValue2 : ''}
                  />}
                {('event' in values) && ('customFieldTitle3' in values.event) && values.event.customFieldTitle3.length > 0 &&
                  <NmFormTextField
                    name='customFieldValue3'
                    label={values.event.customFieldTitle3}
                    required={values.event.isCustomField3Required}
                    onChange={handleChange}
                    value={values.customFieldValue3}
                    onBlur={handleBlur}
                    error={touched.customFieldValue3 && Boolean(errors.customFieldValue3)}
                    helperText={touched.customFieldValue3 ? errors.customFieldValue3 : ''}
                  />}
                {('event' in values) && ('customFieldTitle4' in values.event) && values.event.customFieldTitle4.length > 0 &&
                  <NmFormTextField
                    name='customFieldValue4'
                    label={values.event.customFieldTitle4}
                    required={values.event.isCustomField4Required}
                    onChange={handleChange}
                    value={values.customFieldValue4}
                    onBlur={handleBlur}
                    error={touched.customFieldValue4 && Boolean(errors.customFieldValue4)}
                    helperText={touched.customFieldValue4 ? errors.customFieldValue4 : ''}
                  />}
              </Grid>
            </div>}
          {/*
          TODO formikでうまくできなかったからEntryコンポーネント作ってそこで処理させている
          formikでできるならEntryコンポーネント消して、差し替えてください
          */}
          <Entry
            postId={values.postId}
            apiError={values.apiError}
            setApiError={setApiError}
            setIsOnline={(val) => { values.isOnline = val }}
            isSubmitting={isSubmitting}
          />
        </form>
      </div>
    </Grid>
  )
}
