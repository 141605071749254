// eslint-disable-next-line no-unused-vars
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { InquireCompany } from 'api/NetamatchApi'
import { Form } from './Form'

const useStyles = theme => ({
  root: {
    margin: '0 auto 0',
    padding: '10px',
    maxWidth: '480px',
    justifyContent: 'center'
  },
  isConsent: {
    paddingBottom: theme.spacing(1)
  },
  consentHelperText: {
    minHeight: 0,
    margin: 0
  },
  link: {
    display: 'block',
    marginTop: '16px',
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  login: {
    fontSize: '14px'
  },
  policyLink: {
    'text-decoration': 'none'
  },
  inputTitle: {
    paddingTop: '25px',
    textAlign: 'left'
  }
})

const NewForm = withFormik({
  mapPropsToValues: (
    {
      name,
      departmentName,
      jobTitle,
      representativeName,
      email,
      tel,
      inquiry,
      isConsent,
      postPressWalker
    }) => {
    return {
      name: name || '',
      departmentName: departmentName || '',
      jobTitle: jobTitle || '',
      representativeName: representativeName || '',
      email: email || '',
      tel: tel || '',
      inquiry: inquiry || '',
      isConsent: isConsent || false,
      postPressWalker: postPressWalker || false
    }
  },
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required('必須項目です'),
    departmentName: Yup.string()
      .required('必須項目です'),
    jobTitle: Yup.string(),
    representativeName: Yup.string()
      .required('必須項目です'),
    email: Yup.string()
      .required('必須項目です')
      .email('メールアドレスの形式が正しくありません'),
    tel: Yup.string()
      .required('必須項目です')
      .matches(/^[0-9]{10,11}$/, '半角数字10-11文字で入力してください 例)0312345678'),
    inquiry: Yup.string()
      .required('必須項目です')
      .max(800, '800文字以内で入力してください'),
    isConsent: Yup.boolean()
      .oneOf([true], '必須項目です'),
    postPressWalker: Yup.boolean()
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError }) => {
    try {
      await InquireCompany(
        values.name,
        values.departmentName,
        values.jobTitle,
        values.representativeName,
        values.email,
        values.tel,
        values.inquiry,
        values.postPressWalker
      )
      window.location.href = '/CompanySignupComplete'
    } catch (err) {
      setFieldError('email', 'エラーが発生しました。大変お手数ですがお問い合わせください')
    } finally {
      setSubmitting(false)
    }
  }
})(Form)

export const Body = withStyles(useStyles)(NewForm)
