import React from 'react'
import { Link as MuiLink } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

export const Link = (props) => {
  const { children, to, underline } = props
  return (
    <MuiLink
      component={RouterLink}
      {...props}
      to={to}
      underline={underline || 'none'}
      color='textPrimary'
    >
      {children}
    </MuiLink>
  )
}
