import IApi from './IApi'
import { defaultPostPerPage, defaultPrPerPageForNews } from 'const/Const'
import { Event } from 'domain/entity/Event'
import { MediaDoc } from 'domain/entity/MediaDoc'
import { Category } from 'domain/entity/Category'
import { Post } from 'domain/entity/Post'
import Tag from 'domain/entity/Tag'
import NoImage from 'image/noimage_postcard.png'
import { UrlUtil } from 'utility/UrlUtil'

import axios from 'axios'

class WpApi extends IApi {
  static async getEvent (id, nonce = null, isPreview = false) {
    const resource = `events/${id}`
    const params = {
      _embed: ''
    }
    const headers = {}
    if (isPreview) {
      headers['X-WP-Nonce'] = nonce
    }
    console.log("WpApi :  resource ======================================================");
    console.log(resource);
    return await this.sendRequest(resource, 'GET', headers, params)
      .then(data => {
          console.log(data);
        return (new Event(
          data.body.id,
          data.body.title.rendered,
          this._createImageUrl(data.body),
          data.body.content.rendered,
          data.body.acf.point,
          data.body.acf.online_offline,
          data.body.acf.location,
          data.body.acf.scheduled_date,
          data.body.acf.custom_field_1,
          data.body.acf.custom_field_2,
          data.body.acf.custom_field_3,
          data.body.acf.custom_field_4,
          data.body.acf.is_custom_field_1_required,
          data.body.acf.is_custom_field_2_required,
          data.body.acf.is_custom_field_3_required,
          data.body.acf.is_custom_field_4_required,
          data.body.acf.camera_option,
          data.body.acf.companion_option,
          data.body.date,
          data.body.modified
        ))
      })
      .catch(err => { 
          console.log(err);

      throw err ;
      })
  }

  static async getMediaDoc (id, nonce = null, isPreview = false) {
    const resource = `media_docs/${id}`
    const params = {
      _embed: ''
    }
    const headers = {}
    if (isPreview) {
      headers['X-WP-Nonce'] = nonce
    }
    return await this.sendRequest(resource, 'GET', headers, params)
      .then(data => {
        return this._newMediaDocEntity(data.body)
      })
      .catch(err => { throw err })
  }

  static _newMediaDocEntity(post) {
    return new MediaDoc(
      post.id,
      post.categories,
      this._createTitle(post),
      this._createImageUrl(post),
      post.content?.rendered,
      post.date,
      post.modified,
      post.acf.doc_url
    )
  }

  static getPost (id, nonce = null, isPreview = false) {
    const resource = `posts/${id}`
    const params = {
      _embed: ''
    }
    const headers = {}
    if (isPreview) {
      headers['X-WP-Nonce'] = nonce
    }
    return this.sendRequest(resource, 'GET', headers, params)
      .then(data =>( this._newPostEntity(data.body)))
  }

  static getPostsByIds (ids) {
    if (!Array.isArray(ids) || ids.length === 0) return { count: 0, body: [] }
    const resource = `posts`
    const params = {
      _embed: '',
      include: ids.join()
    }
    const headers = {}
    
    return this.sendRequest(resource, 'GET', headers, params)
      .then(data => ({
        count: data.count,
        body: data.body.map(item => this._newPostEntity(item))
      }))
  }

  static getNewPosts (perPage = defaultPostPerPage, offset = 0) {
    const resource = 'posts'
    // const resource = 'multiple-post-type?&type[]=press_release&type[]=post'
    const params = {
      _embed: '',
      offset: offset,
      per_page: perPage
    }

    return this.sendRequest(resource, 'GET', {}, params)
      .then(data => {
        return {
          count: data.count,
          body: data.body.map(item => this._newPostEntity(item))
        }
      })
  }

  static getSearchPosts (searchWord, perPage = defaultPostPerPage, offset = 0) {
    const resource = 'posts'
    const params = {
      _embed: '',
      search: searchWord,
      offset: offset,
      per_page: perPage
    }
    return this.sendRequest(resource, 'GET', {}, params)
      .then(data => {
        return {
          count: data.count,
          body: data.body.map(item => this._newPostEntity(item))
        }
      })
  }

  static getCategoryPosts (categoryId, perPage = defaultPostPerPage, offset = 0) {
    const resource = 'posts'
    const params = {
      _embed: '',
      categories: categoryId,
      offset: offset,
      per_page: perPage
    }
    return this.sendRequest(resource, 'GET', {}, params)
      .then(data => {
        return {
          count: data.count,
          body: data.body.map(item => this._newPostEntity(item))
        }
      })
  }

  static getLatestCategoryPostsByCategoryIds (categoryIds) {
    const resource = 'posts'
    const perPage = 4
    const params = {
      _embed: '',
      categories: categoryIds.join(),
      per_page: perPage,
      orderby: 'date',
      order: 'desc'
    }
    return this.sendRequest(resource, 'GET', {}, params)
      .then(data => {
        return {
          count: data.count,
          body: data.body.map(item => this._newPostEntity(item))
        }
      })
  }

  static getTagPosts (tagId, perPage = defaultPostPerPage, offset = 0) {
    const resource = 'posts'
    // 元々tagを使っていたがkeywordに移行した
    // 用語自体はタグをそのまま使っている
    // したがってパラメータはtags=>keywordsに変更になった
    const params = {
      _embed: '',
      keywords: tagId,
      offset: offset,
      per_page: perPage
    }
    return this.sendRequest(resource, 'GET', {}, params)
      .then(data => {
        return {
          count: data.count,
          body: data.body.map(item => this._newPostEntity(item))
        }
      })
  }

  static getFeaturePosts (featureId, perPage = defaultPostPerPage, offset = 0) {
    const resource = 'posts'
    const params = {
      _embed: '',
      features: featureId,
      offset: offset,
      per_page: perPage
    }
    return this.sendRequest(resource, 'GET', {}, params)
      .then(data => {
        return {
          count: data.count,
          body: data.body.map(item => this._newPostEntity(item))
        }
      })
  }

  static _newPostEntity(post) {
    return new Post(
      post.id,
      post.categories,
      this._createTitle(post),
      this._createImageUrl(post),
      post.content.rendered,
      post.date,
      post.modified,
      this._createPoints(post),
      post.acf.specialist,
      post.acf.maker,
      post.acf.other,
      post.acf.doc_url,
      this._createRelatedPostIds(post)
    )
  }

  /**
   * 投稿のアイキャッチ画像のURLを取得
   * @param postObject
   * @returns {*}
   * @private
   */
  static _createImageUrl (postObject) {
    try {
      return postObject._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url
    } catch (e) {
      return NoImage
    }
  }

  /**
   * 投稿のタイトルを取得
   * @param postObject
   * @returns {null|*}
   * @private
   */
  static _createTitle (postObject) {
    try {
      // 1つ目のカテゴリーを採用
      return postObject.title.rendered
    } catch (e) {
      return '想定外のオブジェクト構造のためタイトルの取得に失敗しました'
    }
  }

  /**
   * 関連記事IDのリストを取得
   * @param postObject
   * @returns {*|*[]}
   * @private
   */
  static _createRelatedPostIds (postObject) {
    return postObject?.acf?.related_posts || []
  }

  /**
   *
   * @param postObject
   * @returns {*|*[]}
   * @private
   */
  static _createPoints (postObject) {
    try {
      const ret = []
      postObject.acf.point.forEach(point => (ret.push(point.point)))
      return ret
    } catch (e) {
      return []
    }
  }


  static getFeature (featureId) {
    const resource = 'features'
    const params = {
      _embed: '',
      include: featureId
    }
    return this.sendRequest(resource, 'GET', {}, params).then((response) => { return response.body[0] })
  }

  static getFeatures () {
    const resource = 'features'
    const params = {
      _embed: ''
    }
    return this.sendRequest(resource, 'GET', {}, params)
  }

  static getCategory (categoryId) {
    const resource = 'categories'
    const params = {
      _embed: '',
      exclude: 1,
      include: categoryId
    }
    return this.sendRequest(resource, 'GET', {}, params).then((response) => { return response.body[0] })
  }

  static getCategories (parentId) {
    const resource = 'categories'
    const params = {
      exclude: 1,
      per_page: 100
    }
    if (Number.isInteger(parentId)) params.parent = parentId
    
    return this.sendRequest(resource, 'GET', {}, params)
      .then(res => {
        return {
          count: res.count,
          body: res?.body?.map(item => new Category(item))
        }
      })
  }

  static getRootCategories () {
    const ROOT_CATEGORY_PARENT_ID = 0
    return this.getCategories(ROOT_CATEGORY_PARENT_ID)
  }

  static getTag (tagId) {
    const resource = 'keywords'
    const params = {
      _embed: '',
      include: tagId
    }
    return this.sendRequest(resource, 'GET', {}, params)
      .then((response) => {
        if (!response?.body || !response?.body[0]) return null
        return new Tag(response.body[0])
      })
  }

  static async getTags (is_recommend = 1) {
    const resource = 'keywords'
    const params = {
      is_recommend
    }
    return this.sendRequest(resource, 'GET', {}, params)
      .then(data => {
        return {
          count: data.count,
          body: data.body.map(item => new Tag(item))
        }
      })
  }

  static getUserByToken (token) {
    // スタブ実装
    if (token === 'ju389fan89a') {
      return {
        name: 'コマツケンタ',
        mail: 'komatsu@talknote.com',
        token: 'ju389fan89a',
        status: true
      }
    }

    if (token === 'uiejiije93') {
      return {
        name: 'しらさかうこひと',
        mail: 'hirasaka@cloudpayment.com',
        token: 'uiejiije93',
        status: true
      }
    }

    return {
      name: '',
      mail: '',
      token: '',
      status: false
    }
  }

  static async sendRequest (path, method, headers, params) {
    try {
      const res = await this._sendRequest(path, method, headers, params)
      return {
        body: res.data,
        count: res.headers['x-wp-total']
      }
    } catch (err) {
    console.log("_sendRequest axios :  error  ======================================================");
    console.log(err);
      console.error('api error: ' + err + '\npath: ' + path)
      throw err
    }
  }

  static _sendRequest (url, method, headers, params) {
    const configs = {
      baseURL: UrlUtil.WpApi(),
      url: url,
      method: method,
      headers: headers,
      params: params
    }
axios.defaults.headers.post['Content-Type'] = 'x-wp-nonce';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.withCredentials = false;
    console.log("_sendRequest axios :  resource ======================================================");
    console.log(configs);

    // use cookie option
    if (process.env.NODE_ENV === 'production') {
      configs.withCredentials = true
    }
    return axios(configs)
  }
}

export default WpApi
