import { PressRelease } from 'domain/entity/PressRelease'
import NoImage from 'image/noimage_postcard.png'
import { UrlUtil } from 'utility/UrlUtil'

import axios from 'axios'

class BemapApi {
  static async getPressRelease (id, token = null, isPreview = false) {
    const conditions = { id: id }
    if (isPreview) conditions.t = token
    const prs = await this.getPressReleases(1, 0, conditions)
    return prs.body[0]
  }

  static async getPressReleases (perPage, offset = 0, condition = {}) {
    const resource = 'pressrelease'
    const params = {
      offset: offset,
      per_page: perPage,
      ...condition
    }

    return this.sendRequest(resource, 'GET', {}, params, {})
      .then(data => {
        return { body: data.map(item => this._newPressReleaseEntity(item)) }
      })
  }

  static async inquirePressRelease (postId, name, email, tel, job, media, company, inquiry) {
    const resource = 'inquiry'
    const header = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
    const data = {
      postId: postId,
      name: name,
      email: email,
      tel: tel,
      jobTitle: job,
      mediaName: media,
      companyName: company,
      inquiry: inquiry
    }
    return this.sendRequest(resource, 'POST', header, {}, data)
  }

  static _newPressReleaseEntity (post) {
    return new PressRelease(
      post.id,
      post.categoryIds,
      post.title,
      this._createImageUrl(post),
      post.content,
      post.createdBy,
      post.publishedAt,
      post.modifiedAt
    )
  }

  /**
   * 投稿のアイキャッチ画像のURLを取得
   * @param postObject
   * @returns {*}
   * @private
   */
  static _createImageUrl (postObject) {
    return postObject.imageUrl === null ? NoImage : postObject.imageUrl
  }

  static async sendRequest (path, method, headers, params, data) {
    try {
      const res = await this._sendRequest(path, method, headers, params, data)
      return res.data
    } catch (err) {
      console.error('api error: ' + err + '\npath: ' + path)
      throw err
    }
  }

  static _sendRequest (url, method, headers, params, data = {}) {
    const configs = {
      baseURL: UrlUtil.BemapApiBase(),
      url: url,
      method: method,
      headers: headers,
      params: params,
      data: data
    }
    return axios(configs)
  }
}

export default BemapApi
