import WpApi from 'api/WpApi'
import QueryItem from 'utility/query_item/QueryItem'

export default class QueryItemTagPost extends QueryItem {
  perPage = 12
  tagId = 0
  constructor({tagId, perPage}) {
    super()
    this.tagId = tagId
    this.perPage = perPage || this.perPage
  }
  async fetch(perPage, offset) {
    return WpApi.getTagPosts(this.tagId, perPage, offset)
  }
}