import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import WpApi from '../../api/WpApi'
import MyMenu from './Menu'

const useStyles = makeStyles(theme => ({
  root: {
    height: theme.spacing(7),
    minHeight: theme.spacing(7),
    display: 'flex',
    maxWidth: '100%',
    color: '#FFFFFF',
    margin: '0',
    textAlign: 'center'
  }
}))

export const CategoryBar = () => {
  const classes = useStyles()
  const [allCategories, setAllCategories] = useState([])
  const [rootCategories, setRootCategories] = useState([])

  useEffect(() => {
    WpApi.getRootCategories().then((response) => {
      setRootCategories(response.body)
    })

    WpApi.getCategories().then((response) => {
      setRootCategories(response.body.filter(res => res.parent === 0))
      setAllCategories(response.body)
    })
  }, [])

  return (
    <div className={classes.root}>
      {rootCategories.map(category => (
        <MyMenu
          name={category.name}
          categories={allCategories.filter(allCategory => allCategory.parent === category.id)}
          rootCategory={category}
          key={category.id}
        />
      ))}
    </div>

  )
}
