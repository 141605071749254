import React, { useState, useEffect } from 'react'
import BemapApi from 'api/BemapApi'
import { Posts } from 'shared/Posts'
import { defaultPostPerPage } from 'const/Const'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Button, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20
  },
  buttonContainer: {
    textAlign: 'center'
  },
  expandButton: {
    padding: '8px',
    textAlign: 'center'
  },
  expandButtonText: {
    color: theme.palette.text.accent
  },
  expandMoreIcon: {
    fontSize: 22,
    verticalAlign: 'middle'
  }
}))

export const PressReleases = (props) => {
  const classes = useStyles()
  const [posts, setPosts] = useState([])
  const [pageOffset, setPageOffset] = useState(0)
  const [isEnd, setIsEnd] = useState(false)

  const fetchPosts = () => {
    BemapApi.getPressReleases(defaultPostPerPage, pageOffset).then((response) => {
      const ps = response.body
      setPosts(posts.concat(ps))
      setPageOffset(pageOffset + ps.length)
      if (ps.length === 0) setIsEnd(true)
    })
  }

  useEffect(() => {
    fetchPosts()
  }, [])

  return (
    <Container className={classes.root}>
      <Posts posts={posts} sectionTitle='プレスリリース' />
      <div className={classes.buttonContainer}>
        {!isEnd &&
          <Button className={classes.expandButton} onClick={fetchPosts}>
            <Typography className={classes.expandButtonText} variant='subtitle1'>
              もっと読む
            </Typography>
            <ExpandMore className={classes.expandMoreIcon} />
          </Button>}
        {isEnd &&
          <Typography className={classes.expandButtonText} variant='subtitle1'>
          すべて表示しました
          </Typography>}
      </div>
    </Container>
  )
}
