import React from 'react'
import { Posts } from 'shared/Posts'
import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useInfiniteFeaturePosts } from 'hooks/useInfiniteFeaturePosts'
import { ButtonReadMore } from 'shared/molecules/button/ReadMore'

const useStyles = makeStyles((theme) => ({
  pagination: {
    textAlign: 'center'
  },
  root: {
    padding: 0
  }
}))

export const FeaturePosts = (props) => {
  const classes = useStyles()
  const { feature } = props

  const {
    data: featurePosts,
    fetchNextPage,
    hasNextPage
  } = useInfiniteFeaturePosts(feature.id)

  return (
    <Container className={classes.root}>
      <Posts
        posts={featurePosts || []} sectionTitle={feature.name ? '特集：' + feature.name : feature.name}
        sectionDesc={feature.description}
      />
      <ButtonReadMore hasNextPage={hasNextPage} onClick={fetchNextPage} />
    </Container>
  )
}
