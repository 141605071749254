import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import { NmButton } from 'shared/atoms/NmButton.js'
import { useAuth } from 'hooks/useAuth'
import { UrlUtil } from 'utility/UrlUtil'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.cassette.main,
    textAlign: 'center'
  },
  detail: {
    margin: theme.spacing(2),
    width: '90%',
    borderRadius: '9px',
    backgroundColor: theme.palette.common.white,
    paddingBottom: '16px'
  },
  collapse: {
    width: '500px',
    margin: '10px auto 0px'
  },
  collapseInner: {
    marginBottom: '10px'
  },
  listTitle: {
    width: '60px',
    flex: 'none'
  },
  descriptionContainer: {
    padding: '28px 28px 0px 28px'
  },
  listItem: {
    padding: '0px'
  },
  recommendMessage: {
    width: '100%',
    fontSize: '8px',
    fontWeight: 'bold'
  },
  entryButton: {
    backgroundColor: 'rgba(255, 90, 90, 0.3)',
    color: theme.palette.text.primary,
    marginBottom: '20px',
    '&:hover': {
      backgroundColor: 'rgba(210, 44, 52, 0.3)'
    }
  },
  loginButton: {
    marginBottom: '20px'
  },
  signUpButton: {
    marginBottom: '20px'
  }
}))

export const Entry = (props) => {
  const classes = useStyles()
  const { user } = useAuth()
  const { post } = props

  const handleClickInquiry = () => {
    window.location.href = UrlUtil.PressReleaseInquiry(post.id)
  }

  const ButtonView = () => {
    if (user.isLogin()) {
      return (<NmButton text='話を聞く' color='primary' onClick={() => { handleClickInquiry() }} />)
    }

    return (
      <div>
        <div>
          <NmButton className={classes.entryButton} text='話を聞く' color='accent' onClick={() => { handleClickInquiry() }} />
        </div>
        <div>
          <NmButton className={classes.loginButton} text='ログインして話を聞く' link='/Login' color='primary' />
        </div>
      </div>)
  }

  return (
    <Grid container className={classes.wrapper}>
      <Grid item className={classes.detail}>
        <Box m={3}>本記事についてのお問い合わせが可能です。</Box>
        <ButtonView />
      </Grid>
    </Grid>
  )
}
