import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px'
  },
  link: {
    'text-decoration': 'none'
  },
  contentHeader: {
    marginTop: '70px',
    marginBottom: '15px'
  },
  contentBody: {
    marginBottom: '50px'
  }
}))

export const Body = (props) => {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <Typography
        variant='h1' align='center'
        className={classes.contentHeader}
      >送信完了
      </Typography>
      <Typography variant='body1' align='center' className={classes.contentBody}>
        お問い合わせいただき、ありがとうございました。<br />
        担当者より折り返しご連絡させていただきます。<br />
        今しばらくお待ちくださいますようよろしくお願い申し上げます。
      </Typography>
      <Typography variant='body1' align='center' className={classes.contentBody}>
        <Link className={classes.link} to='/'>
          トップに戻る
        </Link>
      </Typography>
    </Container>
  )
}
