import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { Container, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px'
  },
  link: {
    'text-decoration': 'none'
  },
  contentHeader: {
    marginTop: '70px',
    marginBottom: '15px'
  },
  contentBody: {
    marginBottom: '50px'
  }
}))

export const Body = (props) => {
  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <Typography
        variant='h1' align='center'
        className={classes.contentHeader}
      >お問い合わせを受け付けました。
      </Typography>
      <Typography variant='body1' align='center' className={classes.contentBody}>
        改めて担当者よりご連絡差し上げますので今しばらくお待ち下さい。<br /><br />
        ■対応時間：10:00～18:00（土日祝日除く）<br />
        お問い合わせは24時間お受けしておりますが、返信は対応時間内とさせていただいております。<br />
        土・日・祝日・年末年始のお問い合わせは翌営業日以降の返信となります。<br />
        お問い合わせの内容により、返信時間が変動いたします。
      </Typography>
      <Typography variant='body1' align='center' className={classes.contentBody}>
        <Link className={classes.link} to='/'>
          トップに戻る
        </Link>
      </Typography>
    </Container>
  )
}
