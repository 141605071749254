import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Typography, Button } from '@material-ui/core'
import { ResourceContext } from 'App'
import { Link } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '0 auto 0',
    padding: '10px',
    maxWidth: '670px',
    justifyContent: 'center',
    display: 'flex'
  },
  list: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    paddingBottom: 0,
    paddingTop: 0,
    width: '480px'
  },
  link: {
    fontSize: '16px',
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  listItem: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 0
  },
  listItemText: {
    '& span': {
      color: theme.palette.text.subtext,
      fontSize: '12px'
    },
    '& p': {
      color: theme.palette.text.accent,
      fontSize: '16px'
    }
  },
  dividerBottom: {
    marginBottom: theme.spacing(4)
  }
}))

export const Body = (props) => {
  const classes = useStyles()
  const context = React.useContext(ResourceContext)

  return (
    <Container className={classes.container}>
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <Typography variant='h2'>
            アカウント情報
          </Typography>
          <ListItemSecondaryAction>
            <Link to='/AccountUpdate' className={classes.link}>
              <Button color='primary'>編集する</Button>
            </Link>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText className={classes.listItemText} primary='お名前' secondary={context.user.name} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText className={classes.listItemText} primary='会社名' secondary={context.user.company} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText className={classes.listItemText} primary='媒体(番組)名' secondary={context.user.media} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText className={classes.listItemText} primary='職種' secondary={context.user.job} />
        </ListItem>
        <Divider className={classes.dividerBottom} />
        <ListItem className={classes.listItem}>
          <Typography variant='h2'>
            ログイン情報
          </Typography>
        </ListItem>
        <ListItem>
          <ListItemText className={classes.listItemText} primary='メールアドレス' secondary={context.user.email} />
        </ListItem>
        <Divider />
        {/* <ListItem> */}
        {/*  <ListItemText primary='パスワード' /> */}
        {/*  <ListItemSecondaryAction> */}
        {/*    <Link href='#' className={classes.link}> */}
        {/*      変更する */}
        {/*    </Link> */}
        {/*  </ListItemSecondaryAction> */}
        {/* </ListItem> */}
        {/* <Divider /> */}
        {/* <ListItem> */}
        {/*  <ListItemSecondaryAction> */}
        {/*    <Link href='#' className={classes.link}> */}
        {/*      退会はこちらから */}
        {/*    </Link> */}
        {/*  </ListItemSecondaryAction> */}
        {/* </ListItem> */}
      </List>
    </Container>

  )
}
