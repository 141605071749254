import React from 'react'

import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'

import { Carousel } from './Carousel'
import { NewPosts } from './NewPosts'
import { PressReleases } from './PressReleases'
import { RootCategorySection } from './RootCategorySection'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = () => {
  setPageTitle(TitleUtil.home())
  const content = <>
    <Carousel />
    <NewPosts />
    <RootCategorySection />
    <PressReleases />
  </>

  return (
    <TemplateSideColumn content={content} />
  )
}
