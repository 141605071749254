import { useInfiniteQuery } from 'react-query'

/**
 * 「もっと読む」用の無限クエリを実行するためのUtilityクラス
 */
export default class InfiniteQueryUtil {
  queryItem = null
  queryKey = []

  constructor (queryKey) {
    this.queryKey = queryKey
  }

  setQueryItem (queryItem) {
    this.queryItem = queryItem
  }

  fetch(queryKeyProp) {
    const queryKey = queryKeyProp || this.queryKey
    return useInfiniteQuery(queryKey, this.generateFetch(), {
      getNextPageParam: this.generateGetNextPageParam()
    })
  }
  
  /**
   * ページ番号を元にoffsetを生成してfetchを実行する
   * @param {{
   *  pageParam: {
   *    page: number
   *  }
   * }} param
   * @returns {{
   *  page: number, // 現在のページ番号
   *  item: Array, // fetchしてきたitemの配列
   *  count: number // 最大アイテム数
   * }} 
   */
  async _fetch({pageParam = {}}) {
    const page = pageParam.page || 1
    const defaultPostPerPage = this.queryItem.getDefaultPerPage()
    const offset = defaultPostPerPage * (page - 1)
    const res = await this.queryItem.fetch(defaultPostPerPage, offset)
    const pageItems = res?.body || []
  
    return {
      page,
      items: pageItems,
      count: res?.count || 0
    }
  }

  generateFetch() {
    return this._fetch.bind(this)
  }

  /**
   * 次のfetchに必要な情報を生成する
   * undefinedを返すとhasNextPageがfalseになる
   * @param {*} lastPage 
   * @param {*} allPages 
   * @returns {{
   *  page: number // 次のfetchのページ番号
   * }}
   */
  getNextPageParam (lastPage, allPages) {
    const defaultPostPerPage = this.queryItem.getDefaultPerPage()
    const maxItemCount = lastPage.count
    const sumItems = defaultPostPerPage * lastPage.page

    // 現在までに取得したitem数が最大レコード数を超えると次のページは無い
    if ( sumItems >= maxItemCount ) return undefined
    
    return {
      page: lastPage.page + 1
    }
  }

  generateGetNextPageParam() {
    return this.getNextPageParam.bind(this)
  }
}