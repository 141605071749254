export class User {
  constructor (name, email, tel, media, job, company) {
    this.name = name
    this.email = email
    this.media = media
    this.job = job
    this.company = company
    this.tel = tel
  }

  isLogin () {
    return this.name !== undefined
  }
}
