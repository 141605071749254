import React from 'react'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'
import { Body } from './Body'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = (props) => {
  const status = decodeURI(
    new URLSearchParams(props.location.search).get('status')
  )

  setPageTitle(TitleUtil.companySignupComplete())

  return (
    <TemplateSideColumn content={<Body status={status} />} />
  )
}
