import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { NmButton } from 'shared/atoms/NmButton.js'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.cassette.main,
    textAlign: 'center'
  },
  buttonViewArea: {
    marginTop: theme.spacing(2)
  },
  Button: {
    marginBottom: theme.spacing(2)
  }
}))

export const Contact = (props) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.wrapper}>
      <Grid item className={classes.buttonViewArea}>
        <NmButton className={classes.Button} text='資料をダウンロード' color='accent' link={props.post.docUrl} />
      </Grid>
    </Grid>
  )
}
