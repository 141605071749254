import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'shared/atoms/Link'
import AboutImage from 'image/about.png'
import React from 'react'

const useStyles = makeStyles({
  aboutImage: {
    width: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    marginTop: '10px'
  }
})

export const AboutNetamatch = () => {
  const classes = useStyles()

  return (
    <Box>
      <Box><Typography variant='h2'>ネタマッチとは</Typography></Box>
      <Link to='/About'>
        <Box width='100%' maxHeight='216px'>
          <img className={classes.aboutImage} src={AboutImage} alt='ネタマッチとは' />
        </Box>
      </Link>
    </Box>
  )
}
