import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  CardActionArea,
  CardMedia,
  Typography,
  Card,
  Grid
} from '@material-ui/core'
import { Chip } from 'shared/atoms/Chip'
import { Link } from 'react-router-dom'
import * as DateTimeUtil from '../utility/DateTimeUtil'
import { limitedCategoryLength } from 'const/Const'

const useStyles = makeStyles((theme) => ({
  card: {
    height: 260,
    width: 240,
    margin: '0 auto'
  },
  categoryChip: {
    borderRadius: 0,
    fontSize: '10px',
    fontWeight: 400,
    height: '26px'
  },
  postChip: {
    '&:nth-child(even)': {
      marginLeft: '8px',
    },
    backgroundColor: '#FFF0F2'
  },
  pressReleaseCard: {
    height: 260,
    width: 240,
    margin: '0 auto'
  },
  pressReleaseChip: {
    '&:nth-child(even)': {
      marginLeft: '8px',
    },
    backgroundColor: '#DEF0FF'
  },
  wrapper: {
    position: 'relative',
    // height: 260
  },
  media: {
    height: '92px',
    maxHeight: '92px',
    paddingTop: '0' // 16:9
  },
  link: {
    display: 'block',
    height: '100%',
    textDecoration: 'none'
  },
  contentArea: {
    height: '100px',
    maxHeight: '100px',
    padding: '4px',
  },
  contentTitleArea: {
    maxHeight: '66px'
  },
  contentTitle: {
    maxHeight: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    margin: '0px',
    overflowWrap: 'break-word',
    fontFamily: 'Noto Sans JP',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '140%',
  },
  date: {
    flexGrow: 0,
    flexShrink: 0,
    fontSize: '12px',
    color: theme.palette.text.secondary,
    marginTop: '4px',
    marginBottom: '4px',
  },
  footer: {
    color: theme.palette.text.secondary,
    position: 'absolute',
    bottom: 0,
    boxSizing: 'border-box',
    width: '100%'
  },
  chipCardContent: {
    color: theme.palette.text.secondary,
    position: 'absolute',
    bottom: 0,
    right: 0
  },
  chipContainer: {
    textAlign: 'right'
  }
}))

export const PostCardVertically = (props) => {
  const classes = useStyles()
  const post = props.post
  const rootCategories = props.rootCategories || []

  // TODO: 見通しが悪くてバグを生みそうなので、プレスリリースと通常投稿でコンポーネントから分けたい
  // これまで通りPostCardにpostを渡すが、内部でpost.typeを見てPostCardNormalとPostCardPressReleaseのどちらかを返すようにすることで透過的に扱えるようにしたい
  const genetateCategoryChip = (post) => {
    if (post.type === 'press_release') {
      return <Chip className={`${classes.pressReleaseChip}`} label='プレスリリース' />
    }
    if (post.type === 'post') {
      const rootCategoryIds = rootCategories.map(rootCategory => rootCategory.id)
      const categoryCollection = post.getCategoryCollection()
      const parentRootCategoryCollection = categoryCollection
        .extractRootCategoriesByIds(rootCategoryIds)
        .slice(0, limitedCategoryLength)
      
      return <>
        {parentRootCategoryCollection.getCategoryNames()?.map(categoryName => 
          <Chip className={`${classes.postChip}`} label={categoryName} />
        )}
      </>
    }
    return <></>
  }

  return (
    <Card >
      <CardActionArea className={classes.wrapper}>
        <Link className={classes.link} to={post.getDetailPath()}>
          <CardMedia
            className={classes.media}
            alt='記事'
            image={post.getImage()}
            title='記事'
          />
          <Grid item xs container direction="column" spacing={0} className={classes.contentArea}>
            <Grid item xs className={classes.contentTitleArea}>
              <Typography gutterBottom variant='h6' component='h6' className={classes.contentTitle}>
                {post.title}
              </Typography>
            </Grid>
            <Grid item className={classes.date}>
              {DateTimeUtil.fromIsoToFormat(post.date, 'yyyy.MM.dd')}
            </Grid>
            {/* <Grid item xs container direction="row" alignItems="flex-end" spacing={0}>
              {genetateCategoryChip(post)}
            </Grid> */}
          </Grid>
        </Link>
      </CardActionArea>
    </Card>
  )
}
