import React from 'react'
import WpApi from 'api/WpApi'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'
import { FeaturePosts } from './FeaturePosts'
import { useQuery } from 'react-query'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = (props) => {
  const featureId = props.match.params.id
  const { data: feature, isLoading } = useQuery(['features', featureId], () =>
    WpApi.getFeature(featureId)
  )
  setPageTitle(TitleUtil.featurePosts(feature))

  const content = <>
    {feature && <FeaturePosts feature={feature} paginationEnable />}
  </>

  return (
    <TemplateSideColumn content={content} />
  )
}
