import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Divider } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  desc: {
    marginTop: '4px',
    fontSize: '16px',
    lineHeight: '160%'
  },
  line: {
    marginTop: theme.spacing(1)
  }
}))

export const SectionTitle = (props) => {
  const classes = useStyles()
  let d = ''
  if (props.desc) {
    const desc = props.desc
    d = desc.replace(/\r?\n/g, '<br />')
  }
  return (
    <Grid className={classes.root}>
      <Grid>
        <Typography variant='h2'>
          {props.title}
        </Typography>
        <Typography variant='subtitle1' className={classes.desc} dangerouslySetInnerHTML={{ __html: d }} />
      </Grid>
      <Divider className={classes.line} />
    </Grid>
  )
}
