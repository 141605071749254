import React, { useState } from 'react'
import {
  MenuItem,
  Typography,
  FormControl,
  FormHelperText,
  Button,
  makeStyles
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { NmFormTextField } from 'shared/NmForm/NmFormTextField'
import { NmFormCheckbox } from 'shared/NmForm/NmFormCheckbox'
import { NmForm } from 'shared/NmForm'
import { NmFormTextArea } from 'shared/NmForm/NmFormTextArea'
import { NmFormSubmitGroup } from 'shared/NmForm/NmFormSubmitGroup'
import { NmFormButton } from 'shared/NmForm/NmFormButton'
import { Link } from 'react-router-dom'
import { jobCategories, mediaCategories } from 'const/Const'

import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { UrlUtil } from 'utility/UrlUtil'
import { NmFormSelect } from 'shared/NmForm/NmFormSelect'
import BemapApi from '../../api/BemapApi'

const schema = Yup.object().shape({
  name: Yup.string().required('必須項目です'),
  email: Yup.string()
    .required('必須項目です')
    .email('メールアドレスの形式が正しくありません'),
  tel: Yup.string()
    .required('必須項目です')
    .matches(
      /^[0-9]{10,11}$/,
      '半角数字10-11文字で入力してください 例)0312345678'
    ),
  companyName: Yup.string().required('必須項目です'),
  mediaName: Yup.string().required('必須項目です'),
  mediaCategory: Yup.string().required('必須項目です'),
  jobTitle: Yup.string().required('必須項目です'),
  inquiry: Yup.string()
    .required('必須項目です')
    .max(800, '800文字以内で入力してください'),
  isConsent: Yup.boolean().oneOf([true], '必須項目です')
})

/**
 * themeはuseStyle実行時にsrc/App.js内部のThemeProviderから渡されたものが使用される
 * https://mui.com/styles/basics/#StressTest.js
 */
const useStyles = makeStyles((theme) => ({
  isConsent: {
    paddingBottom: theme.spacing(1)
  },
  consentHelperText: {
    minHeight: 0,
    margin: 0
  },
  link: {
    display: 'block',
    marginTop: '16px',
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  login: {
    fontSize: '14px'
  },
  policyLink: {
    'text-decoration': 'none'
  }
}))

export const GuestForm = (props) => {
  const [prevMediaCategory, setPrevMediaCategory] = useState('99999')
  const { postId } = props
  const classes = useStyles()
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      postId: postId || '',
      name: props.name || '',
      email: props.email || '',
      tel: props.tel || '',
      companyName: props.companyName || '',
      jobTitle: props.jobTitle || '',
      mediaName: props.mediaName || '',
      mediaCategory: props.mediaCategory || '',
      inquiry: props.inquiry || '',
      isConsent: props.isConsent || false,
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = async (values) => {
    try {
      await BemapApi.inquirePressRelease(
        values.postId,
        values.name,
        values.email,
        values.tel,
        values.jobTitle,
        values.mediaName,
        values.companyName,
        values.inquiry
      )
      window.location.href = UrlUtil.PressReleaseInquiryComplete({
        name: values.name,
        email: values.email,
        tel: values.tel,
        company: values.companyName,
        job: values.jobTitle,
        media: values.mediaName
      })
    } catch (err) {
      setError('post', {
        type: 'manual',
        message: 'エラーが発生しました。大変お手数ですがお問い合わせください'
      })
      console.error(err)
    }
  }

  return (
    <NmForm
      title='プレスリリースに関するお問い合わせ'
      onSubmit={handleSubmit(onSubmit)}
    >
      <NmFormTextField
        label='お名前'
        required
        {...register('name')}
        error={Boolean(errors.name)}
        helperText={errors?.name?.message}
      />
      <NmFormTextField
        label='メールアドレス'
        required
        {...register('email')}
        error={Boolean(errors.email)}
        helperText={errors?.email?.message}
      />
      <NmFormTextField
        label='電話番号(ハイフン無し)'
        required
        {...register('tel')}
        error={Boolean(errors.tel)}
        helperText={errors?.tel?.message}
      />
      <NmFormTextField
        label='会社名'
        required
        {...register('companyName')}
        error={Boolean(errors.companyName)}
        helperText={errors?.companyName?.message}
      />
      <NmFormTextField
        label='媒体(番組)名'
        required
        {...register('mediaName')}
        error={Boolean(errors.mediaName)}
        helperText={errors?.mediaName?.message}
      />
      <NmFormSelect
        name='jobTitle'
        label='職種'
        control={control} 
        error={Boolean(errors.jobTitle)}
        helperText={errors?.jobTitle?.message}
      >
        {jobCategories.map((category) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </NmFormSelect>
      <NmFormSelect
        name='mediaCategory'
        label='メディア分類'
        control={control} 
        error={Boolean(errors.mediaCategory)}
        helperText={errors?.mediaCategory?.message}
        onChange={(field, value) => {
          const newMediaCategory = value.props.value
          const shouldResetDependentSelect =
                newMediaCategory !== prevMediaCategory
          if (shouldResetDependentSelect) {
            setValue('assignedArea', '')
          }
          setPrevMediaCategory(newMediaCategory)
        }}
      >
        {Object.keys(mediaCategories).map((key) => (
          <MenuItem key={key} value={key}>
            {mediaCategories[key]}
          </MenuItem>
        ))}
      </NmFormSelect>
      <NmFormTextArea
        label='問い合わせ内容'
        required
        {...register('inquiry')}
        error={Boolean(errors.inquiry)}
        helperText={errors?.inquiry?.message}
      />
      {errors && errors.post ? (
        <Alert severity='error'>{errors.post}</Alert>
      ) : null}
      <NmFormSubmitGroup>
        <FormControl
          error={Boolean(errors.isConsent)}
          className={classes.isConsent}
        >
          <div>
            <NmFormCheckbox {...register('isConsent')} />
            <Typography variant='caption'>
              <Link className={classes.policyLink} to='/Terms' target='_blank'>
                利用規約
              </Link>
              に同意する
            </Typography>
          </div>
          <FormHelperText className={classes.consentHelperText}>
            {errors?.isConsent?.message}
          </FormHelperText>
        </FormControl>
        <NmFormButton title='送信する' disabled={isSubmitting} />
        <Link to='/Login' className={classes.link}>
          <Button color='primary' className={classes.login}>
            ログインはこちら
          </Button>
        </Link>
      </NmFormSubmitGroup>
    </NmForm>
  )
}
