import { PostBase } from './PostBase'

export class MediaDoc extends PostBase {
  constructor (id, categories, title, imageUrl, content, createdAt, modifiedAt, docUrl) {
    super(id, categories, title, imageUrl, content, createdAt, modifiedAt)
    this.type = 'mediaDoc'
    this.detailPathDir = '/MediaDocDetail/'
    this.docUrl = docUrl || ''
  }
}
