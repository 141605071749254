import React from 'react'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'
import { Container } from './Container'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = (props) => {
  const postId = props.match.params.id

  setPageTitle(TitleUtil.pressReleaseInquiry())

  const content = <>
    <Container postId={postId} />
  </>

  return <TemplateSideColumn content={content}/>
}
