import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Container, Button, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20
  },
  buttonContainer: {
    textAlign: 'center'
  },
  expandButton: {
    padding: '8px',
    textAlign: 'center'
  },
  expandButtonText: {
    color: theme.palette.text.accent
  },
  expandMoreIcon: {
    fontSize: 22,
    verticalAlign: 'middle'
  }
}))

export const ButtonReadMore = (props) => {
  const classes = useStyles()
  const { hasNextPage, onClick } = props

  return (
    <Box className={classes.buttonContainer}>
      {hasNextPage &&
        <Button className={classes.expandButton} onClick={onClick}>
          <Typography className={classes.expandButtonText} variant='subtitle1'>
            もっと読む
          </Typography>
          <ExpandMore className={classes.expandMoreIcon} />
        </Button>}
    </Box>
  )
}
