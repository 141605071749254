import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Chip } from 'shared/atoms/Chip'
import { limitedCategoryLength } from 'const/Const'

const useStyles = makeStyles((theme) => ({
  postChip: {
    borderRadius: 0,
    fontSize: '10px',
    fontWeight: 400,
    height: '26px',
    '&:nth-child(even)': {
      marginLeft: '8px',
    },
    backgroundColor: '#FFF0F2'
  },
  pressReleaseChip: {
    fontSize: '10px',
    '&:nth-child(even)': {
      marginLeft: '8px',
    },
    backgroundColor: '#DEF0FF'
  },

}))

export const CategoryChip = (props) => {
  const classes = useStyles()
  const post = props.post
  const rootCategories = props.rootCategories

  if (post.type === 'press_release') return <Chip className={`${classes.pressReleaseChip}`} label='プレスリリース'/>
  if (post.type === 'post') {
    const rootCategoryIds = rootCategories.map(rootCategory => rootCategory.id)
    const categoryCollection = post.getCategoryCollection()
    const parentRootCategoryCollection = categoryCollection
      .extractRootCategoriesByIds(rootCategoryIds)
      .slice(0, limitedCategoryLength)

    return <>
      {parentRootCategoryCollection.getCategoryNames(rootCategories)?.map(categoryName =>
        <Chip className={`${classes.postChip}`} key={categoryName} label={categoryName}/>
      )}
    </>
  }
  return <></>
}
