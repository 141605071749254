import React from 'react'
import { Update } from './Update'
import { ResourceContext } from 'App'

export const Body = (props) => {
  const context = React.useContext(ResourceContext)

  return (
    <Update name={context.user.name} company={context.user.company} media={context.user.media} job={context.user.job} />
  )
}
