import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%'
  },
  title: {
    marginTop: '24px',
    marginBottom: '24px'
  },
  subtitle: {
    marginTop: '24px',
    marginBottom: '8px'
  },
  paragraph: {
    marginBottom: '40px'
  }
}))

export const Content = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Typography variant='h1' className={classes.title}>プライバシーポリシー</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        <strong>
          PR オートメーション株式会社および株式会社エムスリー・カンパニー、株式会社ジェイアンドティプランニング（以下、「当社グループ」といいます。）は、『ネタマッチ』上で提供するサービス（以下、「本サービス」といいます。）におけるプライバシー情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
        </strong>
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第1条（プライバシー情報）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        プライバシー情報のうち「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報を指します。<br />
        プライバシー情報のうち「履歴情報および特性情報」とは、上記に定める「個人情報」以外のものをいい、ご利用いただいたサービスやご覧になったページの履歴、ユーザーが検索された検索キーワード、ご利用日時、ご利用の方法、ご利用環境、性別、職業、年齢、ユーザーのIPアドレス、クッキー情報、位置情報、端末の個体識別情報などを指します。
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第２条（プライバシー情報の収集方法）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        当社グループは、ユーザーが利用登録をする際に氏名、勤務先、所属、電話番号、メールアドレスなどの個人情報をお尋ねすることがあります。<br />
        当社グループは、ユーザーについて、利用したサービスや閲覧したページの履歴、検索した検索キーワード、利用日時、利用方法、利用環境（携帯端末を通じてご利用の場合の当該端末の通信状態、利用に際しての各種設定情報なども含みます）、IPアドレス、クッキー情報、位置情報、端末の個体識別情報などの履歴情報および特性情報を、ユーザーが当社グループのサービスを利用し、またはページを閲覧する際に収集します。
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第３条（個人情報を収集・利用する目的）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        当社グループが個人情報を収集・利用する目的は、以下のとおりです。<br />
        （1）ユーザーに自分の登録情報の閲覧や修正、利用状況の閲覧を行っていただくために、氏名、住所、連絡先、利用されたサービスに関する情報を表示する目的<br />
        （2）ユーザーにお知らせや連絡をするためにメールアドレスを利用する場合、必要に応じて連絡したりするため、氏名や住所などの連絡先情報を利用する目的<br />
        （3）ユーザーの本人確認を行うために、氏名、勤務先、所属、電話番号、メールアドレスなどの情報を利用する目的<br />
        （4）ユーザーが簡便にデータを入力できるようにするために、当社グループに登録されている情報を入力画面に表示させたり、ユーザーのご指示に基づいて他のサービスなど（提携先が提供するものも含みます）に転送したりする目的<br />
        （5）ユーザーからのお問い合わせに対応するために、お問い合わせ内容に関する情報など当社グループがユーザーに対してサービスを提供するにあたって必要となる情報や、ユーザーのサービス利用状況、連絡先情報などを利用する目的<br />
        （6）上記の利用目的に付随する目的<br />
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第４条（個人情報の第三者提供）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        当社グループは、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。<br />
        （1）法令に基づく場合<br />
        （2）人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき<br />
        （3）公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき<br />
        （4）国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき<br />
        （5）予め次の事項を告知あるいは公表をしている場合<br />
        利用目的に第三者への提供を含むこと<br />
        第三者に提供されるデータの項目<br />
        第三者への提供の手段または方法<br />
        本人の求めに応じて個人情報の第三者への提供を停止すること<br />
        前項の定めにかかわらず、次に掲げる場合は第三者には該当しないものとします。<br />
        （1）当社グループが利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合<br />
        （2）合併その他の事由による事業の承継に伴って個人情報が提供される場合<br />
        （3）個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いているとき<br />
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第５条（個人情報の開示）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        当社グループは、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。<br />
        （1）本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合<br />
        （2）当社グループの業務の適正な実施に著しい支障を及ぼすおそれがある場合<br />
        （3）その他法令に違反することとなる場合<br />
        前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。<br />
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第６条（個人情報の訂正および削除）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        ユーザーは、当社グループの保有する自己の個人情報が誤った情報である場合には、当社グループが定める手続きにより、当社グループに対して個人情報の訂正または削除を請求することができます。<br />
        当社グループは、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正または削除を行い、これをユーザーに通知します。<br />
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第７条（個人情報の利用停止等） </Typography>
      <Typography variant='body1' className={classes.paragraph}>
        当社グループは、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第８条（プライバシーポリシーの変更）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        本ポリシーの内容は、ユーザーに通知することなく、変更することができるものとします。<br />
        当社グループが別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。<br />
      </Typography>
      <Typography variant='h2' className={classes.subtitle}>第９条（お問い合わせ窓口）</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。 <br />
        info@netamatch.com<br />
      </Typography>
      <Typography variant='body1' className={classes.paragraph}>
        最終更新日：2020年6月20日
      </Typography>
    </div>
  )
}
