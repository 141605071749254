import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    // button
    height: '44px',
    minWidth: '260px',
    padding: '0 12px',
    backgroundColor: props => theme.palette[props.color].main,
    '&:hover': {
      backgroundColor: props => theme.palette[props.color].dark
    },
    borderRadius: '9px',
    // text
    fontSize: '18px',
    letterSpacing: '6%',
    textAlign: 'center',
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold
  }
}))

export const NmButton = (props) => {
  const classes = useStyles(props)
  return (
    <Button className={classes.root + ' ' + props.className} disabled={props.disabled} href={props.link} onClick={props.onClick}>
      {props.text}
    </Button>
  )
}
