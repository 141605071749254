import QueryItemFeaturePost from 'utility/query_item/FeaturePost'
import InfiniteQueryUtil from '../utility/InfiniteQueryUtil'

export const useInfiniteFeaturePosts = (featureId) => {
  if (!featureId)
    return {
      error: new Error('featureIdがありません'),
      data: [],
      hasNextPage: false
    }
  const queryItem = new QueryItemFeaturePost({ featureId, perPage: 12 })
  const infiniteQueryUtil = new InfiniteQueryUtil()
  infiniteQueryUtil.setQueryItem(queryItem)
  const res = infiniteQueryUtil.fetch(['featurePosts', featureId])
  const posts = res?.data?.pages?.reduce((posts, page) => posts.concat(page?.items), [])

  return {
    ...res,
    data: posts
  }
}
