import InfiniteQueryUnshiftSubItemUtil from 'utility/InfiniteQueryUnshiftSubItemUtil'
import QueryItemsNewPost from 'utility/query_item/NewPost'
import QueryItemsPressRelease from 'utility/query_item/PressRelease'

export const useInfiniteNewPostsUnshiftPressRelease = () => {
  const queryItemsNewPost = new QueryItemsNewPost({
    perPage: 8
  })
  const queryItemsPressRelease = new QueryItemsPressRelease({
    perPage: 4
  })
  const infiniteQueryUnshiftSubItemUtil = new InfiniteQueryUnshiftSubItemUtil()
  infiniteQueryUnshiftSubItemUtil.setMainQueryItem(queryItemsNewPost)
  infiniteQueryUnshiftSubItemUtil.setSubQueryItem(queryItemsPressRelease)
  const res = infiniteQueryUnshiftSubItemUtil.fetch([
    'NewPostsUnshiftPressRelease'
  ])

  const items = res?.data?.pages?.reduce(
    (posts, page) => posts.concat(page?.items),
    []
  )

  return {
    ...res,
    data: items
  }
}
