import React from 'react'
import { useQuery } from 'react-query'
import WpApi from 'api/WpApi'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

import { Point } from './Point'
import { Image } from './Image'
import { Title } from './Title'
import { SubTitle } from './SubTitle'
import { Content } from './Content'
import { NewsSource } from './NewsSource'

import { Contact } from './Contact'
import { RelatedPosts } from 'page/post_detail/RelatedPosts'
import { setPageTitle } from 'hooks/setPageTitle'
import TitleUtil from 'utility/TitleUtil'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '702px',
    margin: 'auto'
  }
}))

export const Body = (props) => {
  const classes = useStyles()
  const { post_id: postId, nonce, isPreview } = props
  const { data: post } = useQuery(['post', postId], () => WpApi.getPost(postId, nonce, isPreview))
  setPageTitle(TitleUtil.postDetail(post))

  if (!post) return null

  return (
    <>
      <Box className={classes.root}>
        <Title post={post} />
        <SubTitle post={post} />
        <Image post={post} />
        <Point post={post} />
        <Content post={post} />
        <NewsSource post={post} />
        <Contact post={post} />
      </Box>
      <RelatedPosts post={post} />
    </>
  )
}
