import React from 'react'
import BemapApi from 'api/BemapApi'

import { Error } from 'shared/molecules/Error'
import { Image } from './Image'
import { Title } from './Title'
import { SubTitle } from './Subtitle'
import { Content } from './Content'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Entry } from './Entry'
import { PostSkeleton } from 'shared/skeletons/PostSkeleton'
import { useQuery } from 'react-query'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '702px',
    margin: 'auto'
  }
}))

export const Body = (props) => {
  const classes = useStyles()
  const {
    isLoading,
    data: post,
    error
  } = useQuery(['pressReleaseDetail', props], () =>
    BemapApi.getPressRelease(props.postId, props.nonce, props.isPreview)
  )
  setPageTitle(TitleUtil.pressReleaseDetail(post))

  if (isLoading) return <PostSkeleton />

  if (error) {
    return <Error>エラーが発生しました</Error>
  }

  return (
    <Container className={classes.root}>
      <Title post={post} />
      <SubTitle post={post} />
      <Image post={post} />
      <Content post={post} />
      <Entry post={post} />
    </Container>
  )
}
