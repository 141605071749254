export const defaultPostPerPage = 12
export const defaultPostPerPageForNews = 9
export const defaultPrPerPageForNews = 3
export const limitedCategoryLength = 2

export const jobCategories = [
  'プロデューサー',
  'アシスタントプロデューサー',
  'ディレクター',
  'アシスタントディレクター',
  'リサーチャー',
  '編集担当',
  '編集長',
  '記者/ライター',
  'その他'
]

export const mediaCategories = {
  10101: 'TV・情報番組（ニュース）',
  10102: 'TV・情報番組（企画）',
  10201: 'TV・報道番組（ニュース）',
  10202: 'TV・報道番組（企画）',
  10300: 'TV・健康番組',
  19999: 'TV・その他の番組',
  20001: '新聞・生活',
  20002: '新聞・医療/科学',
  20003: '新聞・経済',
  20004: '新聞・社会',
  30000: '雑誌',
  40000: 'Web'
}

export const wayToParticipate = {
  0: '会場へ出席',
  1: 'オンライン出席'
}
