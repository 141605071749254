import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Container, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
  },
  content: {
    display: 'flex',
    alignItems: 'center'
  },
  imgWrapper: {
    textAlign: 'center'
  },
  thumbNail: {
    width: '100%',
    maxWidth: '300px',
    margin: 'auto'
  },
  body: {
  },
  title: {
  },
  description: {
    marginTop: '15px'
  }
}))

export const Value = (props) => {
  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <Grid container className={classes.content}>
        <Grid item xs={3} className={classes.imgWrapper}>
          <img alt='feat' src={props.imageSrc} className={classes.thumbNail} />
        </Grid>
        <Grid item xs={9} className={classes.body}>
          <Grid item xs={12}>
            <Typography variant='h3' className={classes.title}>{props.title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' className={classes.description}>{props.description}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
