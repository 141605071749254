import React from 'react'
// import React, { useState } from 'react'
import {
  Checkbox,
  Grid,
  Typography,
  FormControl,
  FormHelperText
} from '@material-ui/core'
import { NmFormTextField } from 'shared/NmForm/NmFormTextField'
import { NmFormMultiline } from 'shared/NmForm/NmFormMultiline'
import { NmForm } from 'shared/NmForm'
import { NmFormButton } from 'shared/NmForm/NmFormButton'
import { NmFormText } from 'shared/NmForm/NmFormText'
import { Link } from 'react-router-dom'

export const Form = (props) => {
  const {
    classes,
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit
  } = props

  return (
    <Grid className={classes.root}>
      <Grid>
        <NmForm title='企業会員お問い合わせフォーム' onSubmit={handleSubmit}>
          <NmFormText>
            ネタマッチへプレスリリースの投稿をご希望の方は下記フォームを入力してください。
          </NmFormText>
          <NmFormTextField
            name='name'
            label='会社名'
            required
            onChange={handleChange}
            value={values.name}
            onBlur={handleBlur}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name ? errors.name : ''}
          />
          <NmFormTextField
            name='departmentName'
            label='部署名'
            required
            onChange={handleChange}
            value={values.departmentName}
            onBlur={handleBlur}
            error={touched.departmentName && Boolean(errors.departmentName)}
            helperText={touched.departmentName ? errors.departmentName : ''}
          />
          <NmFormTextField
            name='jobTitle'
            label='役職'
            onChange={handleChange}
            value={values.jobTitle}
            onBlur={handleBlur}
            error={touched.jobTitle && Boolean(errors.jobTitle)}
            helperText={touched.jobTitle ? errors.jobTitle : ''}
          />
          <NmFormTextField
            name='representativeName'
            label='お名前'
            required
            onChange={handleChange}
            value={values.representativeName}
            onBlur={handleBlur}
            error={touched.representativeName && Boolean(errors.representativeName)}
            helperText={touched.representativeName ? errors.representativeName : ''}
          />
          <NmFormTextField
            name='email'
            label='メールアドレス'
            required
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email ? errors.email : ''}
          />
          <NmFormTextField
            name='tel'
            label='電話番号(ハイフン無し)'
            required
            onChange={handleChange}
            value={values.tel}
            onBlur={handleBlur}
            error={touched.tel && Boolean(errors.tel)}
            helperText={touched.tel ? errors.tel : ''}
          />
          <NmFormMultiline
            name='inquiry'
            label='お問い合わせ内容'
            required
            multiline
            onChange={handleChange}
            value={values.textarea}
            rows={10}
            variant='outlined'
            onBlur={handleBlur}
            error={touched.textarea && Boolean(errors.textarea)}
            helperText={touched.textarea ? errors.textarea : ''}
          />
          <FormControl
            error={touched.postPressWalker && Boolean(errors.postPressWalker)}
            className={classes.postPressWalker}
          >
            <div>
              <Checkbox
                name='postPressWalker'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.postPressWalker}
              />
              <Typography variant='caption'>
                Press Walker ※への投稿も希望する
              </Typography>
            </div>
            <FormHelperText className={classes.consentHelperText}>{touched.postPressWalker ? errors.postPressWalker : ''}</FormHelperText>
          </FormControl>
          <FormControl
            error={touched.isConsent && Boolean(errors.isConsent)}
            className={classes.isConsent}
          >
            <div>
              <Checkbox
                required
                name='isConsent'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.isConsent}
              />
              <Typography variant='caption'>
                <Link
                  className={classes.policyLink}
                  to='/Terms'
                  target='_blank'
                >利用規約
                </Link>に同意する
              </Typography>
            </div>
            <FormHelperText className={classes.consentHelperText}>{touched.isConsent ? errors.isConsent : ''}</FormHelperText>
          </FormControl>
          <NmFormButton title='送信する' disabled={isSubmitting} />
          <NmFormText>
            ※Press Walkerは株式会社KADOKAWAが運営する無料のプレスリリース配信サービスです。
          </NmFormText>
        </NmForm>
      </Grid>
    </Grid>
  )
}
