export default class QueryItem {
  perPage = 10
  getDefaultPerPage () {
    return this.perPage
  }
  async fetch (perPage, offset) {
    return []
  }
  getShortageFromRemainItems(remainItemCount) {
    return Math.max((this.getDefaultPerPage() - remainItemCount), 0)
  }
}