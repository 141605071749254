import React from 'react'
import Alert from '@material-ui/lab/Alert'
import { NmForm } from 'shared/NmForm'
import { NmFormTextArea } from 'shared/NmForm/NmFormTextArea'
import { NmFormSubmitGroup } from 'shared/NmForm/NmFormSubmitGroup'
import { NmFormButton } from 'shared/NmForm/NmFormButton'

import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { asyncSendRequest } from 'api/NetamatchApi'
import { UrlUtil } from 'utility/UrlUtil'
import BemapApi from '../../api/BemapApi'

const schema = Yup.object().shape({
  inquiry: Yup.string()
    .required('必須項目です')
    .max(800, '800文字以内で入力してください')
})

export const Form = (props) => {
  const { postId, user } = props
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      postId: postId,
      name: user.name || '',
      email: user.email || '',
      tel: user.tel || '',
      mediaName: user.media || '',
      jobTitle: user.job || '',
      companyName: user.company || '',
      inquiry: props.inquiry || ''
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = async (values) => {
    try {
      await BemapApi.inquirePressRelease(
        values.postId,
        values.name,
        values.email,
        values.tel,
        values.jobTitle,
        values.mediaName,
        values.companyName,
        values.inquiry
      )
      window.location.href = UrlUtil.PressReleaseInquiryComplete({
        name: values.name,
        email: values.email,
        tel: values.tel,
        company: values.companyName,
        job: values.jobTitle,
        media: values.mediaName
      })
    } catch (err) {
      setError('post', {
        type: 'manual',
        message: 'エラーが発生しました。大変お手数ですがお問い合わせください'
      })
      console.error(err)
    }
  }

  return (
    <NmForm
      title='プレスリリースに関するお問い合わせ'
      onSubmit={handleSubmit(onSubmit)}
    >
      <NmFormTextArea
        name='inquiry'
        label='問い合わせ内容'
        required
        {...register('inquiry')}
        error={Boolean(errors.inquiry)}
        helperText={errors?.inquiry?.message}
      />
      {errors && errors.post ? (
        <Alert severity='error'>{errors?.post?.message}</Alert>
      ) : null}
      <NmFormSubmitGroup>
        <NmFormButton title='送信する' disabled={isSubmitting} />
      </NmFormSubmitGroup>
    </NmForm>
  )
}
