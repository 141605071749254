import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import NoImage from 'image/noimage_postcard.png'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 750
  },
  img: {
    width: '100%'
  }
}))

export const Image = (props) => {
  const classes = useStyles()
  const getImage = () => {
    try {
      return props.event.eyeCatchImageUrl
    } catch (e) {
      return NoImage
    }
  }

  return (
    <>
      <img
        className={classes.img}
        alt='nothing'
        src={getImage()}
      />
    </>
  )
}
