import React from 'react'
import { Helmet as ReactHelmet } from 'react-helmet'

export const Helmet = () => {
  return (
    <ReactHelmet>
      <script async src='https://www.instagram.com/embed.js' />
    </ReactHelmet>
  )
}
