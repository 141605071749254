import React from 'react'
import { Link } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
    maxWidth: '100%',
    minHeight: '200px',
    aspectRatio: '16 / 9',
    objectFit: 'contain',
  },
  link: {
    'text-decoration': 'none',
    color: 'white'
  },
  title: {
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    fontSize: '32px',
    color: '#000000',
    letterSpacing: '6px',
    padding: '16px 5%',
    top: '70%',
    maxWidth: '85%',
    fontWeight: 700,
    [theme.breakpoints.down(1280)]: {
      letterSpacing: '4px',
      fontSize: '24px'
    },
    [theme.breakpoints.down('xs')]: {
      letterSpacing: '2px',
      fontSize: '16px'
    }
  },
  container: {
    position: 'relative',
    'padding-left': 0,
    'padding-right': 0,
    maxWidth: '100%',
    maxHeight: '100%',
  }
}))

export const Panel = (props) => {
  const classes = useStyles()
  if (props.title) {
    return (
      <Box className={classes.container}>
        <Link className={classes.link} to={props.link}>
          <img
            className={classes.image}
            src={props.imageSrc}
            alt='特集画像'
          />
          <span className={classes.title}>{props.title}</span>
        </Link>
      </Box>
    )
  } else {
    return (
      <Box className={classes.container}>
        <Link className={classes.link} to={props.link}>
          <img
            className={classes.image}
            src={props.imageSrc}
            alt='特集画像'
          />
        </Link>
      </Box>
    )
  }
}
