import React from 'react'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'
import { Body } from './Body'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = (props) => {
  const search = new URLSearchParams(props.location.search)
  const searchParams = {}
  search.forEach((value, key) => {
    searchParams[key] = decodeURI(value)
  })

  setPageTitle(TitleUtil.pressReleaseInquiryComplete())

  const content = <>
    <Body searchParams={searchParams} />
  </>

  return <TemplateSideColumn content={content}/>
}
