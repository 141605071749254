import React  from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { PostRankingCard } from 'shared/template/SideColumnComponents/PostRankingCard'
import { PressReleaseRanking as ranking } from 'api/NetamatchApi'
import BemapApi from 'api/BemapApi'
import { useQuery } from 'react-query'

const useStyles = makeStyles(() => ({
  cardWrapper: {
    backgroundColor:'#FFFFFF',
    borderRadius:'10px',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 7px 12px 9px',
    margin: '8px 0',
    '&>*': {
      marginTop: '4px',
      marginBottom: '4px'
    },
    '&>*:first-child': {
      marginTop: '0px'
    },
    '&>*:last-child': {
      marginBottom: '0px'
    }
  },
  title: {
    fontWeight: '700'
  }
}))

const fetchPressReleases = async (ranks) => {
  let res = []
  if (ranks.length > 0) {
    for (let i = 0; i < ranks.length; i++) {
      await BemapApi.getPressRelease(ranks[i]).then((pr) => {
        if (pr) res = res.concat(pr)
      })
    }
    return res
  }
}

export const PressReleaseRanking = (props) => {
  const { rootCategories } = props
  const { data: d } = useQuery(['PressReleaseRanking'], () => ranking())
  const ranks = d?.data?.ranking || []
  const { data: prs } = useQuery(['PressRelease',ranks], () => fetchPressReleases(ranks))
  const pressReleases = prs

  const classes = useStyles()
  return (
    <Box className={classes.cardWrapper}>
      <Box display='flex' alignItems='baseline'>
        <Box><Typography variant='h4' className={classes.title}>プレスリリース</Typography></Box>
      </Box>
      {ranks.map((rank, index) => {
        const post = pressReleases?.find( p => String(p.id) === rank)
        if (!post) return
        return (
          <PostRankingCard key={index} rank={index + 1} post={post} rootCategories={rootCategories} />
        )
      })}
    </Box>
  )
}
