
import React from 'react'
import { Controller } from 'react-hook-form'
import { NmFormTextField } from './NmFormTextField'


export const NmFormSelect = (props) => {
  const { control, name, label, onChange: onChangeByProps, error, helperText, children } = props
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <NmFormTextField
          select
          name={name}
          label={label}
          onChange={(field, value) => {
            // react-hook-formに値変更を伝えるためのonChange
            onChange(field, value)
            // ↑のあとで変更をトリガーにユーザ側で何か操作したい場合に、propsで渡された関数を実行する
            onChangeByProps && onChangeByProps(field, value)
          }}
          onBlur={onBlur}
          value={value}
          error={error}
          helperText={helperText}
        >
          {children}
        </NmFormTextField>
      )}
    />
  )
}

      