import React from 'react'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'
import { Header } from 'shared/header/Header'
import { Footer } from 'shared/Footer'
import { Body } from './Body'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = (props) => {
  const email = decodeURI(
    new URLSearchParams(props.location.search).get('email')
  )

  setPageTitle(TitleUtil.signupConfirm())
  const content = <>
    <Body email={email} />
  </>

  return <TemplateSideColumn content={content}/>
}
