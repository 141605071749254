import { PostCategoryCollection } from 'domain/entity/PostCategoryCollection'
import { isoDateToYMD } from '../../utility/DateTimeUtil'
import NoImage from 'image/noimage_postcard.png'

export class PostBase {
  constructor (id, categories, title, imageUrl, content, createdBy, createdAt, modifiedAt) {
    this.id = id
    this.categories = categories || ''
    this.title = title || ''
    this.imageUrl = imageUrl
    this.content = content
    this.date = createdAt
    this.modified = modifiedAt
    this.createdBy = createdBy
    this.type = '' // 継承先で必ず指定する
    this.detailPathDir = '' // 継承先で必ず指定する
  }

  getCreatedBy () {
    let response = this.createdBy
    // 文字数短縮のため「株式会社」を除く
    response = response.replace('株式会社', '')
    return response
  }

  getImage () {
    return this.imageUrl || NoImage
  }

  getYmdDate () {
    return isoDateToYMD(this.date)
  }

  getCategoryCollection () {
    return new PostCategoryCollection(...this.categories)
  }

  getDetailPath () {
    if (this.detailPathDir === '') return '/NotFound'
    return this.detailPathDir + this.id
  }
}
