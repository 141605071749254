export const allowedDomains = [
  '103r.co.jp',
  '1bashi.jp',
  '2005west.com',
  '221kg.com',
  '8senses.co.jp',
  'ab.auone-net.jp',
  'ab.em-net.ne.jp',
  'able.ocn.ne.jp',
  'actindi.net',
  'agoows.jp',
  'ai-land.co.jp',
  'anycr.com',
  'asahi.co.jp',
  'asahi.com',
  'asajo.com',
  'autopro.co.jp',
  'avant-garde.jp',
  'aym.co.jp',
  'baby-calendar.jp',
  'beasup.com',
  'beautebrain.co.jp',
  'beauty-cruise.com',
  'beemerry.jp',
  'beergirl.net',
  'bestsellers.co.jp',
  'biranger.jp',
  'biteki.com',
  'bk2.so-net.ne.jp',
  'bm-sms.co.jp',
  'bond-y.com',
  'brain-communications.jp',
  'broadmedia.co.jp',
  'bunka.ac.jp',
  'bunshun.co.jp',
  'buzzfeed.com',
  'bybirth.jp',
  'cabrain.co.jp',
  'cancam.jp',
  'cancam.tv',
  'cccmh.co.jp',
  'cchan.tv',
  'cheese-inc.com',
  'chuko.co.jp',
  'chunichi.co.jp',
  'cmdb.co.jp',
  'cn-intervoice.com',
  'cocolable.co.jp',
  'condenast.jp',
  'cookpad-dietlab.jp',
  'corp.allabout.co.jp',
  'corpallabout.co.jp',
  'creative2.co.jp',
  'crest.ocn.ne.jp',
  'cyberagent.co.jp',
  'cyzo.com',
  'daily-dairy-news.co.jp',
  'dayorin.com',
  'dempa-times.co.jp',
  'dg7.so-net.ne.jp',
  'diamond.co.jp',
  'dj9.so-net.ne.jp',
  'donuts.ne.jp',
  'eatsmart.co.jp',
  'eco.ocn.jp',
  'ed.sankei.co.jp',
  'eiyo.ac.jp',
  'enfactory.co.jp',
  'entabe.jp',
  'entameplex.com',
  'enuchi.jp',
  'epsp.co.jp',
  'euphoria-factory.com',
  'excite.jp',
  'fan.hi-ho.ne.jp',
  'fashion-press.net',
  'fashionsnap.com',
  'firstship.net',
  'foodchemicalnews.co.jp',
  'frame-inf.co.jp',
  'freepit.co.jp',
  'fujitv.co.jp',
  'fulltime.co.jp',
  'fusosha.co.jp',
  'f-weekly.co.jp',
  'gakken.co.jp',
  'garden.po-jp',
  'gatta-media.com',
  'gentosha.co.jp',
  'getnews.jp',
  'ginza.keizai.biz',
  'glider-associates.com',
  'm3com.jp',
  'netamatch.com',
  'jtplanning.biz'
]
