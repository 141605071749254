import { toBoolean } from 'utility/BooleanUtil'
import { getIdToken } from 'auth/AwsAuth'

import axios from 'axios'

export const PostInquiryNeta = (postId) => {
  const resource = 'inquiries/neta'

  const data = {
    postId: postId
  }

  return asyncSendRequest(resource, 'Post', {}, data)
}

export const PostAttendance = (postId,
  isOnline,
  name,
  email,
  tel,
  jobTitle,
  mediaName,
  companyName,
  companionCount,
  companionNames,
  movieCameraCount,
  photoCameraCount,
  customFieldTitle1 = null,
  customFieldValue1 = null,
  customFieldTitle2 = null,
  customFieldValue2 = null,
  customFieldTitle3 = null,
  customFieldValue3 = null,
  customFieldTitle4 = null,
  customFieldValue4 = null
) => {
  const resource = 'attendances'

  const data = {
    postId: postId,
    isOnline: toBoolean(isOnline),
    name: name,
    email: email,
    tel: tel,
    jobTitle: jobTitle,
    mediaName: mediaName,
    companyName: companyName,
    companionCount: companionCount,
    companionNames: companionNames,
    movieCameraCount: movieCameraCount,
    photoCameraCount: photoCameraCount,
    customFieldTitle1: customFieldTitle1,
    customFieldValue1: customFieldValue1,
    customFieldTitle2: customFieldTitle2,
    customFieldValue2: customFieldValue2,
    customFieldTitle3: customFieldTitle3,
    customFieldValue3: customFieldValue3,
    customFieldTitle4: customFieldTitle4,
    customFieldValue4: customFieldValue4
  }

  const headers = getHeaders()

  return sendRequest(resource, 'Post', headers, {}, data)
}

export const FetchAttendances = (page, token) => {
  const resource = 'attendances'
  const params = {
    page: page
  }
  const headers = getHeaders(token)

  return sendRequest(resource, 'Get', headers, params, {})
}

export const Attend = async (postId,
  isOnline,
  token,
  companionCount,
  companionNames,
  movieCameraCount,
  photoCameraCount,
  customFieldTitle1 = null,
  customFieldValue1 = null,
  customFieldTitle2 = null,
  customFieldValue2 = null,
  customFieldTitle3 = null,
  customFieldValue3 = null,
  customFieldTitle4 = null,
  customFieldValue4 = null) => {
  const resource = 'attendances'
  const data = {
    postId: postId,
    isOnline: toBoolean(isOnline),
    companionCount: companionCount,
    companionNames: companionNames,
    movieCameraCount: movieCameraCount,
    photoCameraCount: photoCameraCount,
    customFieldTitle1: customFieldTitle1,
    customFieldValue1: customFieldValue1,
    customFieldTitle2: customFieldTitle2,
    customFieldValue2: customFieldValue2,
    customFieldTitle3: customFieldTitle3,
    customFieldValue3: customFieldValue3,
    customFieldTitle4: customFieldTitle4,
    customFieldValue4: customFieldValue4
  }
  const headers = getHeaders(token)

  return sendRequest(resource, 'Put', headers, {}, data)
}

export const CancelAttendance = async (attendanceId, token) => {
  const resource = 'attendances/' + attendanceId
  const headers = getHeaders(token)

  return sendRequest(resource, 'Delete', headers, {}, {})
}

export const InquireCompany = async (name, departmentName, jobTitle, representativeName, email, tel, inquiry, postPressWalker) => {
  const resource = 'companies'
  const data = {
    name: name,
    departmentName: departmentName,
    jobTitle: jobTitle,
    representativeName: representativeName,
    email: email,
    tel: tel,
    inquiry: inquiry,
    postPressWalker: postPressWalker
  }
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
  return sendRequest(resource, 'Post', headers, {}, data)
}

export const PostRanking = async () => {
  const resource = 'rankings/post'
  const headers = getHeaders()
  return sendRequest(resource, 'Get', headers)
}

export const PressReleaseRanking = async () => {
  const resource = 'rankings/pressRelease'
  const headers = getHeaders()
  return sendRequest(resource, 'Get', headers)
}

export const asyncSendRequest = async (path, method, params, data) => {
  const token = await getIdToken()
  const headers = getHeaders(token)
  return _sendRequest(path, method, headers, params, data)
}

const getHeaders = (token) => {
  const header = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
  if (token) {
    header.Authorization = 'Bearer ' + token
  }
  return header
}

const sendRequest = async (path, method, headers, params, data) => {
  try {
    const res = await _sendRequest(path, method, headers, params, data)
    return res
  } catch (err) {
    console.log(err)
    console.error('api error: ' + err + '\npath: ' + path)
    throw new Error(err)
  }
}

const _sendRequest = (url, method, headers, params, data) => {
  const configs = {
    baseURL: process.env.REACT_APP_NETAMATCH_API_URL,
    url: url,
    method: method,
    headers: headers,
    params: params,
    data: data
  }

  return axios(configs)
}
