import React from 'react'
import WpApi from '../../api/WpApi'
import { Box } from '@material-ui/core'
import { useQuery } from 'react-query'
import { PostCard } from 'shared/PostCard'
import { SectionTitle } from 'shared/SectionTitle'
import { Link } from 'shared/atoms/Link'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  }
}))

export const SingleCategoryOverview = (props) => {
  const classes = useStyles()
  const { category, rootCategories } = props
  const categoryId = category.id
  const perPage = 1
  const offset = 0
  const { data } = useQuery(['singleCategoryOverview', categoryId], () =>
    WpApi.getCategoryPosts(categoryId, perPage, offset)
  )
  const post = data?.body[0]
  if (!post) return null

  return (
    <Box className={classes.root}>
      <Box>
        <Link to={`/Category/${category.id}`}>
          <SectionTitle title={category.name}/>
        </Link>
      </Box>
      <Box><PostCard post={post} rootCategories={rootCategories} /></Box>
    </Box>
  )
}
