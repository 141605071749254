import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserAttribute,
  AuthenticationDetails
} from 'amazon-cognito-identity-js'
import { CognitoConfig } from './aws/CognitoConfig'
import { AuthErrorCode } from './ConstErrorCode'
import { allowedDomains } from './ConstAllowedDomain'

const userPool = new CognitoUserPool({
  UserPoolId: CognitoConfig.UserPoolId,
  ClientId: CognitoConfig.ClientId
})

export const signUp = (name, email, tel, company, media, job, password) => {
  const attributeList = genAttributeList({
    name: name,
    email: email,
    'custom:company': company,
    'custom:media': media,
    'custom:tel': tel,
    'custom:job': job
  })

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, attributeList, null, function (err, result) {
      if (err) {
        console.log(err.message + err.code || JSON.stringify(err))
        switch (err.code) {
          case 'UsernameExistsException':
            reject(new Error(AuthErrorCode.UsernameExistsError))
            break
          default:
            reject(new Error(AuthErrorCode.InternalError))
        }
        return
      }
      resolve(result)
    })
  })
}

export const resetPassword = (email) => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool
  })
  return new Promise(function (resolve, reject) {
    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        resolve()
      },
      onFailure: function (err) {
        console.log(err.code + ': ' + err.message)
        switch (err.code) {
          case 'UserNotFoundException':
            reject(new Error(AuthErrorCode.UserNotFoundError))
            break
          case 'InvalidParameterException':
            reject(new Error(AuthErrorCode.NotConfirmedError))
            break
          default:
            reject(new Error(AuthErrorCode.InternalError))
            break
        }
      }
    })
  })
}

export const confirmPassword = (email, code, password) => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool
  })
  return new Promise(function (resolve, reject) {
    cognitoUser.confirmPassword(code, password, {
      onSuccess () {
        resolve()
      },
      onFailure (err) {
        console.log('Password not confirmed!')
        console.log(err.code || JSON.stringify(err))
        console.log(err.message || JSON.stringify(err))
        console.log(err.code + ': ' + err.message)
        switch (err.code) {
          case 'UserNotFoundException':
            reject(new Error(AuthErrorCode.UserNotFoundError))
            break
          case 'CodeMismatchException':
            reject(new Error(AuthErrorCode.CodeMismatchError))
            break
          case 'ExpiredCodeException':
            reject(new Error(AuthErrorCode.ExpiredCodeError))
            break
          case 'InvalidPasswordException':
            reject(new Error(AuthErrorCode.InvalidPasswordError))
            break
          default:
            reject(new Error(AuthErrorCode.InternalError))
            break
        }
      }
    })
  })
}

const genAttributeList = (attributes) => {
  const attributeList = []
  for (const key in attributes) {
    attributeList.push(new CognitoUserAttribute({
      Name: key,
      Value: attributes[key]
    }))
  }
  return attributeList
}

export const signIn = (email, password) => {
  const authenticationDetails = new AuthenticationDetails({
    Username: email,
    Password: password
  })

  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool
  })

  return new Promise(function (resolve, reject) {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        cognitoUser.setSignInUserSession(result)
        resolve()
      },
      onFailure: (err) => {
        console.log(err.message)
        console.log(err.code)
        switch (err.code) {
          case 'NotAuthorizedException':
            reject(new Error(AuthErrorCode.NotAuthorizedError))
            break
          case 'UserNotFoundException':
            reject(new Error(AuthErrorCode.UserNotFoundError))
            break
          case 'UserNotConfirmedException':
            reject(new Error(AuthErrorCode.UserNotConfirmedError))
            break
          default:
            reject(new Error(AuthErrorCode.InternalError))
            break
        }
      }
    })
  })
}

export const resendConfirmationCode = (email) => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool
  })
  return new Promise(function (resolve, reject) {
    cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        console.log('Failed to send confirmation code!')
        console.log(err.message + err.code || JSON.stringify(err))
        switch (err.code) {
          case 'UserNotFoundException':
            reject(new Error(AuthErrorCode.UserNotFoundError))
            break
          case 'InvalidParameterException':
            reject(new Error(AuthErrorCode.InvalidParameterError))
            break
          default:
            reject(new Error(AuthErrorCode.InternalError))
            break
        }
      }
      resolve()
    })
  })
}

export const confirmRegistration = (email, code) => {
  const cognitoUser = new CognitoUser(
    {
      Username: email,
      Pool: userPool
    }
  )
  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        console.log(err.message + err.code || JSON.stringify(err))
        switch (err.code) {
          case 'CodeMismatchException':
          case 'UserNotFoundException':
            reject(new Error(AuthErrorCode.CodeMismatchError))
            break
          case 'NotAuthorizedException':
            reject(new Error(AuthErrorCode.NotAuthorizedError))
            break
          case 'ExpiredCodeException':
            reject(new Error(AuthErrorCode.ExpiredCodeError))
            break
          default:
            reject(new Error(AuthErrorCode.InternalError))
            break
        }
        return
      }

      if (allowedDomains.includes(extractDomain(email))) {
        resolve({
          result: result,
          status: 'enabled'
        })
      } else {
        resolve({
          result: result,
          status: 'disabled'
        })
      }
    })
  })
}

const extractDomain = (email) => {
  return email.split('@')[1]
}

export const signOut = () => {
  return new Promise(function (resolve, reject) {
    const cognitoUser = userPool.getCurrentUser()
    if (!cognitoUser) resolve()
    cognitoUser.signOut()
    resolve()
  })
}

export const updateAttributes = (name, company, media, job) => {
  const attributeList = genAttributeList({
    name: name,
    'custom:company': company,
    'custom:media': media,
    'custom:job': job
  })

  return new Promise(function (resolve, reject) {
    const cognitoUser = userPool.getCurrentUser()
    if (!cognitoUser) resolve({})

    cognitoUser.getSession(function (err, session) {
      if (err && err.message) {
        switch (err.message) {
          case 'Username is null. Cannot retrieve a new session':
          case 'Cannot retrieve a new session. Please authenticate.':
          case 'Local storage is missing an ID Token, Please authenticate':
            reject(new Error(AuthErrorCode.NotAuthorizedError))
            break
          default:
            reject(new Error(AuthErrorCode.InternalError))
            break
        }
      }

      cognitoUser.updateAttributes(attributeList, function (err, result) {
        if (err && err.message) {
          switch (err.message) {
            case 'User is not authenticated':
              reject(new Error(AuthErrorCode.NotAuthorizedError))
              break
            default:
              reject(new Error(AuthErrorCode.InternalError))
              break
          }
        }

        resolve()
        if (!result) resolve({})

        // 取得した属性情報を連想配列に格納
        var currentUserData = []
        for (let i = 0; i < result.length; i++) {
          currentUserData[result[i].getName()] = result[i].getValue()
        }
        resolve(currentUserData)
      })
    })
  })
}

export const getUserAttributes = () => {
  return new Promise(function (resolve, reject) {
    const cognitoUser = userPool.getCurrentUser()
    if (!cognitoUser) resolve({})

    cognitoUser.getSession(function (err, session) {
      if (err && err.message) {
        switch (err.message) {
          case 'Username is null. Cannot retrieve a new session':
          case 'Cannot retrieve a new session. Please authenticate.':
          case 'Local storage is missing an ID Token, Please authenticate':
            reject(new Error(AuthErrorCode.NotAuthorizedError))
            break
          default:
            reject(new Error(AuthErrorCode.InternalError))
            break
        }
      }

      cognitoUser.getUserAttributes(function (err, result) {
        if (err && err.message) {
          switch (err.message) {
            case 'User is not authenticated':
              reject(new Error(AuthErrorCode.NotAuthorizedError))
              break
            default:
              reject(new Error(AuthErrorCode.InternalError))
              break
          }
        }

        if (!result) resolve({})

        // 取得した属性情報を連想配列に格納
        var currentUserData = []
        for (let i = 0; i < result.length; i++) {
          currentUserData[result[i].getName()] = result[i].getValue()
        }
        resolve(currentUserData)
      })
    })
  })
}

export const getIdToken = async () => {
  return new Promise(function (resolve, reject) {
    const cognitoUser = userPool.getCurrentUser()
    if (!cognitoUser) reject(new Error(AuthErrorCode.NotAuthorizedError))

    cognitoUser.getSession(function (err, session) {
      if (err && err.message) {
        switch (err.message) {
          case 'Username is null. Cannot retrieve a new session':
          case 'Cannot retrieve a new session. Please authenticate.':
          case 'Local storage is missing an ID Token, Please authenticate':
            reject(new Error(AuthErrorCode.NotAuthorizedError))
            break
          default:
            reject(new Error(AuthErrorCode.InternalError))
            break
        }
      }
      resolve(session.getIdToken().getJwtToken())
    })
  })
}
