import React from 'react'
import { CategoryPosts } from './CategoryPosts'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = (props) => {
  const content = <>
    <CategoryPosts categoryId={props.match.params.id} />
  </>
  return (
    <TemplateSideColumn content={content} />
  )
}
