import React from 'react'
import { Checkbox } from '@material-ui/core'

export const NmFormCheckbox = React.forwardRef((props, ref) => {
  const { onChange, onBlur, name, value } = props
  return (
    <Checkbox
      onChange={onChange}
      onBlur={onBlur}
      inputRef={ref}
      name={name}
      value={value}
    />
  )
})
