import React from 'react'
import { Helmet } from 'react-helmet'

export const GtagScript = ({ ga4Id, userId, uaId }) => {
  const config = {
    send_page_view: false
  }
  if (userId) config.user_id = userId

  return (
    <Helmet>
      <script src={`https://www.googletagmanager.com/gtag/js?id=${ga4Id}`} />
      <script>
        {`
        window.dataLayer = window.dataLayer || []
        function gtag() {
          dataLayer.push(arguments)
        }
        gtag('js', new Date())

        gtag('config', '${ga4Id}', ${JSON.stringify(config)})
        gtag('config', '${uaId}', ${JSON.stringify(config)})
        `}
      </script>
    </Helmet>
  )
}
