import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { resetPassword } from 'auth/AwsAuth'
import { NmFormTextField } from 'shared/NmForm/NmFormTextField'
import { NmForm } from 'shared/NmForm'
import { NmFormText } from 'shared/NmForm/NmFormText'
import { NmFormSubmitGroup } from 'shared/NmForm/NmFormSubmitGroup'
import { NmFormButton } from 'shared/NmForm/NmFormButton'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { AuthErrorCode } from 'auth/ConstErrorCode'

const useStyles = (theme) => ({
  root: {
    margin: '0 auto 0',
    padding: '10px',
    maxWidth: '670px',
    justifyContent: 'center'
  },
  isConsent: {
    paddingBottom: theme.spacing(1)
  },
  consentHelperText: {
    minHeight: 0,
    margin: 0
  }
})

const schema = Yup.object().shape({
  email: Yup.string()
    .required('必須項目です')
    .email('メールアドレスの形式が正しくありません')
})

const Form = (props) => {
  const { classes } = props
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = (values) => {
    resetPassword(values.email)
      .then((result) => {
        window.location.href =
          '/ResetPassword?email=' + encodeURIComponent(values.email)
      })
      .catch((err) => {
        switch (err.message) {
          case AuthErrorCode.UserNotFoundError:
            setError('email', {
              type: 'manual',
              message: 'このメールアドレスは登録されていません'
            })
            break
          case AuthErrorCode.NotConfirmedError:
            setError('email', {
              type: 'manual',
              message: 'このメールアドレスは認証されていません'
            })
            break
          default:
            setError('email', {
              type: 'manual',
              message:
                'エラーが発生しました。大変お手数ですがお問い合わせください'
            })
        }
      })
  }

  return (
    <Grid className={classes.root}>
      <Grid>
        <NmForm
          title='パスワードをお忘れの方'
          onSubmit={handleSubmit(onSubmit)}
        >
          <NmFormText>
            ご登録されたメールアドレスを入力し案内に沿ってパスワードを再設定してください。
          </NmFormText>
          <NmFormTextField
            name='email'
            label='メールアドレス'
            {...register('email')}
            error={Boolean(errors.email)}
            helperText={errors?.email?.message}
          />
          <NmFormSubmitGroup>
            <NmFormButton title='送信する' disabled={isSubmitting} />
          </NmFormSubmitGroup>
        </NmForm>
      </Grid>
    </Grid>
  )
}

export const Body = withStyles(useStyles)(Form)
