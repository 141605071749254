import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Typography, Button, Dialog } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import WpApi from 'api/WpApi'
import { isPastFromsql, sqlFormatToJapaneseString } from 'utility/DateTimeUtil'
import { Attend, CancelAttendance, FetchAttendances } from 'api/NetamatchApi'
import { getIdToken } from 'auth/AwsAuth'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '0 auto 0',
    padding: '10px',
    maxWidth: '670px',
    justifyContent: 'center',
    display: 'flex'
  },
  list: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    paddingBottom: 0,
    paddingTop: 0,
    width: '480px'
  },
  link: {
    fontSize: '16px',
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  title: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingLeft: 0
  },
  listItem: {
    paddingLeft: 0
  },
  modal: {
    borderRadius: 0,
    minWidth: '500px',
    height: '500px'
  },
  eventTitleContainer: {
    paddingBottom: '12px',
    margin: 0
  },
  eventTitle: {
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}))

export const Body = (props) => {
  const classes = useStyles()
  const [attendances, setAttendances] = useState([])
  const [isLoading, setIsloading] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isDialogCancel, setIsDialogCancel] = useState(false)
  const [activeAttendanceIndex, setActiveAttendanceIndex] = useState('')
  useEffect(() => {
    setIsloading(true)
    getIdToken()
      .then((result) => {
        FetchAttendances(1, result).then((response) => {
          if (response.status > 200) {
            console.log(
              'error: {statusCode: ' +
                response.status +
                ', body: ' +
                response.data +
                '}'
            )
            setIsloading(false)
            return
          }
          const rawAttendances = response.data.attendances
          let newAttendances = []
          const promises = rawAttendances.map((a) => {
            return WpApi.getEvent(a.postId).then((event) => {
              a.post = {
                title: event.title,
                scheduledDate: event.scheduledDate,
                location: event.location
              }
              //  https://reactjs.org/docs/hooks-reference.html#bailing-out-of-a-state-update
              newAttendances = [...newAttendances, a]
            })
          })
          Promise.all(promises)
            .then(() => {
              setAttendances(newAttendances)
            })
            .finally(() => {
              setIsloading(false)
            })
        })
      })
      .catch((err) => {
        console.log(err)
        setIsloading(false)
      })
  }, [])

  const StatusButton = (attendance, index) => {
    const isPast = isPastFromsql(attendance.post.datetime)
    console.log(isPast)
    let buttonWord = '参加予定'
    if (attendance.isCancel) {
      buttonWord = 'キャンセル済み'
    }
    if (isPast) return <Button color='primary' disabled>{buttonWord}</Button>
    return <Button onClick={() => openDialog(index, attendance.isCancel)} color='primary'>{buttonWord}</Button>
  }

  const openDialog = (index, isCancel) => {
    setIsDialogOpen(true)
    setIsDialogCancel(isCancel)
    setActiveAttendanceIndex(index)
  }

  const dialogTitle = (isCancel) => {
    if (isCancel) return 'イベントへ参加しますか？'
    return 'イベントをキャンセルしますか？'
  }

  const dialogButton = (isCancel) => {
    if (isCancel) return '参加する'
    return 'キャンセルする'
  }

  const noAttendanceMessage = () => {
    if (isLoading) return '申し込みイベントを読み込んでいます'
    return '申し込み済みのイベントはございません'
  }

  const handleClose = () => { setIsDialogOpen(false) }

  const updateAttendance = async () => {
    try {
      const token = await getIdToken()
      if (attendances[activeAttendanceIndex].isCancel) {
        const response = await Attend(attendances[activeAttendanceIndex].postId, attendances[activeAttendanceIndex].isOnline, token)
        if (response.status > 200) {
          console.log('error: {statusCode: ' + response.status + ', body: ' + response.data + '}')
          return
        }
      } else {
        const response = await CancelAttendance(attendances[activeAttendanceIndex].id, token)
        if (response.status > 200) {
          console.log('error: {statusCode: ' + response.status + ', body: ' + response.data + '}')
          return
        }
      }
      attendances[activeAttendanceIndex].isCancel = !attendances[activeAttendanceIndex].isCancel
    } catch (err) {
      console.log(err)
    } finally {
      setIsloading(true)
      setIsDialogOpen(false)
    }
  }

  const AttendanceContent = () => {
    if (attendances.length > 0) return AttendanceItems
    return (
      <ListItem divider className={classes.listItem}>
        <ListItemText className={classes.listItemText}>
          <Typography variant='subtitle1'>{noAttendanceMessage()}</Typography>
        </ListItemText>
      </ListItem>
    )
  }

  const AttendanceItems = attendances.map((a, i) =>
    <ListItem divider key={a.id} className={classes.listItem}>
      <ListItemText
        className={classes.listItemText} secondary={
          <>
            <Typography variant='body2'>{'日時：' + sqlFormatToJapaneseString(a.post.scheduledDate) + '〜'}</Typography>
          </>
        }
      >
        <p className={classes.eventTitleContainer}>
          <Typography variant='subtitle1' className={classes.eventTitle} component='a' href={'EventDetail/' + a.postId}>{a.post.title}</Typography>
        </p>

      </ListItemText>
      <ListItemSecondaryAction>
        {StatusButton(a, i)}
      </ListItemSecondaryAction>
    </ListItem>
  )

  return (
    <Container className={classes.container}>
      <List className={classes.list}>
        <ListItem className={classes.title}>
          <Typography variant='h2'>
            申し込みイベント
          </Typography>
        </ListItem>
        <Divider />
        <AttendanceContent />
      </List>
      <Dialog onClose={handleClose} open={isDialogOpen}>
        <DialogTitle>{dialogTitle(isDialogCancel)}</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleClose()} color='primary' variant='subtitle1'>
            戻る
          </Button>
          <Button onClick={() => updateAttendance()} color='primary' autoFocus>
            {dialogButton(isDialogCancel)}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
