
export class PostCategoryCollection extends Array {
  /**
   * categoriesのうち、引数で渡されたEntityCategoryに
   * 含まれているものだけを残す
   * @param {string} rootCategoryIds
   */
  extractRootCategoriesByIds (rootCategoryIds) {
    return this.filter(categoryId => rootCategoryIds.includes(categoryId))
  }

  /**
     * ルートカテゴリー名の配列を返す
     * @returns {string[]}
     */
  getCategoryNames (rootCategories) {
    if (this.length === 0) return ['未分類']
    return this.map(categoryId => rootCategories.find(rootCat => rootCat.id === categoryId).name || '未分類')
  }
}
