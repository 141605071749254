import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, TextField, MenuItem, FormControl, Button, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px',
    maxWidth: '670px'
  },

  formControl: {
    width: '80%',
    display: 'block',
    margin: '0 auto 0'
  },
  iframe: {
    display: 'none'
  },
  contentHeader: {
    marginTop: '70px',
    marginBottom: '15px'
  },
  contentBody: {
    marginBottom: '50px'
  },
  textField: {
    marginTop: '15px',
    marginBottom: '15px',
    '& > div > fieldset > legend': {
      display: 'block'
    }
  },
  button: {
    marginTop: '20px',
    marginBottom: '70px'
  }
}))

export const Body = (props) => {
  const classes = useStyles()
  const redirectToThanks = () => {
    window.location.href = '/ContactThanks'
  }

  const jobCategory = ['選択してください',
    'プロデューサー',
    'アシスタントプロデューサー',
    'ディレクター',
    'アシスタントディレクター',
    'リサーチャー',
    '編集担当',
    '編集長',
    '記者/ライター',
    'その他'
  ]

  const [beforeSubmit, setBeforeSubmit] = React.useState(true)
  const [job, setJob] = React.useState('選択してください')

  const [nameError, setNameError] = React.useState(true)
  const [mailError, setMailError] = React.useState(true)
  const [companyError, setCompanyError] = React.useState(true)
  const [mediaError, setMediaError] = React.useState(true)
  const [jobError, setJobError] = React.useState(true)

  const onSubmit = (event) => {
    setBeforeSubmit(false)
    if (nameError || mailError || companyError || mediaError || jobError) return event.preventDefault()
  }

  const onChangeName = (event) => {
    event.target.value !== '' ? setNameError(false) : setNameError(true)
  }
  const onChangeMail = (event) => {
    event.target.value !== '' ? setMailError(false) : setMailError(true)
  }
  const onChangeMedia = (event) => {
    event.target.value !== '' ? setMediaError(false) : setMediaError(true)
  }
  const onChangeCompany = (event) => {
    event.target.value !== '' ? setCompanyError(false) : setCompanyError(true)
  }

  const onChangeJob = (event) => {
    setJob(event.target.value)
    event.target.value !== '選択してください' ? setJobError(false) : setJobError(true)
  }

  const postId = props.post_id ? props.post_id : '記事指定なし'

  return (
    <Container className={classes.root}>
      <Typography
        variant='h1' align='center'
        className={classes.contentHeader}
      >
        「ネタ」の企画化をご希望の方は<br />以下のフォームからご連絡ください。
      </Typography>
      <Typography variant='body1' align='center' className={classes.contentBody}>
        最新のエビデンスに基づいた健康・食品分野の情報をもっと広く伝えるために、報道担当者に向けた企画化支援サービスです。<br />
        テレビ番組ディレクター、新聞記者、WEBメディア編集者などの報道担当者を対象に<br />
        信頼のおける企画の「ネタ」を提供し、企画化に向けた情報収集などをご一緒します！
      </Typography>
      <form
        onSubmit={onSubmit}
        action={process.env.REACT_APP_GOOGLE_FORM_URL}
        method='POST' target='hidden_iframe'
      >
        <iframe
          name='hidden_iframe' id='hidden_iframe' className={classes.iframe} title='hidden_iframe'
          onLoad={redirectToThanks}
        />
        <FormControl className={classes.formControl}>
          <input type='hidden' name={process.env.REACT_APP_GOOGLE_FORM_POST_ID} value={postId} />
          <TextField
            className={classes.textField}
            name={process.env.REACT_APP_GOOGLE_FORM_NAME_ID}
            variant='outlined'
            fullWidth
            error={nameError && !beforeSubmit}
            helperText={nameError && !beforeSubmit ? '必須項目です' : ''}
            onChange={onChangeName}
            label='お名前'
          />
          <TextField
            className={classes.textField}
            name={process.env.REACT_APP_GOOGLE_FORM_MAIL_ID}
            variant='outlined'
            fullWidth
            error={mailError && !beforeSubmit}
            helperText={mailError && !beforeSubmit ? '必須項目です' : ''}
            onChange={onChangeMail}
            label='メールアドレス'
          />
          <TextField
            className={classes.textField}
            name={process.env.REACT_APP_GOOGLE_FORM_COMPANY_ID}
            variant='outlined'
            fullWidth
            error={companyError && !beforeSubmit}
            helperText={companyError && !beforeSubmit ? '必須項目です' : ''}
            onChange={onChangeCompany}
            label='会社名'
          />
          <TextField
            className={classes.textField}
            name={process.env.REACT_APP_GOOGLE_FORM_MEDIA_ID}
            variant='outlined'
            fullWidth
            error={mediaError && !beforeSubmit}
            helperText={mediaError && !beforeSubmit ? '必須項目です' : ''}
            onChange={onChangeMedia}
            label='媒体(番組)名'
          />
          <TextField
            className={classes.textField}
            name={process.env.REACT_APP_GOOGLE_FORM_JOB_ID}
            fullWidth
            variant='outlined'
            select
            label='職種'
            value={job}
            error={jobError && !beforeSubmit}
            helperText={jobError && !beforeSubmit ? '選択してください' : ''}
            onChange={onChangeJob}
          >
            {jobCategory.map(job => (
              <MenuItem key={job} value={job}>
                {job}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            className={classes.textField}
            name={process.env.REACT_APP_GOOGLE_FORM_OPINIONS_ID}
            fullWidth
            multiline
            variant='outlined'
            rowsMax='4'
            label='ご要望やご意見がございましたらお聞かせください。'
          />

          <Button className={classes.button} variant='contained' color='primary' type='submit'>送信する</Button>
        </FormControl>
      </form>
    </Container>

  )
}
