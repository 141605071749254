import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '15px 0px',
    textAlign: 'left'
  },
  RadioGroup: {
    marginTop: '5px'
  },
  radioButton: {
    height: '30px'
  },
  RadioGroupInline: {
    display: 'inline-block',
    margin: '0 auto'
  },
  formControl: {
    width: '100%'
  }
}))

export const NmFormRadioButton = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl} error={props.error}>
        <FormLabel>{props.label}</FormLabel>
        {RadioGroupArea(props)}
        <FormHelperText>{props.error ? '必須項目です' : ''}</FormHelperText>
      </FormControl>
    </div>
  )
}

const RadioGroupArea = (props) => {
  const classes = useStyles()

  // props.isInline=true:横並び
  return (
    <>
      <RadioGroup
        className={props.isInline ? classes.RadioGroupInline : classes.RadioGroup} aria-label={props.name} name={props.name} value={props.value}
        onChange={props.onChange}
      >
        {Object.keys(props.radioGroups).map(key => (
          <FormControlLabel
            className={classes.radioButton} key={key} value={key} control={<Radio />}
            label={props.radioGroups[key]}
          />
        ))}
      </RadioGroup>
    </>
  )
}
