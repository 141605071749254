import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { NmForm } from 'shared/NmForm'
import { NmFormSubmitGroup } from 'shared/NmForm/NmFormSubmitGroup'
import { NmFormButton } from 'shared/NmForm/NmFormButton'
import { NmFormTextField } from 'shared/NmForm/NmFormTextField'
import { confirmRegistration } from 'auth/AwsAuth'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { NmFormText } from 'shared/NmForm/NmFormText'
import { AuthErrorCode } from 'auth/ConstErrorCode'

const useStyles = (theme) => ({
  root: {
    margin: '0 auto 0',
    padding: '10px',
    maxWidth: '480px',
    justifyContent: 'center'
  },
  link: {
    textDecoration: 'none'
  }
})

const schema = Yup.object().shape({
  email: Yup.string()
    .required('必須項目です')
    .email('メールアドレスの形式が正しくありません'),
  code: Yup.string().required('必須項目です')
})

const Form = (props) => {
  const { classes, email } = props
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      email
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = (values) => {
    confirmRegistration(values.email, values.code)
      .then((result) => {
        window.location.href = '/SignupComplete?status=' + result.status
      })
      .catch((err) => {
        switch (err.message) {
          case AuthErrorCode.CodeMismatchError:
            setError('code', {
              type: 'manual',
              message: '認証コードとメールアドレスの組み合わせが誤っています'
            })
            break
          case AuthErrorCode.NotAuthorizedError:
            setError('code', {
              type: 'manual',
              message: 'メールアドレスは認証済みです'
            })
            break
          case AuthErrorCode.ExpiredCodeError:
            setError('code', {
              type: 'manual',
              message: '認証コードの有効期限が切れています'
            })
            break
          default:
            setError('code', {
              type: 'manual',
              message:
                'エラーが発生しました。大変お手数ですがお問い合わせください'
            })
            break
        }
      })
  }

  return (
    <Grid className={classes.root}>
      <Grid>
        <NmForm onSubmit={handleSubmit(onSubmit)} title='認証コード入力'>
          <NmFormText>
            ご登録いただいたメールアドレスに認証コードを送信いたしました。送信された認証コードを入力してください。
          </NmFormText>
          <NmFormTextField
            name='email'
            label='ご登録メールアドレス'
            {...register('email')}
            error={Boolean(errors.email)}
            helperText={errors?.email?.message}
          />
          <NmFormTextField
            name='code'
            label='認証コード'
            {...register('code')}
            error={Boolean(errors.code)}
            helperText={errors?.code?.message}
          />
          <NmFormSubmitGroup>
            <NmFormButton title='送信する' disabled={isSubmitting} />
          </NmFormSubmitGroup>
        </NmForm>
      </Grid>
    </Grid>
  )
}

export const Body = withStyles(useStyles)(Form)
