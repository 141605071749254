import React from 'react'
import { StyledForm } from './Form'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto 0',
    padding: '10px',
    maxWidth: '480px',
    justifyContent: 'center'
  }
}))

export const Body = (props) => {
  const classes = useStyles()
  return (
    <Grid className={classes.root}>
      <Grid>
        <StyledForm {...props} />
      </Grid>
    </Grid>
  )
}
