export const TitleUtil = {
  default: () => 'ネタマッチ|いま取材したくなる食と健康の情報をマッチング',
  home: () => 'トップ | ネタマッチ',
  // nullを返さないとundefindが入ったタイトルが返されてページビューカウントされてしまう
  postDetail: (post) => post?.title ? `${post.title} | ネタマッチ` : null,
  featurePosts: (feature) => feature?.name ? `特集 | ${feature.name} | ネタマッチ` : null,
  categoryPosts: (category) => category?.name ? `${category.name} | ネタマッチ` : null,
  tagPosts: (tag) => (tag?.name ? `タグ | ${tag.name} | ネタマッチ` : null),
  about: () => 'ネタマッチとは | ネタマッチ',
  signUp: () => 'メディア会員登録 | ネタマッチ',
  signupConfirm: () => 'メディア会員登録確認 | ネタマッチ',
  signupComplete: () => 'メディア会員登録完了 | ネタマッチ',
  companySignup: () => '企業会員登録 | ネタマッチ',
  companySignupComplete: () => '企業会員登録完了 | ネタマッチ',
  forgotPassword: () => 'パスワード忘れ | ネタマッチ',
  resetPassword: () => 'パスワードリセット | ネタマッチ',
  resetPasswordComplete: () => 'パスワードリセット完了 | ネタマッチ',
  resendConfirmationCode: () => '認証コード再発行 | ネタマッチ',
  contact: () => 'お問い合わせ | ネタマッチ',
  contactThanks: () => 'お問い合わせ完了 | ネタマッチ',
  search: () => '検索 | ネタマッチ',
  login: () => 'ログイン | ネタマッチ',
  logout: () => 'ログアウト | ネタマッチ',
  accountDetail: () => 'アカウント詳細 | ネタマッチ',
  accountUpdate: () => 'アカウント更新 | ネタマッチ',
  attendanceList: () => '申し込みイベント | ネタマッチ',
  privacyPolicy: () => 'プライバシーポリシー | ネタマッチ',
  eventDetail: (event) => (event?.title ? `${event.title} | ネタマッチ` : null),
  terms: () => '利用規約 | ネタマッチ',
  eventEntryComplete: () => 'イベント申し込み完了 | ネタマッチ',
  eventEntry: () => 'イベント申し込み | ネタマッチ',
  pressReleaseInquiryComplete: () => 'プレスリリース申し込み完了 | ネタマッチ',
  pressReleaseDetail: (pressRelease) => pressRelease?.title ? `${pressRelease.title} | ${pressRelease.createdBy} | ネタマッチ` : null,
  pressReleaseInquiry: () => 'プレスリリースお問い合わせ | ネタマッチ',
  mediaDocDetail: (post) => post?.title ? `${post.title} | ネタマッチ` : null,
  notFound: () => 'お探しのページが見つかりませんでした | ネタマッチ'
}

export default TitleUtil
