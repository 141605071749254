import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { NmForm } from 'shared/NmForm'
import { NmFormText } from 'shared/NmForm/NmFormText'
import { NmFormSubmitGroup } from 'shared/NmForm/NmFormSubmitGroup'
import { NmFormButton } from 'shared/NmForm/NmFormButton'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 auto 0',
    padding: '10px',
    maxWidth: '480px',
    justifyContent: 'center',
  },
  link: {
    textDecoration: 'none'
  }
}))

export const Body = (props) => {
  const classes = useStyles()
  const onSubmit = (event) => {
    event.preventDefault()
  }
  if (props.status === 'enabled') {
    return (
      <Grid className={classes.root}>
        <Grid>
          <NmForm onSubmit={onSubmit} title='会員登録完了'>
            <NmFormText>
              会員登録が完了しました。
            </NmFormText>
            <NmFormSubmitGroup>
              <Link to='Login' className={classes.link}>
                <NmFormButton title='ログインする' />
              </Link>
            </NmFormSubmitGroup>
          </NmForm>
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Grid container className={classes.root}>
        <Grid>
          <NmForm onSubmit={onSubmit} title='会員登録確認'>
            <NmFormText>
              担当者により確認後、会員登録完了となります。<br />
              改めて担当者よりご連絡差し上げますので今しばらくお待ち下さい。
            </NmFormText>
            <NmFormSubmitGroup>
              <Link to='/' className={classes.link}>
                <NmFormButton title='トップへ' />
              </Link>
            </NmFormSubmitGroup>
          </NmForm>
        </Grid>
      </Grid>
    )
  }
}
