import InfiniteQueryUtil from 'utility/InfiniteQueryUtil'
import QueryItemTagPost from 'utility/query_item/TagPost'

export const useInfiniteTagPosts = (tagId) => {
  const queryItemTagPost = new QueryItemTagPost({ tagId, perPage: 12 })
  const infiniteQueryUtil = new InfiniteQueryUtil()
  infiniteQueryUtil.setQueryItem(queryItemTagPost)
  const res = infiniteQueryUtil.fetch(['tagPost', tagId])
  const tagPosts = res?.data?.pages?.reduce((posts, page) => posts.concat(page?.items), []) || []

  return {
    ...res,
    data: tagPosts
  }
}
