import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'
import { NmButton } from 'shared/atoms/NmButton'
import Logo from './logo.png'
import Background from './bg.png'
const useStyles = makeStyles({
  root: {
    width: '500px',
    height: '350px',
    margin: '200px auto',
    background: `url(${Background})`,
    backgroundSize: '400px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  content: {
    paddingTop: '80px',
    textAlign: 'center'
  },
  desc: {
    paddingTop: '30px'
  },
  logo: {
    width: '118px',
    padding: '50px 0'
  },
  button: {
    paddingTop: '8px',
    textAlign: 'center'
  }
})

export const App = (props) => {
  const classes = useStyles()

  setPageTitle(TitleUtil.notFound())

  return (
    <>
      <div className={classes.root}>
        <p className={classes.content}>
          <Typography variant='subtitle1'>
            申し訳ありません。
            <br />
            お探しのページは見つかりませんでした。
          </Typography>
          <Typography className={classes.desc} variant='body1'>
            リンクに問題があるか、ページが削除された可能性があります。
          </Typography>
          <img className={classes.logo} src={Logo} />
        </p>
        <p className={classes.button}>
          <NmButton color='primary' text='最新記事一覧へ' link='/' />
        </p>
      </div>
    </>
  )
}
