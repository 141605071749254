import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  }
}))

export const Title = (props) => {
  const classes = useStyles()
  const { post } = props
  if (!post.title) return null
  return (
    <>
      <Typography variant='h1' className={classes.root}>
        {post.title}
      </Typography>
    </>
  )
}
