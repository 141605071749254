import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'
import { Panel } from './carousel/Panel'
import WpApi from 'api/WpApi'
import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  carouselContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  },
  slider: {
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    position: 'relative',
    '& > button:before': {
      color: 'black'
    }
  },
  div: {
    width: '100%'
  }
}))

export const Carousel = (props) => {
  const classes = useStyles()
  const [posts, setPosts] = useState([])
  const settings = {
    dots: true,
    autoplay: true,
    interval: 3000,
    lazyLoad: true,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0
  }

  useEffect(() => {
    WpApi.getNewPosts(5).then((response) => {
      setPosts(response.body)
    })
  }, [])

  return (
    <Container className={classes.carouselContainer}>
      <Slider {...settings} className={`${classes.slider}`}>
        {posts.map((post, key) => {
          return (
            <Panel
              key={post.id}
              imageSrc={post.imageUrl}
              title={post.title}
              link={post.getDetailPath()}
            />
          )
        })}
      </Slider>
    </Container>
  )
}
