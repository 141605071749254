import React from 'react'
import { Body } from './Body'
import { Helmet } from './Helmet'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = (props) => {
  const params = new URLSearchParams(props.location.search)
  const content = <>
    <Helmet />
    <Body post_id={props.match.params.id} nonce={params.get('token')} isPreview={params.get('preview')} />
  </>

  return <TemplateSideColumn content={content}/>
}
