import React from 'react'
import { Chip as MuiChip, makeStyles } from '@material-ui/core'

const defaultBgColor = '#FFF0F2'

const useStyles = makeStyles({
  root: {
    height: '26px',
    borderRadius: 'initial',
    fontSize: '12px',
    marginBottom: '2px',
    backgroundColor: defaultBgColor,
    '&:nth-child(even)': {
      marginLeft: '8px'
    }
  }

})

export const Chip = (props) => {
  const classes = useStyles()
  return <MuiChip {...props} className={`${classes.root} ${props.className}`} />
}
