import React from 'react'
import WpApi from '../../api/WpApi'
import {Grid, Box, Hidden} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from 'react-query'
import { SingleCategoryOverview } from './SingleCategoryOverview'
import { SingleCategoryOverviewSp } from './SingleCategoryOverviewSp'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-24px'
    }
  },
  overviewWrapper: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    marginBottom: '24px',
  }
}))
export const RootCategorySection = () => {
  const classes = useStyles()
  const { data } = useQuery(['rootCategories'], () =>
    WpApi.getRootCategories()
  )
  const rootCategories = data?.body || []  

  return (
    <Box className={classes.root}>
      <Grid container display='flex' justifyContent='center'>
        {rootCategories.map(category => {
          return <div key={category.id}>
            <Hidden xsDown>
              <Grid item><SingleCategoryOverview rootCategories={rootCategories} category={category} /></Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item>
                <Box className={classes.overviewWrapper}>
                  <SingleCategoryOverviewSp rootCategories={rootCategories} category={category} />
                </Box>
              </Grid>
            </Hidden>
          </div>
        })}
      </Grid>
    </Box>
  )
}
