import React from 'react'
import { setPageTitle } from 'hooks/setPageTitle'
import { TitleUtil } from 'utility/TitleUtil'
import { Body } from './Body'
import { TemplateSideColumn } from 'shared/template/SideColumn'

export const App = (props) => {
  setPageTitle(TitleUtil.terms())
  const content = <>
    <Body />
  </>

  return <TemplateSideColumn content={content}/>
}
