import { Container, Box, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SearchByTags } from 'shared/template/SideColumnComponents/SearchByTags'
import { SideColumnWrapper } from 'shared/template/SideColumnComponents/SideColumnWrapper'
import { AboutNetamatch } from 'shared/template/SideColumnComponents/AboutNetamatch'
import { TagCloud } from 'shared/TagCloud'
import { Ranking } from 'shared/template/SideColumnComponents/Ranking'
import { Header } from 'shared/header/Header'
import { Footer } from 'shared/Footer'

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px 0px'
  },
  columnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    paddingBottom: '32px'
  },
  container: {
    width: '100%'
  }
}))

export const TemplateSideColumn = (props) => {
  const classes = useStyles()
  const { content } = props
  return (
    <>
      <Header />
      <Container maxWidth='lg' className={classes.root}>
        <Box className={classes.columnWrapper}>
          <Box flexShrink={1} overflow='hidden' className={classes.container}>
            {content}
            <TagCloud />
          </Box>
          <Hidden smDown>
            <SideColumnWrapper>
              <Ranking />
              <SearchByTags />
              <AboutNetamatch />
            </SideColumnWrapper>
          </Hidden>
        </Box>
      </Container>
      <Footer />
    </>
  )
}
