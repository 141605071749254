import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid } from '@material-ui/core'
import { PostCard } from './PostCard'
import { SectionTitle } from './SectionTitle'
import Skeleton from '@material-ui/lab/Skeleton'
import { useQuery } from 'react-query'
import WpApi from 'api/WpApi'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20,
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0',
    }
  },
  contents: {
    display: 'flex'
  },
  content: {
    paddingTop: '30px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0',
    }
  },
  skeletonGrids: {
    display: 'block',
    textAlign: 'center'
  },
  skeletonGrid: {
    display: 'inline-block',
    margin: '3px'
  }
}))

export const Posts = (props) => {
  const classes = useStyles()
  const { data: dataRootCategories } = useQuery(['rootCategories'], () =>
    WpApi.getRootCategories()
  )
  const rootCategories = dataRootCategories?.body || []
  
  if (typeof props.sectionTitle === 'undefined') {
    return (
      <Container className={classes.root}>
        <Grid>
          <SectionTitle title='' desc='' />
        </Grid>
        <Grid className={classes.skeletonGrids} container spacing={0} justifyContent='center'>
          {Array.from(new Array(3)).map((num) => (
            <Grid key={num} className={classes.skeletonGrid} xs={12}>
              <Skeleton variant='text' width={240} height={30} />
              <Skeleton variant='rectangular' width={240} height={230} />
            </Grid>
          ))}
        </Grid>
      </Container>
    )
  } else {
    return (
      <Container className={classes.root}>
        <Grid>
          <SectionTitle title={props.sectionTitle} desc={props.sectionDesc} />
        </Grid>
        <Grid className={classes.contents} container spacing={0} justifyContent='center'>
          {props?.posts?.map(post => (
            <Grid className={classes.content} item  xs={12} sm={6} md={6} lg={4} key={post.id}>
              <PostCard post={post} rootCategories={rootCategories} />
            </Grid>
          ))}
        </Grid>
      </Container>
    )
  }
}
