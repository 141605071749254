import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Parser, { domToReact, attributesToProps } from 'html-react-parser'
import { useAuth } from 'hooks/useAuth'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    color: theme.palette.text.primary,
    '& blockquote,& iframe': {
      margin: '0 auto!important'
    }
  },
  section: {
    marginTop: '24px',
    marginBottom: '16px'
  },
  paragraph: {
    marginBottom: '16px',
    lineHeight: '190%'
  },
  line: {
    backgroundColor: '#333333',
    width: '100%',
    height: '2px',
    margin: '0px'
  },
  image: {
    display: 'block',
    margin: 'auto',
    maxWidth: '100%',
    height: 'auto'
  }
}))

export const Content = (props) => {
  const classes = useStyles()
  const { post } = props
  const { user } = useAuth()
  const options = {
    replace: ({ name, attribs, children }) => {
      if (!name) return

      if (name === 'h2') {
        return <Typography variant='h2' className={classes.section}>{domToReact(children, options)}<hr className={classes.line} /></Typography>
      }
      if (name === 'p') {
        return <Typography variant='body1' className={classes.paragraph} alt={attribs.alt}>{domToReact(children, options)}</Typography>
      }
      if (name === 'img') {
        return <img className={classes.image} alt={attribs.alt} src={attribs.src} width={attribs.width} height={attribs.height} />
      }
      if (name === 'script') return <br /> // ScriptタグはXSS対策で削除する
    }
  }
  if (!post) return null
  return (
    <div className={classes.wrapper}>
      {post.content
        ? Parser(getContentHTML(user.isLogin(), post.content), options)
        : null}
    </div>
  )
}

function getContentHTML (_isLogin, rawHTML) {
  return rawHTML
}
