import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemText, Collapse, Divider, InputBase } from '@material-ui/core'
import { ExpandLess, ExpandMore, Search as SearchIcon } from '@material-ui/icons'
import WpApi from 'api/WpApi'
import { NmButton } from 'shared/atoms/NmButton.js'
import { useQuery } from 'react-query'
import { Categories } from 'domain/entity/Categories'

const useStyles = makeStyles(theme => ({
  root: {
    width: '300px',
    textAlign: 'center'
  },
  nested: {
    textAlign: 'left'
  },
  h2: {
    '& span': {
      color: theme.palette.text.subtext
    }
  },
  listItemSearch: {
    marginTop: '20px'
  },
  search: {
    display: 'block',
    fontSize: theme.typography.body2.fontSize,
    alignSelf: 'center',
    position: 'relative',
    border: '0px',
    borderRadius: '8px',
    marginRight: theme.spacing(1),
    marginLeft: 'auto',
    width: 'auto'
  },
  searchIcon: {
    width: theme.spacing(3),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    background: '#EBEBEB',
    borderRadius: '8px'
  },
  inputRoot: {
    color: '#6E6E6E'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 3),
    transition: theme.transitions.create('width'),
    boxSizing: 'border-box',
    width: '260px',
    height: theme.spacing(4)
  },
  listItemSignUp: {
    marginTop: '28px'
  },
  Button: {
    height: '44px'
  },
  childCategory: {
    paddingLeft: theme.spacing(4)
  }
}))

export const DrawerContent = (props) => {
  const [tagsOpen, setTagsOpen] = useState(false)
  const [tags, setTags] = useState([])

  const { data: allCategoriesRes } = useQuery(['allCategories'], () =>
    WpApi.getCategories()
  )
  const allCategories = new Categories(allCategoriesRes?.body || [])
  const rootCategories = allCategories.getRootCategories()
  const categoriesGroupedByRootCategoryId = allCategories.getCategoriesGroupedByRootCategoryId()

  useEffect(() => {
    WpApi.getTags().then((response) => {
      setTags(response.body)
    })
  }, [])

  const handleTagsToggle = () => {
    setTagsOpen(!tagsOpen)
  }
  const classes = useStyles()
  return (
    <List className={classes.root}>
      <ListItem>
        <ListItemText primary='カテゴリー' className={classes.h2} />
      </ListItem>
      <List component='div'>
        {rootCategories.map(rootCategory => {
          const childCategories = categoriesGroupedByRootCategoryId.get(rootCategory.id)
          return (
            <>
              <ListItem button component='a' href={'/Category/' + rootCategory.id} className={classes.nested} key={rootCategory.id}>
                <ListItemText primary={rootCategory.name} />
              </ListItem>
              {childCategories.map(childCategory => {
                return (
                  <ListItem button component='a' href={'/Category/' + childCategory.id} className={classes.childCategory} key={childCategory.id}>
                    <ListItemText primary={childCategory.name} />
                  </ListItem>
                )
              })}
            </>
          )
        })}
      </List>
      <Divider />
      <ListItem button onClick={handleTagsToggle}>
        <ListItemText primary='タグ' className={classes.h2} />
        {tagsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={tagsOpen} timeout='auto' unmountOnExit>
        <List component='div'>
          {tags.map((tag) => (
            <ListItem button component='a' href={'/Tag/' + tag.id} className={classes.nested} key={tag.id}>
              <ListItemText primary={tag.name} />
            </ListItem>
          ))}
        </List>
      </Collapse>
      <Divider />
      <ListItem button component='a' href='/about'>
        <ListItemText primary='ネタマッチとは' className={classes.h2} />
      </ListItem>
      <ListItem className={classes.listItemSearch}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <form action='/Search/' className={classes.form} autoComplete='off'>
            <InputBase
              placeholder='キーワードで検索'
              name='s'
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </form>
        </div>
      </ListItem>
      <ListItem className={classes.listItemSignUp}>
        <div className={classes.Button}>
          <NmButton text='新規会員登録' link='/Signup' color='accent' />
        </div>
      </ListItem>
      <ListItem>
        <div className={classes.Button}>
          <NmButton text='ログイン' link='/Login' color='primary' />
        </div>
      </ListItem>
    </List>
  )
}
