import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '40px 0',
    backgroundColor: theme.palette.cassette.main
  },
  title: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    color: '#1471D7'
  },
  boxRecommendedPoints: {
    width: '100%',
    paddingBottom: theme.spacing(2)
  },
  boxRecommendedPoint: {
    paddingRight: '32px',
    marginBottom: '20px'
  }
}))

export const Point = (props) => {
  const classes = useStyles()
  if (!props.post.hasPoint()) return (<div />)
  return (
    <div className={classes.container}>
      <Typography variant='h2' className={classes.title}>
          いまオススメする{props.post.countPoint()}つの理由
      </Typography>
      <div className={classes.boxRecommendedPoints}>
        <Typography variant='subtitle1'>
          <ol>
            {props.post.points.map((text, key) => {
              return <RecommendedPointRecord key={key} text={text} />
            })}
          </ol>
        </Typography>
      </div>
    </div>
  )
}

const RecommendedPointRecord = (props) => {
  const classes = useStyles()
  return (
    <li className={classes.boxRecommendedPoint}>{props.text}</li>
  )
}
