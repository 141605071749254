import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Collapse, Grid, List, ListItem, ListItemText } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert/Alert'
import { wayToParticipate } from 'const/Const'
import { NmFormRadioButton } from 'shared/NmForm/NmFormRadioButton'
import { sqlFormatToJapaneseString } from 'utility/DateTimeUtil'
import WpApi from 'api/WpApi'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.cassette.main,
    textAlign: 'center',
    width: ' 600px'
  },
  detail: {
    margin: theme.spacing(2),
    width: '90%',
    borderRadius: '9px',
    backgroundColor: theme.palette.common.white,
    paddingBottom: '16px'
  },
  collapse: {
    width: '300px',
    margin: '0 auto 0px'
  },
  collapseInner: {
    marginBottom: '10px'
  },
  radioButtonDisabled: {
    marginBottom: '30px'
  },
  listTitle: {
    width: '60px',
    flex: 'none'
  },
  descriptionContainer: {
    padding: '28px 28px 0px 28px'
  },
  listItem: {
    padding: '0px'
  },
  recommendMessage: {
    width: '100%',
    fontSize: '8px',
    fontWeight: 'bold'
  },
  loginButton: {
    marginBottom: '20px'
  },
  signUpButton: {
    marginBottom: '20px'
  },
  button: {
    height: '44px',
    width: '260px',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  }
}))

export const Entry = (props) => {
  const [error, setError] = useState(false)
  const [isOnline, setIsOnline] = useState('')
  const [apiError, setApiError] = useState(false)
  const [event, setEvent] = useState(null)
  const classes = useStyles()
  const Online = 1
  const Offline = 0

  useEffect(() => {
    WpApi.getEvent(props.postId)
      .then(event => setEvent(event))
  }, [props.postId])

  useEffect(() => {
    setApiError(props.apiError)
  }, [props.apiError])

  const DateListArea = () => {
    if (event) {
      return (
        <ListArea name='日時' text={sqlFormatToJapaneseString(event.scheduledDate) + '〜'} />
      )
    }
    return (<></>)
  }

  const PlaceListArea = () => {
    if (event) {
      switch (event.fetchAttendanceTypeString()) {
        case event.attendanceTypeStringList.online:
          return <ListArea name='会場' text='オンライン' />
        case event.attendanceTypeStringList.offline:
        case event.attendanceTypeStringList.both:
        default:
          return <ListArea name='会場' text={event.location} />
      }
    }
    return (<></>)
  }

  const ListArea = (props) => {
    return (
      <ListItem className={classes.listItem}>
        <ListItemText className={classes.listTitle} primary={props.name} />
        <ListItemText primary={props.text} />
      </ListItem>
    )
  }

  const RadioButtonArea = () => {
    if (event) {
      switch (event.fetchAttendanceTypeString()) {
        case event.attendanceTypeStringList.both:
          return (
            <Grid>
              <NmFormRadioButton
                radioGroups={wayToParticipate}
                select
                name='isOnline'
                required
                isInline
                value={isOnline}
                onChange={(e) => ChangeRadioButton(e)}
              />
            </Grid>
          )

        case event.attendanceTypeStringList.online:
          props.setIsOnline(Online)
          return (<Grid className={classes.radioButtonDisabled} />)

        case event.attendanceTypeStringList.offline:
          props.setIsOnline(Offline)
          return (<Grid className={classes.radioButtonDisabled} />)

        default:
          return (<Grid className={classes.radioButtonDisabled} />)
      }
    }
    return (<></>)
  }

  const ChangeRadioButton = (e) => {
    setIsOnline(e.target.value)
    props.setIsOnline(e.target.value)
  }

  const handleClickEntryByMember = () => {
    if (event && event.fetchAttendanceTypeString() === event.attendanceTypeStringList.both && isOnline === '') {
      setError(true)
    }
  }

  return (
    <Grid container className={classes.wrapper}>
      <Grid item className={classes.detail}>
        <List className={classes.descriptionContainer}>
          <DateListArea />
          <PlaceListArea />
        </List>
        <RadioButtonArea isOnline={props.isOnline} />
        <Collapse className={classes.collapse} in={error}>
          <Alert className={classes.collapseInner} severity='error' onClose={() => { setError(false) }}>出席の種類を選択してください</Alert>
        </Collapse>
        <Collapse className={classes.collapse} in={apiError}>
          <Alert
            className={classes.collapseInner} severity='error' onClose={() => {
              props.setApiError(false)
              setApiError(false)
            }}
          >エラーが発生しました。大変お手数ですがお問い合わせください
          </Alert>
        </Collapse>
        <Button
          className={classes.button} disabled={props.isSubmitting || apiError || error} onClick={() => handleClickEntryByMember()}
          variant='contained' color='primary' type='submit'
        >申し込む
        </Button>
      </Grid>
    </Grid>
  )
}
