import React, { useState, useEffect } from 'react'
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Button,
  Popper,
  Fade,
  Typography,
  Drawer,
  ClickAwayListener,
  Hidden,
  Modal
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Logo from 'image/logo.png'
import { MenuTag } from './MenuTag'
import { MenuCategory } from './MenuCategory'
import { MenuFeature } from './MenuFeature'
import { MenuAccount } from './MenuAccount'
import { DrawerContent } from './DrawerContent'
import { TwitterIcon } from './TwitterIcon'
import WpApi from 'api/WpApi'
import { ResourceContext } from 'App'
import { getUserAttributes } from 'auth/AwsAuth'
import { User } from 'domain/entity/User'

import { SignUpModal } from './SignUpModal'
import { CategoryBar } from './CategoryBar'

const useStyles = makeStyles(theme => ({
  appBar: {
    minWidth: '360px',
    marginBottom: theme.spacing(3)
  },
  firstNavBar: {
    height: theme.spacing(6),
    minHeight: theme.spacing(6),
    alignItems: 'stretch',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.5)'
  },
  secondNavBar: {
    boxSizing: 'border-box',
    height: theme.spacing(7),
    minHeight: theme.spacing(7),
    alignItems: 'stretch',
    border: '1px',
    borderColor: 'rgba(0, 0, 0, 0.1)',
    backgroundColor: '#0352A8',
    paddingRight: '0px',
    paddingLeft: '0px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  headerArea: {
    width: '100%',
    maxWidth: '1240px',
    margin: 'auto',
    display: 'flex'
  },
  hamburger: {
    display: 'block',
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(0)
    }
  },
  logoContainer: {
    alignSelf: 'center'
  },
  logo: {
    width: '140px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0)
    }
  },
  menuButton: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      padding: '20px 16px',
      position: 'relative',
      display: 'inline-block',
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
        '& span': {
          color: theme.palette.primary.main
        }
      },
      '&::after': {
        content: "''",
        position: 'absolute',
        bottom: '0px',
        left: 0,
        width: '100%',
        height: '3px',
        background: theme.palette.primary.main,
        opacity: 0,
        visibility: 'hidden',
        transition: '.3s'
      },
      '&:hover::after': {
        opacity: 1,
        visibility: 'visible'
      }
    },
    [theme.breakpoints.up('lg')]: {
      padding: '20px 24px'
    }
  },

  menuButtonHover: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '& span': {
      color: theme.palette.primary.main
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      bottom: '0px',
      left: 0,
      width: '100%',
      height: '3px',
      background: theme.palette.primary.main,
      opacity: 1,
      visibility: 'visible',
      transition: '.3s'
    }
  },
  menuAccountButton: {
    display: 'none',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      borderRadius: '50%',
      position: 'relative',
      display: 'flex',
      textDecoration: 'none',
      marginLeft: theme.spacing(2)
    }
  },
  menuText: {
    verticalAlign: 'middle',
    color: '#6E6E6E',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '26px'
  },
  menuContent: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.4)',
      zIndex: 1500
    }
  },
  menuContentForTag: {
    width: '100%'
  },
  search: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      height: theme.spacing(4),
      fontSize: theme.typography.body2.fontSize,
      alignSelf: 'center',
      position: 'relative',
      border: '0px',
      borderRadius: '8px',
      marginRight: theme.spacing(4),
      marginLeft: 'auto',
      width: 'auto'
    }
  },
  searchIcon: {
    paddingLeft: theme.spacing(1),
    width: theme.spacing(3),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(6)
    }
  },
  form: {
    background: '#EBEBEB',
    borderRadius: '8px'
  },
  inputRoot: {
    color: '#6E6E6E',
    fontSize: '14px'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 6),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '240px'
    },
    [theme.breakpoints.down('md')]: {
      boxSizing: 'border-box',
      width: '240px',
      height: '34px',
      padding: theme.spacing(1, 1, 1, 4)
    }
  },
  drawer: {
    width: '200px'
  },
  link: {
    'text-decoration': 'none',
    color: 'inherit'
  },
  buttonSignUp: {
    marginLeft: theme.spacing(2),
    width: '140px',
    height: theme.spacing(4),
    fontSize: '14px',
    alignSelf: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
    },
    [theme.breakpoints.down('xs')]: {
      width: '80px',
      padding: '0 10px',
      fontSize: '12px',
      marginLeft: 0
    }
  },
  modalButton: {
    backgroundColor: theme.palette.error.main
  },
  buttonSignIn: {
    marginLeft: theme.spacing(2),
    width: '140px',
    height: theme.spacing(4),
    fontSize: '14px',
    alignSelf: 'center',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {

    },
    [theme.breakpoints.down('xs')]: {
      width: '80px',
      padding: '0 10px',
      fontSize: '12px',
      marginLeft: theme.spacing(1)
    }
  },
  accountButtonArea: {
    display: 'flex',
    lineHeight: '56px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
    },
    [theme.breakpoints.down('xs')]: {
      lineHeight: '40px'
    }
  },
  accountIcon: {
    width: '40px',
    height: '40px'
  },
  twitterIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: `0 ${theme.spacing(1)}px`
    }
  }
}))

export const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [isSelectedTag, setIsSelectedTag] = React.useState(false)
  const [isSelectedCategory, setIsSelectedCategory] = React.useState(false)
  const [isSelectedFeature, setIsSelectedFeature] = React.useState(false)
  const [activeMenu, setActiveMenu] = React.useState('')
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [categories, setCategories] = useState([])
  const [features, setFeatures] = useState([])
  const [tags, setTags] = useState([])
  const classes = useStyles()
  const resetSelectedMenu = () => {
    setIsSelectedTag(false)
    setIsSelectedCategory(false)
    setIsSelectedFeature(false)
  }

  const handleOpen = () => {
    setModalOpen(true)
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  const setSelectedMenu = (newMenu) => {
    switch (newMenu) {
      case 'features':
        setIsSelectedFeature(true)
        break
      case 'tags':
        setIsSelectedTag(true)
        break
      case 'categories':
        setIsSelectedCategory(true)
        break
      default :
        // do nothing
        break
    }
  }

  const MenuPopperContent = (props) => {
    switch (props.menu) {
      case 'features':
        return (<MenuFeature features={features} />)
      case 'tags':
        return (<MenuTag tags={tags} />)
      case 'categories':
        return (<MenuCategory categories={categories} />)

      case 'account':
        return (<MenuAccount />)

      default :
        return (<Typography className={classes.typography}>おっと！メニューが見つかりませんでした。</Typography>)
    }
  }

  const handleClick = newMenu => event => {
    resetSelectedMenu()
    setAnchorEl(event.currentTarget)
    activeMenu !== newMenu && setSelectedMenu(newMenu)
    setOpen(prev => activeMenu !== newMenu || !prev)
    setActiveMenu(newMenu)
  }

  const handleClickAway = () => {
    resetSelectedMenu()
    setAnchorEl(null)
    setOpen(false)
    setActiveMenu('')
  }

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen)
  }

  useEffect(() => {
    WpApi.getCategories().then((response) => {
      setCategories(response.body)
    })

    WpApi.getFeatures().then((response) => {
      setFeatures(response.body)
    })

    WpApi.getTags().then((response) => {
      setTags(response.body)
    })

    getUserAttributes().then((response) => {
      context.setUser(new User(response.name, response.email, response['custom:tel'], response['custom:media'], response['custom:job'], response['custom:company']))
      // gtmにユーザー名を渡す
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ email: response.email, name: response.name })
    })
  }, [])

  const context = React.useContext(ResourceContext)

  const loginView = () => {
    if (context.user.isLogin()) {
      return (
        <IconButton
          className={classes.menuAccountButton}
          onClick={handleClick('account')}
        >
          <AccountCircleIcon
            className={classes.accountIcon}
            color='primary'
          />
        </IconButton>

      )
    }
    return (
      <>
        <div className={classes.accountButtonArea}>
          <Hidden xsDown>
            <Button className={classes.buttonSignUp} variant='contained' color='primary' onClick={handleOpen}>新規会員登録</Button>
          </Hidden>
          <Hidden smUp>
            <Button className={classes.buttonSignUp} variant='contained' color='primary' onClick={handleOpen}>新規登録</Button>
          </Hidden>
          <Button className={classes.buttonSignIn} href='/Login' variant='contained'>ログイン</Button>
        </div>
        <Modal
          open={modalOpen}
          onClose={handleClose}
        >
          <SignUpModal />
        </Modal>
      </>
    )
  }

  return (
    <div>
      <Popper
        key={activeMenu}
        open={open}
        anchorEl={anchorEl}
        placement='bottom'
        className={[
          classes.menuContent,
          isSelectedTag && classes.menuContentForTag
        ].join(' ')}
        transition
        modifiers={{
          preventOverflow: {
            padding: 0
          }
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={{ enter: 350, exit: 0 }}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <div>
                <MenuPopperContent menu={activeMenu} />
              </div>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>
      <AppBar position='static' color='transparent' className={classes.appBar}>
        <Toolbar className={classes.firstNavBar}>
          <div className={classes.headerArea}>
            <IconButton
              edge='start'
              className={classes.hamburger}
              onClick={handleDrawerToggle}
              color='inherit'
              aria-label='open drawer'
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              open={drawerOpen}
              anchor='left'
              onClose={handleDrawerToggle}
              className={classes.drawer}
            >
              <DrawerContent />
            </Drawer>
            <Link
              className={classes.logoContainer}
              to='/'
            ><img className={classes.logo} alt='NETA-MATCH' src={Logo} />
            </Link>

            <Box display='flex' flexGrow={1} justifyContent='flex-end'>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <form action='/Search/' className={classes.form} autoComplete='off'>
                  <InputBase
                    placeholder='キーワードで検索'
                    name='s'
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </form>
              </div>
              <Box className={classes.twitterIconWrapper}>
                <TwitterIcon />
              </Box>
              {loginView()}
            </Box>
          </div>
        </Toolbar>
        <Toolbar className={classes.secondNavBar}>
          <div className={classes.headerArea}>
            <CategoryBar />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}
