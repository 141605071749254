import { useEffect } from 'react'
import TitleUtil from 'utility/TitleUtil'
import { useLocation } from 'react-router'

// 参考: https://zenn.dev/magicmoment/articles/react-ga4-202109
export const setPageTitle = (pageTitle) => {
  const location = useLocation()
  useEffect(() => {
    document.title = pageTitle || TitleUtil.default()
    if (!pageTitle) return

    // ga-ua用
    window.setTimeout(() => {
      window.gtag('event', 'page_view', {
        page_title: pageTitle,
        page_location: location.pathname
      })
    })
  }, [pageTitle])
}
