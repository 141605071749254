import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Container, Typography } from '@material-ui/core'
import { Value } from './Value'
import VerifiedDoc from 'image/verifiedDoc.png'
import Specialist from 'image/specialist.png'
import Guide from 'image/guide.png'
import Free from 'image/free.png'
import howToUse from 'image/howToUse.png'
const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px'
  },
  contents: {
    display: 'flex',
    justifyContent: 'center'
  },
  content: {
    marginTop: '5px'
  },
  contentHeader: {
    marginTop: '70px',
    marginBottom: '15px'
  },
  howToImage: {
    width: '100%'
  }
}))

export const Body = (props) => {
  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <Grid container className={classes.contents}>
        <Grid item className={classes.content}>
          <Typography variant='h1' align='center' className={classes.contentHeader}>ネタマッチとは</Typography>
          <Typography variant='body1' align='center'>
            最新のエビデンスや取材による裏付けに基づいた食・健康／美容に関する情報を<br />
            報道関係者の企画に役立つ情報に編集した企画化支援サービスです。<br />
            テレビ番組ディレクターや新聞記者、雑誌編集者、WEBメディア編集者、ライターなど報道担当者を対象に<br />
            <strong>信頼のおける企画の「ネタ」</strong>を提供し、企画化に向けた情報収集などをご一緒します！
          </Typography>
        </Grid>
        <Grid item className={classes.content}>
          <Typography variant='h1' align='center' className={classes.contentHeader}>ご利用の流れ</Typography>
          <img alt='feat' src={howToUse} className={classes.howToImage} />
        </Grid>
        <Grid item className={classes.content}>
          <Typography variant='h1' align='center' className={classes.contentHeader}>特徴</Typography>
          <Value
            imageSrc={VerifiedDoc}
            title='裏付けされた情報のみをご提供'
            description='医師や栄養士などの専門家の取材や研究論文、調査結果に裏付けされた情報のみを提供します。'
          />
          <Value
            imageSrc={Specialist}
            title='専門家や有識者をご紹介'
            description='専門家や有識者など、取材対象者をご紹介します。'
          />
          <Value
            imageSrc={Guide}
            title='企画化のご支援'
            description='ネタの企画化について、お困りごとを一緒に解決します。'
          />
          <Value
            imageSrc={Free}
            title='無料'
            description='ネタのご提供や専門家のご紹介など企画化に関するご支援はすべて無料でご利用いただけます。'
          />
        </Grid>
      </Grid>
    </Container>
  )
}
