import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '15px 0px',
    textAlign: 'left'
  },
  textField: {
    '& > div > fieldset > legend': {
      display: 'block'
    }
  },
  subText: {
    margin: '8px 14px 0',
    fontSize: '0.75rem',
    color: theme.palette.text.subtext
  }
}))
const NmTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF'
    }
  }
})(TextField)

/**
 * react-hook-formのregisterが返す値をpropsとして渡しているが、その中にrefが含まれている
 * reactの仕様として、refはpropsとして参照できないのでforwardRefする必要がある
 * 今回のようにforwardRefする以外にもいくつかやり方がある
 * react-hook-formのuseControllerを使う、inputRefを返すアダプタを作るなど
 * 参考: https://dev.classmethod.jp/articles/mui-with-rhf-v7/
 * 参考: https://ja.reactjs.org/docs/forwarding-refs.html
 */
export const NmFormTextField = React.forwardRef((props, ref) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <NmTextField
        className={classes.textField}
        name={props.name}
        variant='outlined'
        fullWidth
        onChange={props.onChange}
        value={props.value}
        onBlur={props.onBlur}
        label={props.label}
        error={props.error}
        helperText={props.helperText}
        select={props.select}
        required={props.required}
        type={props.type}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        inputRef={ref || props.inputRef}
      >
        {props.children}
      </NmTextField>
      <span className={classes.subText}>{props.subText}</span>
    </div>
  )
})
