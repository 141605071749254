import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Container, Typography, Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import WpApi from '../api/WpApi'
import qr from '../image/qr.png'
import logo from '../image/footerLogo.png'
import twitter from '../image/twitterIconWhite.png'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    maxWidth: '100%',
    fontSize: theme.typography.body2.fontSize,
    backgroundColor: theme.palette.secondary.dark,
    margin: '0 auto',
    padding: '20px 15px',
    textAlign: 'center',
    color: '#FFF'
  },
  submenu: {
    fontSize: '12px'
  },
  container: {
    display: 'flex',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    margin: 'auto',
    listStyle: 'none'
  },
  listItem: {
    display: 'inline-block',
    margin: theme.spacing(1),
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  link: {
    'text-decoration': 'none',
    color: 'inherit'
  },
  twitter: {
    marginLeft: '30px'
  },
  categoryItemWrapper: {
    margin: `${theme.spacing(1)}px 0px`
  },
  categoryItem: {
    minWidth: 'fit-content'
  },
  iconItemWrapper: {
    flexWrap: 'nowrap',
    margin: `${theme.spacing(1)}px 0px`
  },
  iconItem: {
    maxWidth: '100%',
    maxHeight: '80px',
    objectFit: 'contain'
  }
}))

export const Footer = (props) => {
  const classes = useStyles()
  const {data} = useQuery(['rootCategories'], () => WpApi.getRootCategories())
  const rootCategories = data?.body || []
  
  return (
    <Typography component='footer' className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.submenu}>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link
                  className={classes.link}
                  to='/Terms'
                >利用規約
                </Link>
              </li>
              <li className={classes.listItem}>
                <Link
                  className={classes.link}
                  to='/PrivacyPolicy'
                >プライバシーポリシー
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid container justifyContent='center' alignItems='center' spacing={2} className={classes.categoryItemWrapper}>
            {rootCategories.map(category => (
              <Grid item xs={6} sm={4} md={1} key={category.id} className={classes.categoryItem}>
                <Link
                  className={classes.link}
                  to={'/Category/' + category.id}
                >
                  {category.name}
                </Link>
              </Grid>
            ))}
          </Grid>
          
          <Grid container justifyContent='center' alignItems='center' spacing={4} className={classes.iconItemWrapper}>
            <Grid item>
              <Link to='/' maxwidth={'100%'}>
                <img alt='NETA-MATCH ' src={logo} className={classes.iconItem} />
              </Link>
            </Grid>
            <Grid item>
              <Link to='/'>
                <img alt='NETA-MATCH ' src={qr} className={classes.iconItem}/>
              </Link>
            </Grid>
            <Grid item>
              <a href='https://twitter.com/netamatch' target='_blank' rel='noopener noreferrer'>
                <img alt='TWITTER' src={twitter} className={classes.iconItem} />
              </a>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box color='inherit'>運営会社：PRオートメーション株式会社 TEL:03-3713-2850</Box>
            <Box color='inherit'>〒150-0022 東京都渋谷区恵比寿南1-2-9  小林ビル4階</Box>
            <Box color='inherit'>©2020 NETA-MATCH.</Box>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  )
}
