import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10)
  },
  formWrapper: {
    backgroundColor: theme.palette.cassette.main,
    width: '100%',
    maxWidth: '480px',
    display: 'block'
  },
  formControl: {
    width: '328px',
    textAlign: 'center',
    margin: '0 auto',
    padding: '30px 0',
    display: 'block'
  },
  title: {
    marginBottom: theme.spacing(2)
  }
}))

export const NmForm = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant='h2' className={classes.title}>
        {props.title}
      </Typography>
      <div className={classes.formWrapper}>
        <form onSubmit={props.onSubmit} className={classes.formControl} autoComplete='off'>
          {props.children}
        </form>
      </div>
    </div>
  )
}
