import { PostBase } from './PostBase'

export class Post extends PostBase {
  constructor (id, categories, title, imageUrl, content, createdAt, modifiedAt, points, specialist, maker, otherSpecialist, docUrl, relatedPostIds = []) {
    const createdBy = 'ネタマッチ編集部'
    super(id, categories, title, imageUrl, content, createdBy, createdAt, modifiedAt)
    this.type = 'post'
    this.points = points
    this.relatedPostIds = relatedPostIds
    this.specialist = specialist
    this.maker = maker
    this.otherSpecialist = otherSpecialist
    this.docUrl = docUrl || ''
    this.detailPathDir = '/Detail/'
  }

  hasDocUrl () {
    return this.docUrl.length > 0
  }

  hasPoint () {
    return this.points.length !== 0
  }

  countPoint () {
    return this.points.length
  }
}
