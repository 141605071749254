import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(({
  author: {
    textAlign: 'left'
  },
  date: {
    textAlign: 'right'
  }
}))

export const SubTitle = (props) => {
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item xs={6} className={classes.author}>
        <Typography variant='body1'>
          {props.post.createdBy}
        </Typography>
      </Grid>
      <Grid item xs={6} className={classes.date}>
        <Typography variant='body1'>
          {props.post.getYmdDate()}
        </Typography>
      </Grid>
    </Grid>
  )
}
